import { Theme } from '@mui/material';
import Color from 'color';

export const styles = (theme: Theme) => ({
  emptyCard: {
    display: 'flex',
    width: { tablet: '510px', mobile: '100%' },
    marginTop: { tablet: '60px', mobile: '0px' },
    height: { tablet: '100%', mobile: '100%' },
  },
  cardTitle: {
    fontSize: '1.25rem',
    marginBottom: '3px',
    fontWeight: 700,
    color: theme.palette.grayScale.main,
  },
  alert: {
    backgroundColor: theme.palette.grayScale.gradient100,
  },

  recordCard: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  details: {
    display: 'flex',
  },
  subtitle: {
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
    marginBottom: '10px',
  },
});

export default styles;
