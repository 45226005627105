import apiService from './api.service';
import endpointService from './endpoint.service';
import { ILabResponse } from 'core/interfaces/lab-record.interface';
import {
  IImmunization,
  IImmunizationResponse,
  IImmunizationHistory,
} from 'core/interfaces/immunization.interface';
import { IPanelDetailResult } from 'core/interfaces/tests.interface';

import { IPrescriptionMetadata } from 'core/interfaces/prescription-metadata.interface';
import { IImmunizationMetadata } from 'core/interfaces/immunization-metadata.interface';
import { ICovidCard } from 'core/interfaces/covid-card.interface';
import { IPrescriptionResponse } from 'core/interfaces/prescription.interface';
import { ILabMetadata } from '../interfaces/lab-metadata.interface';

const getLabs = async (userId: string, filter: any) => {
  return apiService.get<ILabResponse>(
    endpointService.getRoutes().LABS.QUERY(userId),
    {
      params: filter,
    },
  );
};

const getNewLabs = async (userId: string) => {
  return apiService.get<ILabResponse>(
    endpointService.getRoutes().LABS.NEW(userId),
  );
};

const getLabsMetadata = async (userId: string) => {
  return apiService.get<ILabMetadata>(
    endpointService.getRoutes().LABS.METADATA(userId),
  );
};

const getLabDetail = async (recordId: string) => {
  return apiService.get<any>(endpointService.getRoutes().LABS.DETAIL(recordId));
};

const getPanelTest = async (id: string) => {
  return apiService.get<any>(endpointService.getRoutes().LABS.DETAIL(id));
};

const getLabHistory = async (
  patientId: string,
  testCode: string,
  testType: string | null | undefined,
  dateFilter: any,
) => {
  return apiService.get<IPanelDetailResult[] | undefined>(
    endpointService.getRoutes().LABS.HISTORY(patientId, testCode),
    {
      params: { dateFilter, testType },
    },
  );
};

const getMedications = async (userId: string, filter: any) => {
  return apiService.get<IPrescriptionResponse>(
    endpointService.getRoutes().MEDICATIONS.QUERY(userId),
    {
      params: filter,
    },
  );
};

const getMedicationsMetadata = async (userId: string) => {
  return apiService.get<IPrescriptionMetadata[]>(
    endpointService.getRoutes().MEDICATIONS.METADATA(userId),
  );
};

const getImmunizations = async (userId: string, filter: any) => {
  return apiService.get<IImmunizationResponse>(
    endpointService.getRoutes().IMMUNIZATIONS.QUERY(userId),
    {
      params: filter,
    },
  );
};

const getImmunizationDetails = async (userId: string, recordId: string) => {
  return apiService.get<IImmunization>(
    endpointService.getRoutes().IMMUNIZATIONS.DETAIL(userId, recordId),
  );
};

const getImmunizationHistory = async (userId: string, vaccineCode: string) => {
  return apiService.get<IImmunizationHistory[]>(
    endpointService.getRoutes().IMMUNIZATIONS.HISTORY(userId, vaccineCode),
  );
};

const getImmunizationsMetadata = async (userId: string) => {
  return apiService.get<IImmunizationMetadata>(
    endpointService.getRoutes().IMMUNIZATIONS.METADATA(userId),
  );
};

const getCovidCard = (userId: string) => {
  return apiService.get<ICovidCard>(
    endpointService.getRoutes().IMMUNIZATIONS.COVID_CARD(userId),
  );
};

export const SearchService = {
  getCovidCard,
  getImmunizations,
  getImmunizationDetails,
  getImmunizationsMetadata,
  getImmunizationHistory,
  getLabs,
  getLabDetail,
  getNewLabs,
  getLabsMetadata,
  getMedications,
  getMedicationsMetadata,
  getPanelTest,
  getLabHistory,
};

export default SearchService;
