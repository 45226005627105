import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { chain, compact, get, map } from 'lodash';
import { Link, useParams, useHistory } from 'react-router-dom';
import {
  useTheme,
  Box,
  Breadcrumbs,
  LinearProgress,
  Button,
  Skeleton,
  Stack,
} from '@mui/material';
import { DataGrid, GridRenderCellParams, GridOverlay } from '@mui/x-data-grid';

import SearchService from 'core/services/search.service';
import { useAuthState } from 'core/contexts/authentication.context';
import MobileHeaderComponent from 'components/MobileHeader/MobileHeader.component';
import { Download as DownloadIcon } from '@mui/icons-material';
import generatePDF, {
  generatePersonalDetails,
  generateLabsReportPDF,
} from 'core/services/export.service';
import { formatDate } from 'utils/utils';
import { IPanelTest } from 'core/interfaces/tests.interface';
import htmlParser from 'html-react-parser';
import ReportContainer from '../../components/Panel_ReportType/PanelReport.component';

import useStyles from './Panel.container.style';

export const handleReportypeParagraph = (contentString: string) => {
  const outputString: string = contentString.replaceAll('\n', '<br>');
  return htmlParser(outputString);
};

export const getPanelTestData = (
  setIsLoading: any,
  setPanelTest: any,
  panelId: string,
) => {
  setIsLoading(true);
  SearchService.getLabDetail(panelId)
    .then((response) =>
      getLabDetailSuccess(response, setPanelTest, setIsLoading),
    )
    .catch(getLabDetailFail);
};

export const getLabDetailSuccess = (
  response,
  setPanelTest: any,
  setIsLoading: any,
) => {
  setPanelTest(response.data);
  setIsLoading(false);
};

export const getLabDetailFail = (setIsLoading) => {
  setIsLoading(false);
  // TODO: Handle error
};

export const singleOrPanelRow = (panelTest: any) => {
  if (panelTest && panelTest.type === 'Panel') {
    return panelTest.panelTests;
  }

  if (panelTest && panelTest.type === 'Single') {
    const rowArray: any[] = [];
    rowArray.push(panelTest.record);
    return rowArray;
  }

  return [];
};

export const exportPDF = (
  user,
  dataRowsOverview,
  dataRowsPanel,
  columnOverview,
  columnsPanel,
) => {
  const dob = get(user, 'dateOfBirth', '');
  generatePDF({
    tables: [
      {
        rows: dataRowsOverview,
        columns: compact(map(columnOverview, 'headerName')),
        yPos: 60,
      },
      {
        rows: dataRowsPanel,
        columns: compact(map(columnsPanel, 'headerName')),
        yPos: 60,
      },
    ],
    title: 'Lab Records',
    personalDetails: generatePersonalDetails(
      `${user?.firstName} ${user?.lastName}`,
      formatDate(dob),
    ),
    filename: 'labs',
    disclaimer: 'panel.disclaimer',
  });
};
//component definition
const Panel = () => {
  interface RouteParams {
    panelId: string;
  }
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [panelTest, setPanelTest] = useState<any>();
  const { panelId } = useParams<RouteParams>();
  const { user } = useAuthState();
  const reportType = 'Report';

  useEffect(() => {
    if (user) {
      getPanelTestData(setIsLoading, setPanelTest, panelId);
    }
  }, [user]);

  const columnOverview = [
    {
      field: 'providedBy',
      headerName: t('panel.providedHeader'),
      flex: 1,
    },
    {
      field: 'collectedOn',
      headerName: t('panel.collectedHeader'),
      flex: 1,
    },
    {
      field: 'updatedOn',
      headerName: t('panel.updatedHeader'),
      flex: 1,
    },
  ];

  const exportReportRecordPDF = (user, dataRowsOverview, columnOverview) => {
    console.log(user?.firstName);
    const dob = get(user, 'dateOfBirth', '');
    generateLabsReportPDF({
      data: panelTest ? panelTest.record.value : '',
      tables: [
        {
          rows: dataRowsOverview,
          columns: compact(map(columnOverview, 'headerName')),
          yPos: 60,
        },
      ],
      title: 'Lab Report',
      personalDetails: generatePersonalDetails(
        `${user?.firstName} ${user?.lastName}`,
        formatDate(dob),
      ),
      filename: 'labs',
      disclaimer: t('panel.disclaimer'),
    });
  };

  const testColumn = (params: GridRenderCellParams) => {
    return (
      <Link
        style={{ ...styles.rowLink, textDecoration: 'none' }}
        to={`/labs/${panelId}/${encodeURIComponent(params.row.testCode)}/${
          panelTest.type
        }`}
      >
        {params.value}
      </Link>
    );
  };

  const resultsColumn = (params: GridRenderCellParams) => {
    if (params.value === '') {
      return '';
    }
    return (
      <div style={styles.resultColumn}>
        <span>{params.value}</span>
      </div>
    );
  };

  const columnsPanel = [
    {
      field: 'testName',
      headerName: t('panel.testHeader'),
      flex: 1,
      renderCell: testColumn,
    },
    { field: 'value', headerName: t('panel.valueHeader'), flex: 0.7 },
    { field: 'range', headerName: t('panel.rangeHeader'), flex: 1 },
    {
      field: 'results',
      headerName: t('panel.resultsHeader'),
      flex: 0.75,
      renderCell: resultsColumn,
    },
  ];

  const renderLoadingOverlay = () => {
    return (
      <GridOverlay>
        <div style={{ position: 'absolute', top: 0, width: '100%' }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  };

  const exportSingleRecord = () => {
    const record: IPanelTest = panelTest;
    const dataColumnsOverview = compact(map(columnOverview, 'field'));
    const dataColumnsPanel = compact(map(columnsPanel, 'field'));
    const dataRowsOverview = map([record], (row) =>
      mapRowsToReportExport(row, dataColumnsOverview),
    );
    const dataRowsPanel = map(record.panelTests, (row) =>
      mapRowsToReportExport(row, dataColumnsPanel),
    );
    exportPDF(
      user,
      dataRowsOverview,
      dataRowsPanel,
      columnOverview,
      columnsPanel,
    );
  };

  const exportReportRecord = () => {
    const rec: IPanelTest = panelTest;
    const dataColumnsOverview = compact(map(columnOverview, 'field'));
    const dataRowsOverview = map([rec], (row) =>
      mapRowsToReportExport(row, dataColumnsOverview),
    );
    exportReportRecordPDF(user, dataRowsOverview, columnOverview);
  };

  const mapRowsToReportExport = (row, columns: string[]) =>
    chain(row).pick(columns).values().value();

  const navigateBack = (e) => {
    e.preventDefault;
    history.goBack();
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.asideActions}>
        <MobileHeaderComponent hideBackButton />
      </Box>
      <Box>
        <Breadcrumbs aria-label="breadcrumb" separator="›">
          <Link
            style={{ ...styles.rowLink, textDecoration: 'none' }}
            onClick={navigateBack}
            to="#"
          >
            {t('panel.allTestBreadCrumb')}
          </Link>
          <span>{panelTest?.name}</span>
        </Breadcrumbs>
      </Box>
      <div style={styles.pageTitle}>
        {isLoading && <Skeleton height={70} width="50%" />}
        {!isLoading && (
          <React.Fragment>
            <span>{panelTest?.name}</span>
            <Button
              style={{ display: 'flex', flex: 'none', alignSelf: 'flex-end' }}
              title={t('actions.download')}
              aria-label={t('actions.download')}
              onClick={
                panelTest.type === reportType
                  ? exportReportRecord
                  : exportSingleRecord
              }
            >
              <DownloadIcon />
              <span>{t('actions.download')}</span>
            </Button>
          </React.Fragment>
        )}
      </div>
      {isLoading && (
        <Stack spacing={1}>
          <Skeleton height={190} width="100%" />
          <Skeleton variant="rectangular" height={250} width="100%" />
        </Stack>
      )}
      {!isLoading && (
        <React.Fragment>
          <Box sx={{ display: 'flex' }}>
            <div style={{ overflowX: 'auto', width: '100%' }}>
              <DataGrid
                columns={columnOverview}
                rows={panelTest ? [panelTest] : []}
                loading={isLoading}
                components={{
                  // NoRowsOverlay: renderNoRows,
                  LoadingOverlay: renderLoadingOverlay,
                }}
                autoHeight
                disableColumnMenu
                getRowId={(r) => r.name}
                disableColumnSelector
                disableDensitySelector
                disableSelectionOnClick
                hideFooter
              />
            </div>
          </Box>
          <Box sx={{ display: 'flex', marginTop: '30px' }}>
            <div style={{ overflowX: 'auto', width: '100%' }}>
              {panelTest && panelTest.type === reportType && (
                <ReportContainer
                  htmlContent={handleReportypeParagraph(panelTest.record.value)}
                />
              )}

              {(panelTest && panelTest.type) != reportType && (
                <DataGrid
                  loading={isLoading}
                  components={{
                    // NoRowsOverlay: renderNoRows,
                    LoadingOverlay: renderLoadingOverlay,
                  }}
                  columns={columnsPanel}
                  rows={singleOrPanelRow(panelTest)}
                  autoHeight
                  getRowId={(r) => r.testCode}
                  disableColumnMenu
                  disableColumnSelector
                  disableDensitySelector
                  disableSelectionOnClick
                  hideFooter
                />
              )}
            </div>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default Panel;
