import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Drawer,
  // MenuItem,
  useTheme,
} from '@mui/material';
import {
  Menu as MenuIcon,
  Home as HomeIcon,
  People as PeopleIcon,
  Help as HelpIcon,
  Settings as SettingsIcon,
  // Check as CheckIcon,
  Person as PersonIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { noop } from 'lodash';

import {
  useAuthDispatch,
  useAuthState,
} from 'core/contexts/authentication.context';
import { INavigationLink } from 'core/interfaces/navigation.interface';
import MedicationIcon from 'assets/images/medication_icon.svg';
import ImmunizationIconComponent from 'components/ImmunizationIcon/ImmunizationIcon.component';
import LabIconComponent from 'components/LabIcon/LabIcon.component';
// import CommunityIcon from 'assets/images/community_icon.svg';
import useStyles from './MobileNavigation.component.styles';
import AuthService from 'core/services/auth.service';

export const MobileNavigationComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuthState();
  const dispatch = useAuthDispatch();

  const [isOpen, setState] = React.useState<boolean>(false);
  const [activePage, setActivePage] = React.useState<string | undefined>(
    location.pathname,
  );
  const bodyTag: HTMLElement = document.body;

  useEffect(noop, [user]);

  useEffect(() => {
    if (activePage === 'accounts') {
      return;
    }

    if (activePage && activePage !== 'more') {
      return history.push(activePage);
    } else if (activePage === 'more') {
      toggleDrawer(!isOpen);
    }
  }, [activePage]);

  const toggleDrawer = (open: boolean) => {
    const action = open ? 'add' : 'remove';
    bodyTag.classList[action]('lockScroll');
    setState(open);
    setActivePage(undefined);
  };

  const logout = () => {
    AuthService.logout(dispatch);
    //    history.push('/login');
  };

  const onClose = (link) => {
    if (link.disabled) {
      return;
    }
    if (link.id === 'logout') {
      logout();
      toggleDrawer(false);
    } else {
      setActivePage(location.pathname);
      toggleDrawer(false);
    }
  };

  // const renderContexts = () => {
  //   if (account && account.contexts.length < 2) {
  //     return '';
  //   }

  //   return (
  //     <div style={styles.contextList} key="contextList">
  //       {account?.contexts.map((context: IContext, index: number) => {
  //         let style = { paddingLeft: '91px' };
  //         const userId = get(user, '_id', '');
  //         const isActive = context.profileId === userId;
  //         if (isActive) {
  //           style = { ...style, ...styles.activeContext };
  //         }

  //         return (
  //           <MenuItem
  //             key={`context_${index}`}
  //             style={style}
  //             onClick={() => changeContext(context)}
  //           >
  //             {renderAvatar(context, true)}
  //             {`${context?.firstName} ${context?.lastName}`}
  //             {isActive && (
  //               <Box sx={styles.endIconDecorator}>
  //                 <CheckIcon fontSize="small" />
  //               </Box>
  //             )}
  //           </MenuItem>
  //         );
  //       })}
  //     </div>
  //   );
  // };

  // const changeContext = (context) => {
  //   getProfile(context.profileId);
  //   toggleDrawer(false);
  // };

  // const getProfile = (userId: string) => {
  //   ProfileService.getProfile(dispatch, userId).catch(getProfileFail);
  // };

  // const getProfileFail = () => {
  //   // TODO: Handle fail
  // };

  const navigationLinks: INavigationLink[] = [
    {
      id: 'home',
      path: '/home',
      icon: () => <HomeIcon />,
    },
    {
      id: 'immunizations',
      path: '/immunizations',
      icon: () => <ImmunizationIconComponent />,
    },
    {
      id: 'medications',
      path: '/medications',
      icon: () => (
        <div>
          <img src={MedicationIcon} alt={t('navigation.medications')} />
        </div>
      ),
    },
    {
      id: 'more',
      path: 'more',
      icon: () => <MenuIcon />,
      isActive: (activePage: string) => activePage === 'more',
    },
  ];

  const menuLinks: INavigationLink[] = [
    // {
    //   id: 'accounts',
    //   path: 'accounts',
    //   icon: () => <PeopleIcon />,
    //   disabled: true,
    //   children: renderContexts,
    // },
    {
      id: 'profile',
      path: '/profile',
      icon: () => <PersonIcon />,
    },
    {
      id: 'labs',
      path: '/labs',
      icon: () => <LabIconComponent />,
    },
    // {
    //   id: 'communityCare',
    //   path: '/community-care',
    //   icon: () => (
    //     <img
    //       style={styles.communityIcon}
    //       src={CommunityIcon}
    //       alt={t('navigation.communityCare')}
    //     />
    //   ),
    // },
    {
      id: 'settings',
      path: '/settings',
      icon: () => <SettingsIcon />,
    },
    {
      id: 'contact',
      path: '/contact',
      icon: () => <PeopleIcon />,
    },
    {
      id: 'supportCenter',
      path: '/support',
      icon: () => <HelpIcon />,
    },
    {
      id: 'logout',
      path: '/login',
      icon: () => <LogoutIcon />,
    },
  ];

  const renderAvatar = (user, isSmall = false) => {
    let avatarStyle = styles.avatarIcon;
    if (isSmall) {
      avatarStyle = { ...avatarStyle, ...styles.avatarSmall };
    }
    return (
      <Box sx={styles.avatar}>
        <Avatar sx={avatarStyle} alt={`${user?.firstName} ${user?.lastName}`}>
          {user?.firstName?.charAt(0)} {user?.lastName?.charAt(0)}
        </Avatar>
      </Box>
    );
  };

  return (
    <Box
      component="nav"
      sx={{
        ...styles.root,
        position: 'fixed',
      }}
    >
      <Drawer
        variant="temporary"
        anchor="bottom"
        hideBackdrop
        elevation={0}
        onClose={onClose}
        sx={{
          width: '100%',
          bottom: '56px',
          '.MuiDrawer-paper': {
            color: theme.palette.grayScale.light,
            backgroundColor: theme.palette.primary.dark,
            position: 'fixed',
            top: '0px',
            bottom: '56px',
            left: '0px',
            right: '0px',
            overflowY: 'auto',
          },
        }}
        open={isOpen}
      >
        <ul style={styles.links}>
          {user && (
            <NavLink
              key="userName"
              to="/profile"
              style={styles.covidLink}
              onClick={onClose}
            >
              <Box sx={{ ...styles.panelContainer, flexDirection: 'row' }}>
                {renderAvatar(user)}
                <div>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      ...styles.personalInfo,
                    }}
                  >
                    <span
                      style={{ ...styles.name, textTransform: 'capitalize' }}
                    >{`${user?.firstName?.toLowerCase()}`}</span>
                  </Box>
                </div>
              </Box>
            </NavLink>
          )}

          {menuLinks.map((link: INavigationLink, linkId: number) => [
            <NavLink
              style={{
                ...styles.menuLink,
                pointerEvents: link.disabled ? 'none' : 'auto',
              }}
              key={`${linkId}_${link.id}`}
              to={link.path}
              activeStyle={styles.active}
              title={t(`navigation.${link.id}`)}
              aria-label={`${t(`navigation.${link.id}`)}`}
              onClick={() => onClose(link)}
            >
              <div style={styles.menuLinkIcon}>{link.icon && link.icon()}</div>
              <span style={styles.linkText}>{t(`navigation.${link.id}`)}</span>
              {link?.endDecorator && link?.endDecorator()}
            </NavLink>,
            link.children && link.children(),
          ])}
        </ul>
      </Drawer>
      <BottomNavigation
        showLabels
        value={location.pathname}
        onChange={(event, page: string) => {
          setActivePage(page);
        }}
      >
        {navigationLinks.map((link: INavigationLink) => {
          const active =
            link.isActive && link.isActive(activePage) ? styles.active : {};

          return (
            <BottomNavigationAction
              sx={{ ...styles.link, ...active }}
              key={link.id}
              value={link.path}
              label={t(`navigation.${link.id}`)}
              icon={link.icon()}
            />
          );
        })}
      </BottomNavigation>
    </Box>
  );
};

export default MobileNavigationComponent;
