import { Theme } from '@mui/material';
import Color from 'color';

export const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    padding: '16px 51px',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'column',
      padding: '15px',
    },
  },
  links: {
    padding: 0,
    margin: 0,
    paddingInlineStart: 0,
    display: 'flex',
    flex: 'auto',
  },
  link: {
    display: 'inlineBlock',
    textDecoration: 'none',
  },
  linkText: {
    color: theme.palette.primary.main,
    fontSize: '0.75rem',
  },
  copyright: {
    display: 'flex',
    flex: 'none',
    fontSize: '0.875rem',
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      marginTop: '15px',
      justifyContent: 'center',
    },
  },
});

export default styles;
