import { useTranslation } from 'react-i18next';

import 'assets/locales/i18n';
import RoutesComponent from 'components/Routes/Routes.component';
import InitializeComponent from 'components/Initiatlize/Initialize.component';
import SessionTimer from 'components/SessionTimer/SessionTimer.component';
import './App.container.scss';

function AppContainer() {
  const { i18n } = useTranslation();

  document.documentElement.lang = i18n.language;

  return (
    <div className="App">
      <InitializeComponent />
      <SessionTimer />
      <RoutesComponent />
    </div>
  );
}

export default AppContainer;
