import React from 'react';
import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';
import TutBody from './TutBody.component';
import { useTranslation } from 'react-i18next';
import Tut1 from 'assets/images/Tutorial1.png';
import Tut2 from 'assets/images/Tutorial2.png';
import Tut3 from 'assets/images/Tutorial3.png';
import Tut6 from 'assets/images/Tutorial6.png';
import Tut5 from 'assets/images/Tutorial5.png';
import Tut4 from 'assets/images/Tutorial4.png';
import { noop } from 'lodash';
import Tut1Mobile from 'assets/images/Tutorial1Mobile.png';
import Tut2Mobile from 'assets/images/Tutorial2Mobile.png';
import Tut3Mobile from 'assets/images/TutorialTestresultDetailsMobile.png';
import Tut5Mobile from 'assets/images/Tutorial5Mobile.png';
import Tut6Mobile from 'assets/images/Tutorial6Mobile.png';
import Tut4Mobile from 'assets/images/Tutorial4Mobile.png';

interface ITutorialsProps {
  openDialog: boolean;
  setOpenDialog: any;
}

// eslint-disable-next-line
const Carousel = require('react-responsive-carousel').Carousel;

const Tutorial = ({ openDialog, setOpenDialog }: ITutorialsProps) => {
  const { t } = useTranslation();
  const tutorialContent = [
    {
      tutTip: t('tutorials.tut1.tut1Tip'),
      tutDesc: t('tutorials.tut1.tut1Desc'),
      tutImage: Tut1,
      tutImageMobile: Tut1Mobile,
    },
    {
      tutTip: t('tutorials.tut2.tut2Tip'),
      tutDesc: t('tutorials.tut2.tut2Desc'),
      tutImage: Tut2,
      tutImageMobile: Tut2Mobile,
    },
    {
      tutTip: t('tutorials.tut3.tut3Tip'),
      tutDesc: t('tutorials.tut3.tut3Desc'),
      tutImage: Tut3,
      tutImageMobile: Tut3Mobile,
    },
    {
      tutTip: t('tutorials.tut4.tut4Tip'),
      tutDesc: t('tutorials.tut4.tut4Desc'),
      tutImage: Tut4,
      tutImageMobile: Tut4Mobile,
    },
    {
      tutTip: t('tutorials.tut5.tut5Tip'),
      tutDesc: t('tutorials.tut5.tut5Desc'),
      tutImage: Tut5,
      tutImageMobile: Tut5Mobile,
    },
    {
      tutTip: t('tutorials.tut6.tut6Tip'),
      tutDesc: t('tutorials.tut6.tut6Desc'),
      tutImage: Tut6,
      tutImageMobile: Tut6Mobile,
    },
  ];

  return (
    <Dialog
      open={openDialog}
      onClose={setOpenDialog}
      PaperProps={{
        sx: {
          minHeight: '70vh',
          maxHeight: '95vh',
          maxWidth: '70vw',
          minWidth: '70vw',
        },
      }}
    >
      <DialogContent sx={{ width: 'auto' }}>
        <Carousel
          axis="horizontal"
          autoPlay={false}
          showArrows={true}
          showThumbs={false}
          showStatus={false}
          onChange={noop}
          onClickItem={noop}
          onClickThumb={noop}
        >
          {tutorialContent.map((tip, i) => (
            <TutBody
              tutTitle={tip.tutTip}
              tutDescription={tip.tutDesc}
              key={i}
              tutImage={tip.tutImage}
              tutImageMobile={tip.tutImageMobile}
            />
          ))}
        </Carousel>
      </DialogContent>
      <DialogActions>
        <Button onClick={setOpenDialog}>{t('tutorials.exitTutorial')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Tutorial;
