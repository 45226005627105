import { Theme } from '@mui/material';
import Color from 'color';

export const styles = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    padding: '20px 15px',
    color: theme.palette.grayScale.light,
    fontSize: '0.875rem',
    display: 'flex',
    minWidth: { tablet: '520px', mobile: '100%' },
    width: { tablet: '500px', mobile: '100%' },
    gap: { tablet: '26px', mobile: '11px' },
    marginBottom: { tablet: '26px', mobile: '20px' },
  },
  qr: {
    backgroundColor: theme.palette.grayScale.light,
    padding: { tablet: '1px', mobile: '2px' },
    width: { tablet: '200px', mobile: '120px' },
    height: { tablet: 'auto', mobile: 'auto' },
    margin: 'auto',
    // margin: { mobile: 'auto 0', tablet: '0' },
  },
  code: {
    width: '100%',
    display: 'flex',
  },
  details: {
    display: 'flex',
  },
  cardBoldText: {
    fontSize: { tablet: '.9rem', mobile: '.5rem' },
    fontWeight: 600,
    marginBottom: '10px',
  },
  subtitle: {
    marginBottom: '32px',
  },
  info: {
    display: { mobile: 'none', tablet: 'flex' },
    justifyContent: 'center',
  },
  userInfo: {
    fontSize: { tablet: '.7rem', mobile: '.3rem' },
    fontWeight: 400,
    marginBottom: '10px',
  },
  mobileInfo: {
    display: { tablet: 'none' },
  },
  value: {
    marginLeft: '5px',
    fontWeight: 700,
  },
  actions: {
    display: { tablet: 'flex', mobile: 'none' },
    justifyContent: { tablet: 'flex-end', mobile: 'flex-start' },
    flex: 'none',
    '& > *': {
      color: theme.palette.grayScale.light,
    },
  },
  actionButton: {
    color: theme.palette.grayScale.light,
  },
  actionButtonDrawer: {
    color: theme.palette.grayScale.light,
    marginLeft: '25%',
  },
  icon: {
    marginRight: '10px',
  },
  cardTitle: {
    fontSize: { tablet: '1.25rem', mobile: '.9rem' },
    fontWeight: 700,
    marginBottom: '3px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: { tablet: 'auto', mobile: '100%' },
  },
  recordCardPaper: {
    width: { tablet: '510px', mobile: '100%' },
    padding: '24px 20px',
    display: 'flex',
    fontSize: '0.875rem',
  },
  covidCardMobile: {
    padding: '50px 30px',
    color: theme.palette.grayScale.light,
  },
  closeButton: {
    bottom: '30px',
    right: '30px',
    color: theme.palette.grayScale.light,
  },
  drawerContent: {
    marginBottom: '38px',
  },
  doseInfo: {
    marginTop: '38px',
    display: 'flex',
    justifyContent: 'center',
  },
  name: {
    fontSize: '2.125rem',
    margin: '38px 0 15px 0',
  },
  sectionTitle: {
    color: Color(theme.palette.grayScale.dark).hsl().alpha(0.6).toString(),
    fontSize: '1.5rem',
    marginBottom: '23px',
  },
});

export default styles;
