import apiService from './api.service';
import endpointService from './endpoint.service';
import { ICondition } from '../interfaces/condition.interface';

async function getTermsAndConditions(params?: {}) {
  return apiService.get<ICondition[]>(
    endpointService.getRoutes().CONDITIONS(),
    {
      params,
    },
  );
}

export const service = {
  getTermsAndConditions,
};

export default service;
