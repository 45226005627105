import { Theme } from '@mui/material';
import Color from 'color';

const styles = (theme: Theme) => ({
  detailBox: {
    backgroundColor: Color(theme.palette.grayScale.gradient100)
      .alpha(1)
      .hsl()
      .toString(),
    padding: '20px',
  },
  sectionTitle: {
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
    fontSize: '1.5rem',
    marginBottom: '23px',
  },
  bold: {
    fontWeight: '600',
  },
});

export default styles;
