import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';
import LoginComponent from 'components/Login/Login.component';
import LayoutComponent from 'components/Layout/Layout.component';
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute.component';
import ConditionsContainer from 'containers/Conditions/Conditions.container';
import LoginCallbackComponent from 'components/LoginCallback/LoginCallback.component';
import NotFoundContainer from 'containers/NotFound/NotFound.container';
import RegistrationConditionsContainer from 'containers/RegistrationConditions/RegistrationConditions.container';
import SupportPublicContainer from 'containers/SupportPublic/SupportPublic.container';
import SupportTermContainer from 'containers/Support/SupportTerms.container';

export const RoutesComponent = () => (
  <Switch>
    <Route path="/login" exact={true} component={LoginComponent} />
    <Route path="/implicit/callback" component={LoginCallbackComponent} />
    <Route path="/help" exact={true} component={SupportPublicContainer} />
    <Route path="/404" component={NotFoundContainer} />
    <Route
      path="/imfaq"
      component={() => {
        window.location.href = 'https://help.id.me/hc/en-us';
        return null;
      }}
    />
    <Route
      path="/termsandconditions"
      exact={true}
      component={SupportTermContainer}
    />
    <Route
      path="/register/:conditionType"
      component={RegistrationConditionsContainer}
    />
    <Redirect path="/register" to="/register/ " />
    <ProtectedRoute
      path="/conditions/:conditionType"
      component={ConditionsContainer}
    />
    <Redirect path="/conditions" to="/conditions/ " />
    <ProtectedRoute path="/" component={LayoutComponent} />
  </Switch>
);

export default RoutesComponent;
