export const styles = () => ({
  root: {
    padding: '45px',
  },
  notFoundImage: {
    width: '100%',
    maxWidth: '500px',
    marginBottom: '25px',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 600,
  },
  content: {
    '& > *': {
      marginBottom: '25px',
    },
  },
});

export default styles;
