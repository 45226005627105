import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

import useStyles from './Footer.component.styles';

interface INavigationLink {
  id: string;
  path: string;
  icon?: any;
}

export const FooterComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);

  // TODO: Undo when URLs for footer links are available
  const navigationLinks: INavigationLink[] = [
    // {
    //   id: 'about',
    //   path: '/about',
    // },
    {
      id: 'conditions',
      path: '/termsandconditions',
    },
    // {
    //   id: 'privacyPolicy',
    //   path: '/privacy',
    // },
    // {
    //   id: 'accessibility',
    //   path: '/accessibility',
    // },
  ];

  return (
    <footer>
      <Box sx={{ ...styles.root, flexDirection: 'row' }}>
        <ul style={styles.links}>
          {navigationLinks.map((link: INavigationLink, linkId: number) => (
            <NavLink
              style={{
                ...styles.link,
                textAlign: 'center',
                marginRight: linkId !== navigationLinks.length ? '20px' : 0,
              }}
              key={`${linkId}_${link.id}`}
              to={link.path}
              title={t(`footer.${link.id}`)}
              aria-label={`${t(`footer.${link.id}`)}`}
            >
              <span style={styles.linkText}>{t(`footer.${link.id}`)}</span>
            </NavLink>
          ))}
        </ul>
        <Box sx={styles.copyright}>
          <span>
            © 2021 {t('companyName')}. {t('footer.allRightsReserved')}.
          </span>
        </Box>
      </Box>
    </footer>
  );
};

export default FooterComponent;
