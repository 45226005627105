import { Theme } from '@mui/material';

export const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    flex: 'none',
    justifyContent: 'center',
    width: '100%',
  },
  logo: {
    width: '25px',
  },
  backButton: {
    color: theme.palette.primary.main,
    left: 0,
    top: 0,
  },
  backIcon: {
    marginRight: '14px',
  },
});

export default styles;
