import { Theme } from '@mui/material';
import Color from 'color';

const styles = (theme: Theme) => ({
  root: {
    padding: { tablet: '45px', mobile: '45px 15px' },
  },
  asideActions: {
    display: 'none',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'row',
      display: 'flex',
      flex: 'none',
      marginBottom: '23px',
    },
  },
  pageTitle: {
    fontSize: '2rem',
    marginBottom: '16px',
  },
  sectionTitle: {
    fontSize: '1.3rem',
    marginBottom: '20px',
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
  },
  subtitle: {
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
    marginBottom: '20px',
  },
  rowLink: {
    color: Color(theme.palette.primary.main).alpha(1).hsl().toString(),
  },
  actions: {
    display: 'flex',
    justifyContent: { tablet: 'flex-end', mobile: 'flex-start' },
    alignItems: 'flex-start',
    flex: 'auto',
    color: theme.palette.primary.main,
  },
  actionButton: {
    marginLeft: '15px',
  },
  grouping: {
    marginBottom: '0',
  },
  formControl: {
    width: { tablet: '250px', mobile: '100%' },
  },
  resultsContainer: {
    height: 600,
    width: '100%',
  },
  actionsCell: {
    display: 'flex',
  },
  labTestLabel: {
    display: 'flex',
    border: '1px solid #cdcdcd',
    padding: '5px 10px',
    borderRadius: '159px',
    lineHeight: '1',
  },
  labTestIcon: {
    width: '12px',
    display: 'inline-block',
    marginRight: '5px',
  },
});

export default styles;
