import apiService from './api.service';
import endpointService from './endpoint.service';
import { ICondition } from 'core/interfaces/condition.interface';
import { IProfile } from 'core/interfaces/profile.interface';
import { IAcknowledgement } from 'core/interfaces/acknowledgement.interface';
import { actionTypes } from '../contexts/authentication.reducer';
import AuthService from './auth.service';

const getProfile = async (dispatch: any, userId: string) => {
  try {
    dispatch({ type: actionTypes.FETCH_CONTEXT });
    const response = await apiService.get<IProfile>(
      endpointService.getRoutes().PROFILE.USER_PROFILE(userId),
      {},
    );

    const user: IProfile = response.data;
    dispatch({
      type: actionTypes.FETCH_CONTEXT_SUCCESS,
      payload: {
        user,
      },
    });

    return user;
  } catch (err) {
    AuthService.handleAuthError(dispatch, err);
  }
};

const getProfileByEmail = async (email: string) => {
  return apiService.get<IProfile[]>(
    endpointService.getRoutes().PROFILE.USER_PROFILE_BY_EMAIL(email),
    {},
  );
};

const getProfiles = async (userId: string) => {
  return apiService.get<IProfile[]>(
    endpointService.getRoutes().PROFILE.USER_PROFILES_BY_CONTEXT(userId),
  );
};

const updateProfile = async (
  user: IProfile | undefined,
  payload: any,
  dispatch: any,
) => {
  try {
    apiService.put<any>(
      endpointService.getRoutes().PROFILE.USER_PROFILE(user?._id),
      payload,
    );
    const modUser = { ...user, tutorialSeen: true };
    dispatch({
      type: actionTypes.UPDATE_USER_SUCCESS,
      payload: {
        user: modUser,
      },
    });

    return Promise.resolve();
  } catch (err) {
    AuthService.handleAuthError(dispatch, err);
  }
};

const acknowledgeCondition = async (
  userId: string,
  payload: IAcknowledgement[],
) => {
  return apiService.post<IAcknowledgement[]>(
    endpointService.getRoutes().PROFILE.ACKNOWLEDGEMENTS(userId),
    payload,
  );
};

const getUnacknowledgedConditions = async (userId: string, language = 'en') => {
  return apiService.get<ICondition[]>(
    endpointService.getRoutes().PROFILE.UNACKNOWLEDGED(userId),
    {
      params: {
        language,
      },
    },
  );
};

const saveAcknowledgementToStorage = (acknowledgement: IAcknowledgement) => {
  setAcknowledgements(acknowledgement);
};

const getAcknowledgementFromStorage = () => {
  return getAcknowledgements();
};

// region Session Management
const getAcknowledgements = (): IAcknowledgement[] => {
  const storedAcknowledgements =
    sessionStorage.getItem('acknowledgements') || '[]';
  return JSON.parse(storedAcknowledgements);
};

const setAcknowledgements = (acknowledgement: IAcknowledgement) => {
  const acknowledgements = getAcknowledgements();
  acknowledgements.push(acknowledgement);
  clearAcknowledgements();
  sessionStorage.setItem('acknowledgements', JSON.stringify(acknowledgements));
};

const clearAcknowledgements = () => {
  sessionStorage.removeItem('acknowledgements');
};
// endregion

export const service = {
  acknowledgeCondition,
  clearAcknowledgements,
  getAcknowledgementFromStorage,
  getProfile,
  getProfiles,
  getProfileByEmail,
  getUnacknowledgedConditions,
  saveAcknowledgementToStorage,
  updateProfile,
};

export default service;
