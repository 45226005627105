const supportedLanguages = [
  {
    id: 'en',
    displayName: 'languages.en',
  },
  {
    id: 'es',
    displayName: 'languages.es',
  },
];

const getShortLanguage = (language: string) => language.split('-')[0];

export const service = {
  supportedLanguages,
  getShortLanguage,
};

export default service;
