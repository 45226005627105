import { IProfile } from '../interfaces/profile.interface';

const token = localStorage.getItem('accessToken') || '';
const refreshToken = localStorage.getItem('refreshToken') || '';
const idToken = localStorage.getItem('idToken') || '';

export const initialState = {
  account: undefined,
  user: undefined,
  token: '' || token,
  refreshToken: '' || refreshToken,
  idToken: '' || idToken,
  loading: false,
  errorMessage: '',
  routeAfterAuth: '',
};

export enum actionTypes {
  REQUEST_LOGIN = 'REQUEST_LOGIN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGOUT = 'LOGOUT',
  LOGIN_ERROR = 'LOGIN_ERROR',
  FETCH_USER = 'FETCH_USER',
  FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS',
  FETCH_CONTEXT = 'FETCH_CONTEXT',
  FETCH_CONTEXT_SUCCESS = 'FETCH_CONTEXT_SUCCESS',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
}

export interface IReducerState {
  account: IProfile | undefined;
  user: IProfile | undefined;
  token: string;
  refreshToken: string;
  idToken: string;
  loading: boolean;
  errorMessage?: string;
}

interface IReducerAction {
  type: string;
  payload: {
    user?: IProfile;
    accessToken?: string;
    refreshToken?: string;
    idToken?: string;
  };
  error: string | null;
}

export const AuthReducer = (initialState: any, action: IReducerAction) => {
  switch (action.type) {
    case actionTypes.REQUEST_LOGIN:
      return {
        ...initialState,
        loading: true,
      };

    case actionTypes.LOGIN_SUCCESS:
      return {
        ...initialState,
        token: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        idToken: action.payload.idToken,
        loading: false,
      };

    case actionTypes.LOGOUT:
      return {
        ...initialState,
        account: '',
        user: '',
        token: '',
        idToken: '',
        refreshToken: '',
      };

    case actionTypes.LOGIN_ERROR:
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };

    case actionTypes.FETCH_USER:
      return {
        ...initialState,
        loading: true,
      };

    case actionTypes.FETCH_USER_SUCCESS:
      return {
        ...initialState,
        account: action.payload.user,
        user: action.payload.user,
        loading: false,
      };

    case actionTypes.UPDATE_USER_SUCCESS:
      return {
        ...initialState,
        user: action.payload.user,
      };

    case actionTypes.FETCH_CONTEXT:
      return {
        ...initialState,
        loading: true,
      };

    case actionTypes.FETCH_CONTEXT_SUCCESS:
      return {
        ...initialState,
        user: action.payload.user,
        loading: false,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
