import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CssBaseline,
  Skeleton,
  Typography,
  useTheme,
  Link as L,
  Divider,
} from '@mui/material';
import Button from '@mui/material/Button';
import { groupBy, toPairs } from 'lodash';
import {
  ArrowBack as ArrowLeftIcon,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';
import { Link, useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';

import SupportService from 'core/services/support.service';
import { ISupportTopic } from 'core/interfaces/support-topic.interface';
import MobileNavigationComponent from 'components/MobileNavigation/MobileNavigation.component';
import { useAuthState } from 'core/contexts/authentication.context';
import MediaQuery from 'react-responsive';

import useStyles from './Support.container.styles';
import 'styles/common.scss';

export default function SupportContainer() {
  const drawerWidth = '30%';

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sections, setSections] = useState<[string, ISupportTopic[]][]>([]);
  const { t, i18n } = useTranslation();
  const { user } = useAuthState();
  const history = useHistory();
  const theme = useTheme();
  const styles = useStyles(theme, {
    drawerWidth,
  });

  useEffect(() => {
    getSupportTopics();
  }, [setSections, i18n.language]);

  const getSupportTopics = () => {
    setIsLoading(true);
    SupportService.getSupportTopics({
      language: i18n.language,
    })
      .then(getSupportTopicsSuccess)
      .catch(getSupportTopicsFail);
  };

  const getSupportTopicsSuccess = (
    response: AxiosResponse<ISupportTopic[]>,
  ) => {
    setIsLoading(false);
    const topics = response.data;
    console.log(topics);
    const sections: [string, ISupportTopic[]][] = toPairs(
      groupBy(topics, 'section'),
    );

    setSections(sections);
  };

  const getSupportTopicsFail = () => {
    setIsLoading(false);
    // TODO: Handle with Snackbar
  };

  const renderSection = (section, topics) => (
    <Box key={section} sx={{ ...styles.section, flexDirection: 'row' }}>
      <Box sx={styles.sectionTitle}>{section}</Box>
      <div style={styles.topicsContainer}>
        {topics.map((topic: ISupportTopic, topicId: number) =>
          renderTopic(topic, topicId),
        )}
      </div>
    </Box>
  );

  const renderTopic = (topic, topicId) => (
    <Accordion key={topicId}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${topic._id}_content`}
        id={topic._id}
        sx={styles.accordion}
      >
        <Typography sx={{ fontWeight: 'medium' }}>{topic.title}</Typography>
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        <Typography>{topic.content} </Typography>
        <L
          href={
            topic.link.includes('https')
              ? topic.link
              : `${'mailto:' + topic.link}`
          }
          target="_blank"
        >
          {topic.link}
        </L>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <Box sx={styles.root}>
      <CssBaseline />
      <Box component="main" sx={{ ...styles.content, flexDirection: 'column' }}>
        <Box sx={styles.asideActions}>
          <Box sx={{ display: 'flex', flex: 'none' }}>
            <Button
              title={t('general.back')}
              aria-label={t('general.back')}
              style={styles.backButton}
              onClick={() => history.push('/home')}
            >
              <ArrowLeftIcon fontSize="small" style={styles.backIcon} />
              <span>{t('general.back')}</span>
            </Button>
          </Box>
        </Box>
        <div style={styles.pageTitle}>
          <span>{t('support.pageTitle')}</span>
        </div>
        <div style={styles.instruction}>
          <span>
            <Trans i18nKey="support.instruction">
              We know some extra information can be helpful to use the platform.
              Find answers to your questions about using Cynchealth patient
              portal <Link to="/conditions">here</Link>.
            </Trans>
          </span>
        </div>
        {isLoading && (
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              gap: '20px',
              flexDirection: { tablet: 'row', mobile: 'column' },
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '50%',
                alignItems: 'flex-start',
              }}
            >
              <Skeleton width={'100%'} />
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                gap: '20px',
                justifyContent: 'flex-start',
              }}
            >
              <Skeleton width={'100%'} />
              <Skeleton width={'100%'} height={100} />
            </div>
          </Box>
        )}
        {!isLoading &&
          sections.map(([section, topics]) => renderSection(section, topics))}
      </Box>
      {user && (
        <MediaQuery maxWidth={theme.breakpoints.values.tablet}>
          <MobileNavigationComponent />
        </MediaQuery>
      )}
    </Box>
  );
}
