import { Theme } from '@mui/material';

export const styles = (theme: Theme) => ({
  root: {
    padding: '30px',
  },
  asideActions: {
    display: 'none',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'row',
      display: 'flex',
      flex: 'none',
      marginBottom: '23px',
    },
  },
  pageTitle: {
    fontSize: '3rem',
    marginBottom: '16px',
  },
  section: {
    marginBottom: '30px',
  },
  sectionTitle: {
    fontSize: '1.2rem',
    fontWeight: 500,
    marginBottom: '15px',
  },
  sectionContainer: {
    padding: '24px 40px',
    display: 'flex',
    marginBottom: '20px',
  },
  instruction: {
    marginTop: '10px',
    fontSize: '0.875rem',
  },
  cta: {
    display: 'block',
    maxWidth: '200px',
    width: '100%',
    marginTop: '44px',
  },
  language: {
    maxWidth: '300px',
  },
  content: {
    width: { tablet: '100%', desktop: '75%' },
  },
  contactValues: {
    fontWeight: 500,
  },
  contactInfoContainer: {
    display: 'table',
    tableLayout: 'fixed',
    marginTop: '3px',
  },
  contactInfoRow: {
    display: 'table-row',
  },
  contactInfoCell: {
    display: 'table-cell',
    paddingRight: '15px',
  },
  spacer: {
    marginBottom: '20px',
  },
  contactValue: {
    paddingRight: '15px',
  },
});

export default styles;
