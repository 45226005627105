import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';

import emptyLab from 'assets/images/emptyLab.svg';

import useStyles from './EmptyLabsTile.component.styles';

export const EmptyLabsTileComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Box
      component={NavLink}
      to="/labs"
      sx={{
        ...styles.recordCard,
        ...styles.emptyCard,
        flexDirection: { tablet: 'row', mobile: 'column' },
      }}
    >
      <Box sx={styles.emptyLabImage}>
        <img
          alt={t('labs.emptyTestTitle')}
          src={emptyLab}
          style={styles.emptyTestImg}
        />
      </Box>
      <div
        style={{
          ...styles.details,
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div style={{ ...styles.cardTitle }}>{t('labs.emptyTestTitle')}</div>
        <div style={styles.subtitle}>
          <span>{t('labs.emptyTestSubtitle')}</span>
        </div>
      </div>
    </Box>
  );
};

export default EmptyLabsTileComponent;
