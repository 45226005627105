// eslint-disable-next-line
import react from 'react';
import useStyles from './MetaTable.component.style';
import {
  useTheme,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface metaTableProp {
  performers: string;
  modifiedDate: string;
}

const MetaTable = ({ performers, modifiedDate }: metaTableProp) => {
  // eslint-disable-next-line
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);
  const PerformersArray: string[] = performers ? performers.split(',') : ['-'];

  const performersRows = (PerformersArrayParameter: string[]) => {
    if (PerformersArrayParameter.length > 1) {
      return (
        <>
          {PerformersArrayParameter.map((performer: string, index: number) => {
            return (
              <TableRow key={`${performer}_${index}`}>
                {index === 0 && (
                  <>
                    <TableCell sx={styles.boldRow} align="left">
                      {t('metaTable.providedBy')}
                    </TableCell>
                    <TableCell align="left">{performer}</TableCell>
                  </>
                )}
                {index > 0 && (
                  <>
                    <TableCell align="left"> </TableCell>
                    <TableCell align="left">{performer}</TableCell>
                  </>
                )}
              </TableRow>
            );
          })}
        </>
      );
    } else {
      return (
        <TableRow>
          <TableCell sx={styles.boldRow} align="left">
            {t('metaTable.providedBy')}
          </TableCell>
          <TableCell align="left">{PerformersArrayParameter[0]}</TableCell>
        </TableRow>
      );
    }
  };

  return (
    <Box sx={styles.tableContainer}>
      <Table aria-label="Table with performer and date information">
        <TableBody>
          {performersRows(PerformersArray)}
          <TableRow>
            <TableCell sx={styles.boldRow} align="left">
              {t('metaTable.modifiedOn')}
            </TableCell>
            <TableCell align="left">{modifiedDate}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export default MetaTable;
