import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Box, Button, useTheme } from '@mui/material';
import { ArrowBack as ArrowLeftIcon } from '@mui/icons-material';

import LogoIcon from '../../assets/images/icon.png';

import useStyles from './MobileHeader.component.styles';

export const MobileHeaderComponent = ({ hideBackButton = false }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Box component="header" sx={{ ...styles.root, position: 'relative' }}>
      {!hideBackButton && (
        <Button
          title={t('general.back')}
          aria-label={t('general.back')}
          style={{ ...styles.backButton, position: 'absolute' }}
          onClick={history.goBack}
        >
          <ArrowLeftIcon fontSize="small" style={styles.backIcon} />
        </Button>
      )}
      <img alt={t('companyName')} src={LogoIcon} style={styles.logo} />
    </Box>
  );
};

export default MobileHeaderComponent;
