import { Theme } from '@mui/material';
import Color from 'color';

export const styles = (theme: Theme) => ({
  emptyCard: {
    display: 'flex',
    backgroundColor: theme.palette.grayScale.gradient100,
    width: { tablet: '510px', mobile: '100%' },
    borderRadius: '4px',
    padding: '15px',
    alignItems: 'center',
  },
  emptyTestImg: {
    width: '175px',
  },
  cardTitle: {
    fontSize: '1.25rem',
    marginBottom: '3px',
    fontWeight: 700,
    color: theme.palette.grayScale.main,
  },
  recordCard: {
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'inline-block',
  },
  emptyLabImage: {
    marginRight: { tablet: '30px', mobile: '0px' },
    marginBottom: { tablet: '0px', mobile: '30px' },
    width: '175px',
    display: 'flex',
  },
  details: {
    display: 'flex',
  },
  subtitle: {
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
    marginBottom: '20px',
  },
});

export default styles;
