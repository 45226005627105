export const styles = () => ({
  terms: {
    maxWidth: '80%',
    maxHeight: '380px',
    margin: '40px 0',
    borderRadius: '4px',
    padding: '6px 16px',
  },
  subtitle: {
    maxWidth: '70%',
  },
  cta: {
    maxWidth: '200px',
    width: '100%',
    margin: '50px 0',
  },
  actionButton: {
    color: 'inherit',
    opacity: 'inherit',
  },
  form: {
    display: 'flex',
  },
  conditions: {
    minHeight: '200px',
  },
});

export default styles;
