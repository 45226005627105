import React, { useEffect, useState } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import {
  Box,
  Button,
  MenuItem,
  Paper,
  SelectChangeEvent,
  TextField,
  useTheme,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import {
  Download as DownloadIcon,
  MailOutline as ShareIcon,
} from '@mui/icons-material';
import { DateTime } from 'luxon';
import querystring, { ParsedUrlQueryInput } from 'querystring';
import { compact, find, get, identity, chain, omit, omitBy, map } from 'lodash';
import { AxiosResponse } from 'axios';
import { useHistory, Link } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import {
  GridCellParams,
  GridSortDirection,
  GridSortItem,
} from '@mui/x-data-grid';

import MobileHeaderComponent from 'components/MobileHeader/MobileHeader.component';
import SearchService from 'core/services/search.service';
import FilterComponent from 'components/Filter/Filter.component';
import { ISortOption } from 'core/interfaces/sort-option.interface';
import ChipSelectComponent from 'components/ChipSelect/ChipSelect.component';
import { useAuthState } from 'core/contexts/authentication.context';
import FormControlAdapterComponent from 'components/FormControlAdapter/FormControlAdapter.component';
import CovidCardComponent from 'components/CovidCard/CovidCard.component';
import SearchResultsDataGridComponent from 'components/SearchResultsDataGrid/SearchResultsDataGrid.component';
import { IImmunizationMetadata } from 'core/interfaces/immunization-metadata.interface';
import {
  IImmunization,
  IImmunizationResponse,
} from 'core/interfaces/immunization.interface';
import ImmunizationsImg from 'assets/images/immunizations.svg';
import generatePDF, {
  generatePersonalDetails,
} from 'core/services/export.service';
import { formatDate } from 'utils/utils';

import useStyles from './Immunizations.container.styles';
import AlertBoxComponent from 'components/AlertBox/AlertBox.component';

export default function ImmunizationsContainer() {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);
  const history = useHistory();
  const { user } = useAuthState();
  const today = DateTime.local();
  const [queryParams, changeQueryParams] = useState<any>(
    querystring.decode(window.location.search.replace('?', '')),
  );
  const rowsPerPage = 15;
  const [totalRowCount, setTotalRowCount] = useState<number>();
  const [totalPages, setTotalPages] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<{
    [key: string]: IImmunization[];
  }>({ 0: [] });
  const [selectedRowIds, setSelectedIds] = useState<{
    [key: string]: string[];
  }>({ 0: [] });
  const [data, setData] = useState<IImmunization[]>([]);
  const [page, setPage] = useState<number>(0);
  const [sort, setSort] = useState<GridSortItem | ''>({
    field: 'vaccineName',
    sort: 'asc',
  });
  const [, setQuery] = React.useState<string>('');
  const [facilities, setFacilities] = React.useState<string[]>([]);
  const [dateRange, setDateRange] = React.useState<string>('3year');
  const [isCustomDateRange, setCustomDateRange] =
    React.useState<boolean>(false);
  const [isSearching, setIsSearching] = React.useState<boolean>(false);
  const [dateRangeEnd, setDateRangeEnd] = React.useState<Date>(new Date());
  const [facilityOptions, setFacilityOptions] = React.useState<ISortOption[]>(
    [],
  );

  const dateOffsets: { [id: string]: { months?: number; years?: number } } = {
    '6months': { months: 6 },
    '1year': { years: 1 },
    '3year': { years: 3 },
    '5year': { years: 5 },
    '10year': { years: 10 },
  };

  const [dateRangeStart, setDateRangeStart] = React.useState<Date>(
    today.minus(dateOffsets['3year']).toJSDate(),
  );

  const dateOptions: ISortOption[] = [
    {
      id: '6months',
      displayName: 'general.dateOptions.6months',
    },
    {
      id: '1year',
      displayName: 'general.dateOptions.1year',
    },
    {
      id: '3year',
      displayName: 'general.dateOptions.3year',
    },
    {
      id: '5year',
      displayName: 'general.dateOptions.5year',
    },
    {
      id: '10year',
      displayName: 'general.dateOptions.10year',
    },
    {
      id: 'custom',
      displayName: 'general.dateOptions.custom',
    },
  ];

  const resetValues = {
    facilities: [],
    dateRange: '3year',
  };

  useEffect(() => {
    if (user) {
      getImmunizationMetadata();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const queryParams = querystring.decode(
        window.location.search.replace('?', ''),
      );
      changeQueryParams(queryParams);

      // Set current page
      setPage(queryParams.page ? parseFloat(queryParams.page as string) : 0);

      // Set sort
      const field = get(queryParams, 'sort', 'vaccineName') as string;
      const sort = get(
        queryParams,
        'sortDirection',
        'asc',
      ) as GridSortDirection;
      setSort({ field, sort });

      // Set Facilities
      if (queryParams.facilities) {
        const facilities = queryParams.facilities as string;
        const parsedFacilities: string[] = facilities.split('|');
        setFacilities(parsedFacilities);
      }

      // Set Date Range
      if (queryParams.dateRange) {
        const dateRange = queryParams.dateRange as string;
        handleDateRangeQueryParam(dateRange);
      }

      if (queryParams.dateStart) {
        const dateStart = queryParams.dateStart as string;
        setDateRangeStart(DateTime.fromISO(dateStart).toJSDate());
      }

      if (queryParams.dateEnd) {
        const dateEnd = queryParams.dateEnd as string;
        setDateRangeEnd(DateTime.fromISO(dateEnd).toJSDate());
      }

      if (queryParams.query) {
        const query = queryParams.query as string;
        setQuery(query);
      }

      searchRecords(queryParams);
    }
  }, [
    user,
    changeQueryParams,
    setPage,
    setSort,
    setFacilities,
    setDateRangeStart,
    setDateRangeEnd,
    setQuery,
    window.location.search,
  ]);

  const handleDateRangeQueryParam = (dateRange: string) => {
    if (!find(dateOptions, { id: dateRange })) {
      const newQuery = omit(queryParams, 'dateRange');
      history.push('/immunizations', {
        search: querystring.encode(newQuery),
      });
      return;
    }
    setCustomDateRange(dateRange === 'custom');
    setDateRange(dateRange);
  };

  const getRegionalCDCLink = (language = 'en'): string => {
    return language == 'es'
      ? 'https://espanol.cdc.gov/coronavirus/2019-ncov/vaccines/your-vaccination.html'
      : 'https://www.cdc.gov/coronavirus/2019-ncov/vaccines/your-vaccination.html';
  };

  const getImmunizationMetadata = () => {
    const userId = get(user, '_id', '');
    SearchService.getImmunizationsMetadata(userId)
      .then(getImmunizationsMetadataSuccess)
      .catch(getImmunizationsMetadataFail);
  };

  const getImmunizationsMetadataSuccess = (
    response: AxiosResponse<IImmunizationMetadata>,
  ) => {
    const metadata: IImmunizationMetadata = response.data;
    const facilities: ISortOption[] = chain(metadata.performers)
      .compact()
      .map(convertOptionsToSortOptions)
      .value();
    setFacilityOptions(facilities);
  };

  const convertOptionsToSortOptions = (option: string): ISortOption => ({
    id: option,
    displayName: option,
  });

  const getImmunizationsMetadataFail = () => {
    // TODO: Snackbar
  };

  const searchRecords = (queryParams: any) => {
    const userId = get(user, '_id', '');
    setIsSearching(true);
    SearchService.getImmunizations(userId, {
      ...queryParams,
      page: page + 1,
      dateStart:
        queryParams?.dateStart ||
        DateTime.fromJSDate(dateRangeStart).toISODate(),
      dateEnd:
        queryParams?.dateEnd || DateTime.fromJSDate(dateRangeEnd).toISODate(),
      limit: rowsPerPage,
    })
      .then(handleImmunizationsSuccess)
      .catch(handleImmunizationsFail);
  };

  const handleImmunizationsSuccess = (
    response: AxiosResponse<IImmunizationResponse>,
  ) => {
    const immunizations: IImmunization[] = response.data.records;
    setData(immunizations);
    setTotalRowCount(response.data.metadata.total_count);
    setTotalPages(response.data.metadata.total_pages);
    setIsSearching(false);
  };

  const handleImmunizationsFail = () => {
    setIsSearching(false);
    // TODO: Fail
  };

  // const handleChangePage = (page: number) => {
  //   const newQuery = { ...queryParams, page: page };
  //   navigateAndSearch(newQuery);
  // };

  const handleChangePage = (event: any, page: number) => {
    setPage(page);
    const newQuery = { ...queryParams, page: page };
    history.push({
      pathname: '/immunizations',
      search: querystring.encode(newQuery),
    });
  };

  const applyFilters = (values: any) => {
    const { dateStart, dateEnd } = calculateDateRange(values.dateRange);
    const newQuery = {
      page,
      limit: rowsPerPage,
      ...values,
      facilities: values.facilities.join('|'),
      dateStart,
      dateEnd,
    };

    navigateAndSearch(newQuery);
  };

  const calculateDateRange = (dateRange: string) => {
    if (dateRange === 'custom') {
      return {
        dateStart: DateTime.fromJSDate(dateRangeStart).toISODate(),
        dateEnd: DateTime.fromJSDate(dateRangeEnd).toISODate(),
      };
    }

    return {
      dateStart: DateTime.now().minus(dateOffsets[dateRange]).toISODate(),
      dateEnd: today.toISODate(),
    };
  };

  const immuColumn = (params: GridRenderCellParams) => {
    return (
      <Link
        style={{ ...styles.rowLink, textDecoration: 'none' }}
        to={`/labs/${params.row.id}`}
      >
        {params.value}
      </Link>
    );
  };

  const columns = [
    {
      field: 'vaccineName',
      headerName: t('immunizations.columns.title'),
      flex: 1,
      // width: 320,
      renderCell: immuColumn,
    },
    {
      field: 'soldOn',
      headerName: t('immunizations.columns.receivedDate'),
      // width: 180,
      flex: 1,
    },
    {
      field: 'performer',
      headerName: t('immunizations.columns.performer'),
      //  width: 250,
      flex: 1,
    },
    {
      field: 'manufacturer',
      headerName: t('immunizations.columns.manufacturer'),
      // width: 250,
      flex: 1,
    },
  ];

  const handleDataGridSelectionChange = (newSelection: string[]) => {
    setSelectedIds({ ...selectedRows, [page]: newSelection });

    const selections = chain(newSelection).map(findById).compact().value();
    const currentSelections = { ...selectedRows, [page]: selections };
    setSelectedRows(currentSelections);
  };

  const findById = (id) => find(data, { id });

  const onSortChange = (event: { field: string; sort: string }[]) => {
    const currentSort = get(event, '[0]');

    const newQuery = {
      ...queryParams,
      page: 0,
    };

    if (currentSort) {
      newQuery.sort = currentSort.field;
      newQuery.sortDirection = currentSort.sort;
    } else {
      delete newQuery.sort;
      delete newQuery.sortDirection;
      setSort('');
    }

    navigateAndSearch(newQuery);
  };

  const navigateAndSearch = (query = {}) => {
    const queryParams = omitBy(
      query,
      (v) => !identity(v),
    ) as ParsedUrlQueryInput;
    history.push({
      pathname: '/immunizations',
      search: querystring.encode(queryParams),
    });
  };

  const onCellClick = (params: GridCellParams) => {
    if (params.field !== 'vaccineName') {
      return;
    }

    history.push(`/immunizations/${params.id}`);
  };

  const exportSelected = () => {
    const dataColumns = map(columns, 'field');
    const allSelected = chain(selectedRows).values().flatten().value();
    const dataRows = map(allSelected, (row) =>
      mapRowsToReportExport(row, dataColumns),
    );
    const dob = get(user, 'dateOfBirth', '');

    generatePDF({
      tables: [
        {
          rows: dataRows,
          columns: compact(map(columns, 'headerName')),
          yPos: 60,
        },
      ],
      title: 'Immunization Records',
      personalDetails: generatePersonalDetails(
        `${user?.firstName} ${user?.lastName}`,
        formatDate(dob),
      ),
      filename: 'immunizations',
      disclaimer: t('panel.disclaimer'),
    });
  };

  const mapRowsToReportExport = (row, columns: string[]) =>
    chain(row).pick(columns).values().value();

  const renderResourceTile = () => (
    <a
      style={{ textDecoration: 'none' }}
      rel="noreferrer"
      aria-label={t('immunizations.resourceTitle')}
      href={getRegionalCDCLink(i18n.language)}
      target="_blank"
    >
      <Box component={Paper} elevation={3} sx={styles.resources}>
        <Box sx={styles.immunizationImg}>
          <img
            style={styles.img}
            src={ImmunizationsImg}
            alt={t('immunizations.resourceImgAlt')}
          />
        </Box>
        <Box
          sx={{
            ...styles.details,
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <span>{t('immunizations.resourceTitle')}</span>
        </Box>
      </Box>
    </a>
  );

  return (
    <Box sx={styles.root}>
      <Box sx={styles.asideActions}>
        <MobileHeaderComponent hideBackButton />
      </Box>
      <div style={styles.pageTitle}>
        <span>{t('immunizations.pageTitle')}</span>
      </div>
      <div style={styles.subtitle}>
        <Trans i18nKey="disclaimer">
          The Portal displays information shared with CyncHealth by certain
          providers. If your information appears incomplete, it may be because
          your provider does not participate with CyncHealth. Please see the
          full Terms and Conditions <Link to="/conditions">here</Link>.
        </Trans>
      </div>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '65px' }}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
          <CovidCardComponent title={t('immunizations.covidTitle')} />
          <div>
            <div style={styles.sectionTitle}>
              {t('immunizations.resources')}
            </div>
            {renderResourceTile()}
          </div>
          <Box
            sx={{
              height: '100%',
              margin: { tablet: '-20px 0px', mobile: '0px' },
            }}
          >
            <AlertBoxComponent content={t('alertContent.immunizationsAlert')} />
          </Box>
        </Box>
        <div>
          <div style={styles.sectionTitle}>
            {t('immunizations.previousRecords')}
          </div>
          <div>
            <div>
              <FilterComponent
                onSubmit={applyFilters}
                searchValue={queryParams.query}
                resetValues={resetValues}
              >
                <FormControlAdapterComponent
                  id="dateRange"
                  defaultValue={dateRange}
                  renderComponent={(field: any, register: any) => (
                    <Box
                      component={TextField}
                      {...field}
                      {...register('dateRange')}
                      select
                      variant="standard"
                      sx={styles.formControl}
                      SelectProps={{
                        value: field.value,
                        onChange: (event: SelectChangeEvent) => {
                          setCustomDateRange(event.target.value === 'custom');
                          field.onChange(event);
                        },
                      }}
                    >
                      {dateOptions.map((option: ISortOption) => (
                        <MenuItem key={option.id} value={option.id}>
                          {t(option.displayName)}
                        </MenuItem>
                      ))}
                    </Box>
                  )}
                />
                {isCustomDateRange && (
                  <React.Fragment>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        maxDate={new Date()}
                        value={dateRangeStart}
                        onChange={(newValue) => {
                          if (newValue !== null) {
                            setDateRangeStart(newValue);
                          }
                        }}
                        renderInput={(params) => (
                          <Box
                            component={TextField}
                            sx={styles.formControl}
                            variant="standard"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        minDate={dateRangeStart}
                        maxDate={new Date()}
                        value={dateRangeEnd}
                        onChange={(newValue) => {
                          if (newValue !== null) {
                            setDateRangeEnd(newValue);
                          }
                        }}
                        renderInput={(params) => (
                          <Box
                            component={TextField}
                            sx={styles.formControl}
                            variant="standard"
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </React.Fragment>
                )}
                <ChipSelectComponent
                  id="facilities"
                  placeholder={t('immunizations.facilitiesSelectPlaceholder')}
                  defaultValue={facilities}
                  options={facilityOptions}
                />
              </FilterComponent>
            </div>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { tablet: 'row', mobile: 'column' },
                margin: '20px 0',
              }}
            >
              <Box
                sx={{
                  ...styles.actions,
                  flexDirection: 'row',
                  gap: '15px',
                }}
              >
                {selectedRows[page] && selectedRows[page].length > 0 && (
                  <Button
                    title={t('actions.downloadSelected')}
                    aria-label={t('actions.downloadSelected')}
                    onClick={exportSelected}
                  >
                    <DownloadIcon />
                    <span>{t('actions.downloadSelected')}</span>
                  </Button>
                )}

                {false && (
                  <Button
                    title={t('actions.share')}
                    aria-label={t('actions.share')}
                  >
                    <ShareIcon />
                    <span>{t('actions.share')}</span>
                  </Button>
                )}
              </Box>
            </Box>
          </div>
          <Paper elevation={3}>
            <div style={{ ...styles.resultsContainer, overflowX: 'auto' }}>
              <SearchResultsDataGridComponent
                loading={isSearching}
                columns={columns}
                rows={data}
                rowId="id"
                onPageChange={handleChangePage}
                page={page}
                pageSize={rowsPerPage}
                onSelectionChange={handleDataGridSelectionChange}
                selectedRows={selectedRowIds[page]}
                onSortChange={onSortChange}
                onCellClick={onCellClick}
                sort={compact([sort])}
                rowCount={totalRowCount}
                totalPages={totalPages}
              />
            </div>
          </Paper>
        </div>
      </Box>
    </Box>
  );
}
