import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';

import NotFound from 'assets/images/404.png';

import useStyles from './NotFound.container.styles';

export default function NotFoundContainer() {
  const { t } = useTranslation();
  const history = useHistory();
  const styles = useStyles();

  const goBack = () => history.goBack();

  return (
    <Box sx={{ ...styles.root, textAlign: 'center' }}>
      <img
        style={styles.notFoundImage}
        src={NotFound}
        alt={t('notFound.notFoundImgAlt')}
      />
      <Box sx={styles.content}>
        <div style={styles.title}>{t('notFound.title')}</div>
        <div>{t('notFound.subtitle')}</div>
        <div>
          <Button
            variant="contained"
            title={t('actions.goBack')}
            aria-label={t('actions.goBack')}
            onClick={goBack}
          >
            <span>{t('actions.goBack')}</span>
          </Button>
        </div>
      </Box>
    </Box>
  );
}
