import { Theme } from '@mui/material';
import Color from 'color';

export const styles = (theme: Theme) => ({
  root: {
    minWidth: '100%',
    padding: '30px',
  },
  empty: {
    color: Color(theme.palette.grayScale.main).hsl().alpha(0.6).toString(),
  },
  emptyTitle: {
    fontSize: '2.125rem',
    marginBottom: '15px',
  },
  label: {
    fontWeight: 700,
  },
  tips: {
    padding: 0,
    margin: 0,
    paddingInlineStart: '15px',
  },
});

export default styles;
