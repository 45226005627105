import React from 'react';
import { useTheme, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { IPanelDetailResult } from 'core/interfaces/tests.interface';

import useStyles from './RangeChart.component.style';

interface TestDetailProps {
  labRecord: IPanelDetailResult | undefined;
}

const RangeChart = ({ labRecord }: TestDetailProps) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const { t } = useTranslation();

  const balloonLocation = (
    value: number | undefined,
    range: string | undefined,
  ) => {
    const rangeArray: string[] = range ? range.split('-') : [];
    const rangeStart: number = +rangeArray[0];
    const rangeEnd: number = +rangeArray[1];
    const outOfBounds = ((rangeEnd - rangeStart) / 0.6) * 0.2;
    const valueInt: number = value || 0;
    const domain = [rangeStart - outOfBounds, rangeEnd + outOfBounds];
    const truePercent =
      ((valueInt - domain[0]) / (domain[1] - domain[0])) * 100;
    const percent = clamp(truePercent, 0, 100);
    return `${percent}%`;
  };

  const clamp = (value: number, min: number, max: number) => {
    return Math.min(Math.max(value, min), max);
  };

  return (
    <Box sx={styles.container}>
      <Box sx={{ ...styles.balloonWrapper, position: 'relative' }}>
        <Box>
          <h3>{t('labDetail.rangeTitle')}</h3>
          <p>{t('labDetail.rangeDescription')}</p>
        </Box>
      </Box>
      <Box sx={{ ...styles.rangeBarWrap, position: 'relative' }}>
        <Box
          sx={{
            ...styles.balloon,
            position: 'absolute',
            zIndex: 100,
            textAlign: 'center',
            transform: 'translateX(-50%)',
            left: balloonLocation(labRecord?.value, labRecord?.range),
            '&::after': { ...styles.balloon['&::after'], position: 'absolute' },
          }}
        >
          <span style={{ fontWeight: '700' }}>
            {t('labDetail.balloonText')}
          </span>
          <span style={{ textAlign: 'center' }}>
            {labRecord?.value}
            {labRecord?.unit}
          </span>
          {labRecord?.results && <span>- {labRecord?.results}</span>}
        </Box>
        <div style={{ position: 'absolute', left: '20%', ...styles.divider }} />
        <div style={{ position: 'absolute', left: '80%', ...styles.divider }} />
        <div style={{ ...styles.dangerRange, borderRadius: '5px 0 0 5px' }} />
        <div style={styles.normalRange} />
        <div style={{ ...styles.dangerRange, borderRadius: '0 5px 5px 0' }} />
      </Box>
    </Box>
  );
};

export default RangeChart;
