import React from 'react';

export const LabIconComponent = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3266 17.0899L12.0455 6.96703L12.0246 1.86899H12.5684C12.7071 1.86899 12.8401 1.8139 12.9381 1.71585C13.0362 1.61779 13.0913 1.48479 13.0913 1.34612C13.0913 1.20744 13.0362 1.07445 12.9381 0.976389C12.8401 0.878331 12.7071 0.823242 12.5684 0.823242H5.24813C5.10946 0.823242 4.97646 0.878331 4.87841 0.976389C4.78035 1.07445 4.72526 1.20744 4.72526 1.34612C4.72526 1.48479 4.78035 1.61779 4.87841 1.71585C4.97646 1.8139 5.10946 1.86899 5.24813 1.86899H5.79192L5.77101 6.96703L0.495196 17.0847C0.354818 17.3515 0.28585 17.6502 0.294994 17.9516C0.304137 18.253 0.39108 18.5469 0.547376 18.8047C0.703673 19.0626 0.924008 19.2756 1.18698 19.4232C1.44994 19.5707 1.74661 19.6477 2.04814 19.6468H15.7736C16.0742 19.6466 16.3696 19.5691 16.6315 19.4217C16.8935 19.2743 17.113 19.0619 17.269 18.805C17.425 18.5481 17.5123 18.2554 17.5224 17.955C17.5324 17.6546 17.465 17.3567 17.3266 17.0899ZM14.137 17.5553H3.67951C3.59012 17.5557 3.50214 17.533 3.42404 17.4896C3.34593 17.4461 3.28034 17.3832 3.23358 17.3071C3.18683 17.2309 3.16047 17.1439 3.15707 17.0546C3.15367 16.9653 3.17332 16.8766 3.21415 16.7971L7.92003 7.38533C7.98286 7.26266 8.09171 7.16985 8.22277 7.12719C8.35383 7.08454 8.49645 7.0955 8.61945 7.15769C8.74245 7.21989 8.83583 7.32824 8.87917 7.45908C8.92251 7.58992 8.91229 7.73259 8.85075 7.85592L4.52658 16.5095H14.137C14.2757 16.5095 14.4087 16.5646 14.5068 16.6627C14.6048 16.7607 14.6599 16.8937 14.6599 17.0324C14.6599 17.1711 14.6048 17.3041 14.5068 17.4021C14.4087 17.5002 14.2757 17.5553 14.137 17.5553ZM7.86251 3.43762C7.86251 3.29894 7.9176 3.16595 8.01566 3.06789C8.11372 2.96983 8.24671 2.91474 8.38539 2.91474C8.52406 2.91474 8.65706 2.96983 8.75512 3.06789C8.85317 3.16595 8.90826 3.29894 8.90826 3.43762V5.52912C8.90826 5.6678 8.85317 5.80079 8.75512 5.89885C8.65706 5.99691 8.52406 6.052 8.38539 6.052C8.24671 6.052 8.11372 5.99691 8.01566 5.89885C7.9176 5.80079 7.86251 5.6678 7.86251 5.52912V3.43762Z"
      fill="currentColor"
    />
  </svg>
);

export default LabIconComponent;
