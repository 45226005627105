import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { Link, useParams, useHistory } from 'react-router-dom';
import { Download as DownloadIcon } from '@mui/icons-material';
import {
  useTheme,
  Box,
  Breadcrumbs,
  MenuItem,
  Select,
  TextField,
  Button,
  Paper,
  Skeleton,
  Stack,
} from '@mui/material';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { chain, compact, get, last, map } from 'lodash';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import SearchService from 'core/services/search.service';
import RangeChart from 'components/RangeChart/RangeChart.component';
import MetaTable from 'components/MetaTable/MetaTable.component';
import MobileHeaderComponent from 'components/MobileHeader/MobileHeader.component';
import { ISortOption } from 'core/interfaces/sort-option.interface';
import { IPanelDetailResult } from 'core/interfaces/tests.interface';
import ResultsAreaChart from 'components/ResultsAreaChart/ResultsAreaChart.component';
import ScrollCards from 'components/ScrollCards/ScrollCards.component';
import TestDetailBlock from 'components/TestDetailBlock/TestDetailBlock.component';
import generatePDF, {
  generatePersonalDetails,
} from 'core/services/export.service';
import { formatDate } from 'utils/utils';
import { useAuthState } from 'core/contexts/authentication.context';

import useStyles from './PanelDetail.container.style';

interface IContainerParams {
  panelId: string;
  testCode: string;
  testType: string | null | undefined;
}

const PanelDetailContainer = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);
  const { user } = useAuthState();

  const dateOffsets: { [id: string]: { months?: number; years?: number } } = {
    '1months': { months: 1 },
    '3months': { months: 3 },
    '6months': { months: 6 },
    '1year': { years: 1 },
    '3year': { years: 3 },
  };

  const dateOptions: ISortOption[] = [
    {
      id: '1months',
      displayName: 'general.dateOptions.1months',
    },
    {
      id: '3months',
      displayName: 'general.dateOptions.3months',
    },
    {
      id: '6months',
      displayName: 'general.dateOptions.6months',
    },
    {
      id: '1year',
      displayName: 'general.dateOptions.1year',
    },
    {
      id: '3year',
      displayName: 'general.dateOptions.3year',
    },
    {
      id: 'custom',
      displayName: 'general.dateOptions.custom',
    },
  ];

  const { panelId, testCode, testType } = useParams() as IContainerParams;
  const [recordHistory, setRecordHistory] = useState<IPanelDetailResult[]>([]);
  const [recordDetails, setRecordDetail] = useState<IPanelDetailResult>();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [dateRange, setDateRange] = React.useState<string>('1months');
  const [dateRangeStart, setDateRangeStart] = React.useState<Date>(new Date());
  const [dateRangeEnd, setDateRangeEnd] = React.useState<Date>(new Date());
  const [isCustomDateRange, setCustomDateRange] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const today = DateTime.local();

  const renderRangeChart = (singleRes: IPanelDetailResult | undefined) => {
    if (singleRes && singleRes.range !== '') {
      return <RangeChart labRecord={singleRes} />;
    }
  };

  useEffect(() => {
    if (!testCode) {
      return history.goBack();
    }

    if (user) {
      getLabHistory();
    }
  }, [user, dateRange, dateRangeStart, dateRangeEnd]);

  const getLabHistory = () => {
    const userId = get(user, '_id', '');
    const dateRangeFilter = calculateDateRange(dateRange);
    setIsLoading(true);
    SearchService.getLabHistory(userId, testCode, testType, dateRangeFilter)
      .then(getLabHistorySuccess)
      .catch(getLabHistoryFail);
  };

  const getLabHistorySuccess = (response) => {
    const recordHistory = response.data;
    if (recordHistory.length > 0) {
      //const orderHistory = orderBy(recordHistory, ['modifiedDate'], ['asc']);
      console.log(recordHistory);
      const orderHistory = recordHistory.sort(function compare(a, b) {
        const dateA: any = new Date(a.modifiedDate);
        const dateB: any = new Date(b.modifiedDate);
        return dateA - dateB;
      });

      console.log(recordHistory);
      setRecordHistory(orderHistory);

      selectRecord(last(orderHistory), recordHistory.length - 1);
      setIsLoading(false);
    } else {
      // TODO: history goBack, notification
      history.push(`/labs/${panelId}`);
    }
  };

  const getLabHistoryFail = (error) => {
    setIsLoading(false);
    console.dir(error);
    // TODO: Error handling
  };

  const selectRecord = (item, index) => {
    setRecordDetail(item);
    setCurrentIndex(index);
  };

  const calculateDateRange = (dateRange: string) => {
    if (dateRange === 'custom') {
      return {
        dateStart: DateTime.fromJSDate(dateRangeStart).toISODate(),
        dateEnd: DateTime.fromJSDate(dateRangeEnd).toISODate(),
      };
    }

    return {
      dateStart: DateTime.now().minus(dateOffsets[dateRange]).toISODate(),
      dateEnd: today.toISODate(),
    };
  };

  const renderTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const value = get(payload, '[0].value', '');
      const unit = get(payload, '[0].unit', '');
      const results = get(payload, '[0].payload.results', '');
      const color =
        results === ''
          ? theme.palette.secondary.main
          : theme.palette.warning.main;
      return (
        <Box component={Paper} elevation={3} sx={{ ...styles.toolTip, color }}>
          <div>{`${formatXAxis(label)}`}</div>
          <div>
            <span>{`${value}${unit}`}</span>
            <span style={{ fontWeight: '700' }}>
              {' '}
              - {results || t('labDetail.normal')}
            </span>
          </div>
        </Box>
      );
    }

    return null;
  };

  const renderDot = (props) => {
    const { cx, cy, payload, index } = props;
    const isSelected = props.index === currentIndex;
    const dotSize = isSelected ? 24 : 16;
    const dotStyle = isSelected ? styles.selectedDot : {};
    return (
      <svg style={dotStyle} key={props.index}>
        <rect
          onClick={() => selectRecord(payload, index)}
          x={cx - dotSize / 2}
          y={cy - dotSize / 2}
          width={dotSize}
          height={dotSize}
          rx={dotSize}
          fill={
            payload.results === ''
              ? theme.palette.secondary.main
              : theme.palette.warning.main
          }
          strokeWidth={2}
          stroke={theme.palette.grayScale.light}
        />
      </svg>
    );
  };

  const columnsRecord = [
    {
      field: 'testCode',
      headerName: t('panel.testHeader'),
    },
    {
      field: 'value',
      headerName: t('panel.valueHeader'),
    },
    {
      field: 'unit',
      headerName: t('panel.unitHeader'),
    },
    {
      field: 'range',
      headerName: t('panel.rangeHeader'),
    },
    {
      field: 'results',
      headerName: t('panel.resultsHeader'),
    },
    {
      field: 'performers',
      headerName: t('panel.providedHeader'),
    },
    {
      field: 'modifiedDate',
      headerName: t('panel.updatedHeader'),
    },
  ];

  const exportSingleRecord = () => {
    const dataColumns = compact(map(columnsRecord, 'field'));
    const dataColumnsPanel = compact(map(columnsRecord, 'field'));
    const dataRowsHistory = map(recordHistory, (row) =>
      mapRowsToReportExport(row, dataColumns),
    );
    const dataRowsPanel = map([recordDetails], (row) =>
      mapRowsToReportExport(row, dataColumnsPanel),
    );
    exportPDF(user, testCode, dataRowsHistory, dataRowsPanel);
  };

  const exportPDF = (user, title, dataRowsHistory, dataRowsPanel) => {
    const dob = get(user, 'dateOfBirth', '');
    const { dateStart, dateEnd } = calculateDateRange(dateRange);
    generatePDF({
      tables: [
        {
          title: `${testCode} Over Time (${formatDate(
            dateStart,
          )} - ${formatDate(dateEnd)})`,
          rows: dataRowsHistory,
          columns: compact(map(columnsRecord, 'headerName')),
          yPos: 60,
        },
        {
          title: `Details For Test On ${recordDetails?.modifiedDate}`,
          rows: dataRowsPanel,
          columns: compact(map(columnsRecord, 'headerName')),
          yPos: 80,
        },
      ],
      title,
      personalDetails: generatePersonalDetails(
        `${user?.firstName} ${user?.lastName}`,
        formatDate(dob),
      ),
      filename: 'labs',
      disclaimer: t('panel.disclaimer'),
    });
  };

  const mapRowsToReportExport = (row, columns: string[]) =>
    chain(row).pick(columns).values().value();

  const formatXAxis = (tickItem) => {
    return DateTime.fromISO(tickItem).toFormat('MM/dd/yyyy');
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.asideActions}>
        <MobileHeaderComponent hideBackButton />
      </Box>
      <Box>
        <Breadcrumbs aria-label="breadcrumb" separator="›">
          <Link
            style={{ ...styles.rowLink, textDecoration: 'none' }}
            to="/labs"
          >
            {t('panel.allTestBreadCrumb')}
          </Link>
          <Link
            style={{ ...styles.rowLink, textDecoration: 'none' }}
            to={`/labs/${panelId}`}
          >
            {t('panel.panel')}
          </Link>
          <span>{recordDetails?.testCode}</span>
        </Breadcrumbs>
      </Box>
      <div style={styles.pageTitle}>
        {isLoading && <Skeleton height={70} width="50%" />}
        {!isLoading && (
          <React.Fragment>
            <span>{recordDetails?.testCode}</span>
            <Button
              style={{ display: 'flex', flex: 'none', alignSelf: 'flex-end' }}
              title={t('actions.download')}
              aria-label={t('actions.download')}
              onClick={exportSingleRecord}
            >
              <DownloadIcon />
              <span>{t('actions.download')}</span>
            </Button>
          </React.Fragment>
        )}
      </div>
      {isLoading && (
        <Stack spacing={1}>
          <Skeleton height={70} width="15%" />
          <Skeleton variant="rectangular" height={250} width="100%" />
          <Skeleton height={190} width="100%" />
        </Stack>
      )}
      {!isLoading && (
        <React.Fragment>
          <Box
            sx={{
              marginTop: '30px',
              marginBottom: '20px',
              display: 'flex',
              gap: '15px',
              alignItems: { mobile: 'flex-start', tablet: 'center' },
              justifyContent: 'space-between',
              flexDirection: { mobile: 'column', tablet: 'row' },
            }}
          >
            <Select
              onChange={(event) => {
                setCustomDateRange(event.target.value === 'custom');
                setDateRange(event.target.value);
              }}
              value={dateRange}
            >
              {dateOptions.map((option: ISortOption) => (
                <MenuItem key={option.id} value={option.id}>
                  {t(option.displayName)}
                </MenuItem>
              ))}
            </Select>
            {isCustomDateRange && (
              <React.Fragment>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    maxDate={new Date()}
                    value={dateRangeStart}
                    onChange={(newValue) => {
                      if (newValue !== null) {
                        setDateRangeStart(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <Box
                        component={TextField}
                        sx={{ ...styles.formControl, margin: '0 10px' }}
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    minDate={dateRangeStart}
                    maxDate={new Date()}
                    value={dateRangeEnd}
                    onChange={(newValue) => {
                      if (newValue !== null) {
                        setDateRangeEnd(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <Box
                        component={TextField}
                        sx={{ ...styles.formControl, margin: '0 10px' }}
                        variant="standard"
                        {...params}
                      />
                    )}
                  />
                </LocalizationProvider>
              </React.Fragment>
            )}
            <Box
              sx={{
                justifyContent: 'flex-end',
                display: 'flex',
                gap: '15px',
                flexDirection: { mobile: 'column', tablet: 'row' },
              }}
            >
              <div style={styles.legendItem}>
                <div style={styles.badge} />
                {t('labDetail.normal')}
              </div>
              <div style={styles.legendItem}>
                <div
                  style={{
                    ...styles.badge,
                    backgroundColor: theme.palette.warning.main,
                  }}
                />
                {t('labDetail.outOfRange')}
              </div>
            </Box>
          </Box>

          {recordHistory.length > 1 && (
            <React.Fragment>
              <Paper elevation={3} style={styles.chartContainer}>
                <ResultsAreaChart
                  data={recordHistory}
                  CustomTooltip={renderTooltip}
                  CustomDot={renderDot}
                  strokeWidth={3}
                  xAxisTickFormatter={formatXAxis}
                  xAxisDataKey="modifiedDate"
                />
              </Paper>
              <ScrollCards
                labRecords={recordHistory}
                updateSingleResult={selectRecord}
                selectedIndex={currentIndex}
              />
            </React.Fragment>
          )}
          <TestDetailBlock labRecord={recordDetails}>
            {renderRangeChart(recordDetails)}
            <MetaTable
              performers={recordDetails?.performers || ''}
              modifiedDate={recordDetails?.modifiedDate || ''}
            />
          </TestDetailBlock>
        </React.Fragment>
      )}
    </Box>
  );
};

export default PanelDetailContainer;
