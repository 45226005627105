import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { includes } from 'lodash';

import {
  useAuthDispatch,
  useAuthState,
} from 'core/contexts/authentication.context';
import AuthService from 'core/services/auth.service';
import ProfileService from 'core/services/profile.service';
import { ICondition } from 'core/interfaces/condition.interface';
import NotificationComponent from '../Notification/Notification.component';
import ApiService from 'core/services/api.service';

export const InitializeComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //const { pathname } = useLocation();
  const dispatch = useAuthDispatch();
  const { account, loading } = useAuthState();
  const [isNotificationOpen, showNotification] = React.useState(false);

  const interceptors = useMemo(() => {
    return {
      response: (response: AxiosResponse) => response,
      error: (error: AxiosError) =>
        ApiService.handleError(error, dispatch, history, loading),
    };
  }, [dispatch, loading]);

  useEffect(() => {
    const axios = ApiService.getInstance();
    if (axios !== null) {
      axios.interceptors.response.use(
        interceptors.response,
        interceptors.error,
      );
    }
  }, [interceptors]);

  useEffect(() => {
    const token = AuthService.getToken();
    if (token) {
      if (!account) {
        AuthService.getUser(dispatch).catch(getUserFail);
      } else {
        checkTermsAndConditions(account._id);
      }
    }
  }, [account]);

  const getUserFail = (error: AxiosError) => {
    if (!includes([401, 403], error.response?.status)) {
      showNotification(true);
      history.push('/404');
    }
  };

  const checkTermsAndConditions = (userId: string) => {
    ProfileService.getUnacknowledgedConditions(userId).then(handleConditions);
  };

  const handleConditions = (response: AxiosResponse<ICondition[]>) => {
    const conditions: ICondition[] = response.data;
    if (conditions.length != 0) {
      history.push('/conditions/');
    }
  };

  const closeNotification = () => {
    showNotification(false);
  };

  return (
    <NotificationComponent
      isOpen={isNotificationOpen}
      handleClose={closeNotification}
      message={t('notifications.userFail')}
    />
  );
};

export default InitializeComponent;
