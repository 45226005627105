import React, { SyntheticEvent, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Switch,
  TablePagination,
  TextField,
  useTheme,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import {
  Download as DownloadIcon,
  MailOutline as ShareIcon,
} from '@mui/icons-material';
import { DateTime } from 'luxon';
import querystring from 'querystring';
import { chain, reject, find, get, map, omit } from 'lodash';
import { AxiosResponse } from 'axios';
import { Link, useHistory } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import MobileHeaderComponent from 'components/MobileHeader/MobileHeader.component';
import SearchService from 'core/services/search.service';
import {
  IPrescription,
  IPrescriptionResponse,
} from 'core/interfaces/prescription.interface';
import MedicationRowComponent from 'components/MedicationRow/MedicationRow.component';
import FilterComponent from 'components/Filter/Filter.component';
import { ISortOption } from 'core/interfaces/sort-option.interface';
import ChipSelectComponent from 'components/ChipSelect/ChipSelect.component';
import { useAuthState } from 'core/contexts/authentication.context';
import { IPrescriptionMetadata } from 'core/interfaces/prescription-metadata.interface';
import FormControlAdapterComponent from 'components/FormControlAdapter/FormControlAdapter.component';
import {
  generateMedicationPDF,
  generatePersonalDetails,
} from 'core/services/export.service';
import { formatDate } from 'utils/utils';

import useStyles from './Medications.container.styles';
import AlertBoxComponent from 'components/AlertBox/AlertBox.component';

export default function MedicationsContainer() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);
  const history = useHistory();
  const { user } = useAuthState();

  const today = DateTime.local();
  const [queryParams, changeQueryParams] = useState<any>(
    querystring.decode(window.location.search.replace('?', '')),
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isExpanded, changeExpandAll] = useState<boolean>(false);
  const rowsPerPage = 5;
  const [data, setData] = useState<IPrescription[]>([]);
  const [totalRowCount, setTotalRowCount] = useState<number>(25);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [page, setPage] = useState<number>(0);
  const [sortSelection, setSortSelection] =
    useState<string>('lastFillDateDesc');
  const [, setSort] = useState<string>('lastFillDate');
  const [, setSortDirection] = useState<string>('desc');
  const [, setQuery] = React.useState<string>('');
  const [prescribers, setPrescribers] = React.useState<string[]>([]);
  const [pharmacies, setPharmacies] = React.useState<string[]>([]);
  const [dateRange, setDateRange] = React.useState<string>('3year');
  const [isCustomDateRange, setCustomDateRange] =
    React.useState<boolean>(false);
  const [dateRangeEnd, setDateRangeEnd] = React.useState<Date>(new Date());
  const [pharmacyOptions, setPharmacyOptions] = React.useState<ISortOption[]>(
    [],
  );
  const [prescriberOptions, setPrescriberOptions] = React.useState<
    ISortOption[]
  >([]);
  const [selectedRows, setSelectedRows] = useState<{
    [key: string]: IPrescription[];
  }>({ 0: [] });

  const sortOptions: ISortOption[] = [
    {
      id: 'lastFillDateDesc',
      valueProperty: 'lastFillDate',
      direction: 'desc',
      displayName: 'medications.sortOptions.lastFillDateDesc',
    },
    {
      id: 'lastFillDateAsc',
      valueProperty: 'lastFillDate',
      direction: 'asc',
      displayName: 'medications.sortOptions.lastFillDateAsc',
    },
    {
      id: 'prescribedDateDesc',
      valueProperty: 'prescriptionDate',
      direction: 'desc',
      displayName: 'medications.sortOptions.prescribedDateDesc',
    },
    {
      id: 'prescribedDateAsc',
      valueProperty: 'prescriptionDate',
      direction: 'asc',
      displayName: 'medications.sortOptions.prescribedDateAsc',
    },
    {
      id: 'titleAsc',
      valueProperty: 'title',
      direction: 'asc',
      displayName: 'medications.sortOptions.titleAsc',
    },
    {
      id: 'titleDesc',
      valueProperty: 'title',
      direction: 'desc',
      displayName: 'medications.sortOptions.titleDesc',
    },
  ];

  const dateOffsets: { [id: string]: { months?: number; years?: number } } = {
    '3months': { months: 3 },
    '6months': { months: 6 },
    '1year': { years: 1 },
    '3year': { years: 3 },
    '5year': { years: 5 },
  };

  const [dateRangeStart, setDateRangeStart] = React.useState<Date>(
    today.minus(dateOffsets['3year']).toJSDate(),
  );

  const dateOptions: ISortOption[] = [
    {
      id: '3months',
      displayName: 'medications.dateOptions.3months',
    },
    {
      id: '6months',
      displayName: 'medications.dateOptions.6months',
    },
    {
      id: '1year',
      displayName: 'medications.dateOptions.1year',
    },
    {
      id: '3year',
      displayName: 'medications.dateOptions.3year',
    },
    {
      id: '5year',
      displayName: 'medications.dateOptions.5year',
    },
    {
      id: 'custom',
      displayName: 'medications.dateOptions.custom',
    },
  ];

  // const defaultFilter = {
  //   page: 0,
  //   sort: sortOptions[0],
  // };

  const resetValues = {
    pharmacies: [],
    prescribers: [],
    dateRange: '3year',
  };

  useEffect(() => {
    if (user) {
      getMedicationMetadata();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const queryParams = querystring.decode(
        window.location.search.replace('?', ''),
      );
      changeQueryParams(queryParams);

      // Set sort
      const option: any =
        find(sortOptions, {
          valueProperty: queryParams.sort,
          direction: queryParams.sortDirection,
        }) || sortOptions[0];

      setSortSelection(option.id);
      setSort(option.valueProperty);
      setSortDirection(option.direction);

      // Set prescribers
      if (queryParams.prescribers) {
        const prescribers = queryParams.prescribers as string;
        const parsedPrescribers: string[] = prescribers.split('|');
        setPrescribers(parsedPrescribers);
      }

      // Set pharmacies
      if (queryParams.pharmacies) {
        const pharmacies = queryParams.pharmacies as string;
        const parsedPharmacies: string[] = pharmacies.split('|');
        setPharmacies(parsedPharmacies);
      }

      // Set Date Range
      if (queryParams.dateRange) {
        const dateRange = queryParams.dateRange as string;
        handleDateRangeQueryParam(dateRange);
      }

      if (queryParams.dateStart) {
        const dateStart = queryParams.dateStart as string;
        setDateRangeStart(DateTime.fromISO(dateStart).toJSDate());
      }

      if (queryParams.dateEnd) {
        const dateEnd = queryParams.dateEnd as string;
        setDateRangeEnd(DateTime.fromISO(dateEnd).toJSDate());
      }

      if (queryParams.query) {
        const query = queryParams.query as string;
        setQuery(query);
      }

      searchRecords(queryParams);
    }
  }, [
    user,
    changeQueryParams,
    setPage,
    setSortSelection,
    setSort,
    setSortDirection,
    setPrescribers,
    setPharmacies,
    setDateRangeStart,
    setDateRangeEnd,
    setQuery,
    window.location.search,
  ]);

  const handleDateRangeQueryParam = (dateRange: string) => {
    if (!find(dateOptions, { id: dateRange })) {
      const newQuery = omit(queryParams, 'dateRange');
      history.push('/medications', {
        search: querystring.encode(newQuery),
      });
      return;
    }
    setCustomDateRange(dateRange === 'custom');
    setDateRange(dateRange);
  };

  const getMedicationMetadata = () => {
    const userId = get(user, '_id', '');
    SearchService.getMedicationsMetadata(userId)
      .then(getMedicationsMetadataSuccess)
      .catch(getMedicationsMetadataFail);
  };

  const getMedicationsMetadataSuccess = (response: AxiosResponse<any>) => {
    const metadata: IPrescriptionMetadata = response.data;

    const pharmacies: ISortOption[] = map(
      metadata.pharmacies,
      convertOptionsToSortOptions,
    );
    const prescribers: ISortOption[] = map(
      metadata.prescribers,
      convertOptionsToSortOptions,
    );
    setPharmacyOptions(pharmacies);
    setPrescriberOptions(prescribers);
  };

  const convertOptionsToSortOptions = (option: string): ISortOption => ({
    id: option,
    displayName: option,
  });

  const getMedicationsMetadataFail = () => {
    // TODO: Snackbar
  };

  const searchRecords = (queryParams: any) => {
    setIsLoading(true);
    const userId = get(user, '_id', '');
    SearchService.getMedications(userId, {
      ...queryParams,
      dateStart:
        queryParams?.dateStart ||
        DateTime.fromJSDate(dateRangeStart).toISODate(),
      dateEnd:
        queryParams?.dateEnd || DateTime.fromJSDate(dateRangeEnd).toISODate(),
      page: page + 1,
      limit: rowsPerPage,
      sort: queryParams?.sort || sortOptions[0]['valueProperty'],
      sortDirection: queryParams?.sortDirection || sortOptions[0]['direction'],
    })
      .then(handleMedicationsSuccess)
      .catch(handleMedicationsFail);
  };

  const handleMedicationsSuccess = (
    response: AxiosResponse<IPrescriptionResponse>,
  ) => {
    const prescriptions: IPrescription[] = response.data.records;
    setData(prescriptions);
    setTotalPages(response.data.metadata.total_pages);
    setTotalRowCount(response.data.metadata.total_count);
    setIsLoading(false);
  };

  const handleMedicationsFail = () => {
    setIsLoading(false);
    // TODO: Fail
  };

  const handleChangePage = (event: any, page: number) => {
    setPage(page);
    const newQuery = { ...queryParams, page: page };
    history.push({
      pathname: '/medications',
      search: querystring.encode(newQuery),
    });
  };

  const defaultLabelDisplayedRows = () =>
    `Page ${totalPages === 0 ? '0' : page + 1} of ${totalPages}`;

  const onExpandChange = (event: SyntheticEvent) => {
    const checked = get(event, 'target.checked', false);
    changeExpandAll(checked);
  };

  const onSortChange = (event: SelectChangeEvent) => {
    const sortSelection: ISortOption =
      find(sortOptions, { id: event.target.value }) || sortOptions[0];
    setPage(0);
    const newQuery = {
      ...queryParams,
      sort: sortSelection.valueProperty,
      sortDirection: sortSelection.direction,
      page: 0,
    };
    history.push({
      pathname: '/medications',
      search: querystring.encode(newQuery),
    });
  };

  const applyFilters = (values: any) => {
    const { dateStart, dateEnd } = calculateDateRange(values.dateRange);
    const newQuery = {
      page,
      limit: rowsPerPage,
      ...values,
      prescribers: values.prescribers.join('|'),
      pharmacies: values.pharmacies.join('|'),
      dateStart,
      dateEnd,
    };
    history.push({
      pathname: '/medications',
      search: querystring.encode(newQuery),
    });
  };

  const calculateDateRange = (dateRange: string) => {
    if (dateRange === 'custom') {
      return {
        dateStart: DateTime.fromJSDate(dateRangeStart).toISODate(),
        dateEnd: DateTime.fromJSDate(dateRangeEnd).toISODate(),
      };
    }

    return {
      dateStart: DateTime.now().minus(dateOffsets[dateRange]).toISODate(),
      dateEnd: today.toISODate(),
    };
  };

  const handleSelectionChange = (selection: IPrescription) => {
    let currentPageSelections = selectedRows[page] || [];

    const exists = find(currentPageSelections, { _id: selection._id });
    if (exists) {
      currentPageSelections = reject(currentPageSelections, {
        _id: selection._id,
      });
    } else {
      currentPageSelections.push(selection);
    }
    const currentSelections = {
      ...selectedRows,
      [page]: currentPageSelections,
    };
    setSelectedRows(currentSelections);
  };

  const resetCustomDateRange = (showDateRange: boolean) => {
    setCustomDateRange(showDateRange);
  };

  const isChecked = (id) => {
    const currentPageSelections = selectedRows[page];
    return find(currentPageSelections, { _id: id }) !== undefined;
  };

  const exportSelected = () => {
    const dob = get(user, 'dateOfBirth', '');
    generateMedicationPDF({
      data: chain(selectedRows).values().flatten().value(),
      title: t('medications.pageTitle'),
      personalDetails: generatePersonalDetails(
        `${user?.firstName} ${user?.lastName}`,
        formatDate(dob),
      ),
      filename: 'medications',
      disclaimer: t('medications.disclaimer'),
      alsoKnownAs: t('medications.knownAs'),
      directionsForUse: t('medications.directionsForUse'),
      medicationDetails: t('medications.medicationDetails'),
      prescriber: t('medications.prescriber'),
      prescribedOn: t('medications.prescribedOn'),
      fillDetails: t('medications.fillDetails'),
      presNumber: t('medications.presNumber'),
      quantity: t('medications.quantity'),
      lastFilled: t('medications.lastFilled'),
      daysSupply: t('medications.daysSupply'),
      pharmacyDetails: t('medications.pharmacyDetails'),
      additionalInfo: t('medications.additionalInfo'),
    });
  };

  const renderNoRows = () => (
    <Box
      component={Paper}
      elevation={3}
      sx={{ ...styles.noRows, display: 'flex', flexDirection: 'column' }}
    >
      <div style={styles.empty}>
        <div style={{ ...styles.emptyTitle, textAlign: 'center' }}>
          {t('general.noMatches')}
        </div>
        <div style={styles.label}>{t('general.filterTips')}:</div>
        <ul style={styles.tips}>
          <li>{t('general.checkSpelling')}</li>
          <li>{t('general.tuneFilter')}</li>
          <li>{t('general.similarWords')}</li>
        </ul>
      </div>
    </Box>
  );

  return (
    <Box sx={styles.root}>
      <Box sx={styles.asideActions}>
        <MobileHeaderComponent hideBackButton />
      </Box>
      <div style={styles.pageTitle}>
        <span>{t('medications.pageTitle')}</span>
      </div>
      {false && (
        <div style={styles.grouping}>
          <div style={styles.sectionTitle}>
            {t('medications.highlightedTitle')}
          </div>
          <div style={styles.subtitle}>
            {t('medications.highlightSubtitle')}
            {t('disclaimer')}
          </div>
          {data.map((prescription: IPrescription) => (
            <MedicationRowComponent
              key={prescription._id}
              prescription={prescription}
            />
          ))}
        </div>
      )}
      <div style={styles.grouping}>
        <div style={styles.sectionTitle}>
          {t('medications.highlightedTitle')}
        </div>
        <div style={styles.subtitle}>
          {t('medications.highlightSubtitle')}
          <Trans i18nKey="disclaimer">
            The Portal displays information shared with CyncHealth by certain
            providers. If your information appears incomplete, it may be because
            your provider does not participate with CyncHealth. Please see the
            full Terms and Conditions <Link to="/conditions">here</Link>.
          </Trans>
        </div>
        <Box
          sx={{
            marginTop: { tablet: '-20px', mobile: '0px' },
            marginBottom: { tablet: '20px', mobile: '20px' },
          }}
        >
          <AlertBoxComponent content={t('alertContent.medicationsAlert')} />
        </Box>
        <div>
          <div>
            <FilterComponent
              onSubmit={applyFilters}
              searchValue={queryParams.query}
              resetValues={resetValues}
              resetCutomDate={resetCustomDateRange}
            >
              <FormControlAdapterComponent
                id="dateRange"
                defaultValue={dateRange}
                renderComponent={(field: any, register: any) => (
                  <Box
                    component={TextField}
                    {...field}
                    {...register('dateRange')}
                    select
                    variant="standard"
                    sx={styles.formControl}
                    SelectProps={{
                      value: field.value,
                      onChange: (event: SelectChangeEvent) => {
                        setCustomDateRange(event.target.value === 'custom');
                        field.onChange(event);
                      },
                    }}
                  >
                    {dateOptions.map((option: ISortOption) => (
                      <MenuItem key={option.id} value={option.id}>
                        {t(option.displayName)}
                      </MenuItem>
                    ))}
                  </Box>
                )}
              />
              {isCustomDateRange && (
                <React.Fragment>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      maxDate={new Date()}
                      value={dateRangeStart}
                      onChange={(newValue) => {
                        if (newValue !== null) {
                          setDateRangeStart(newValue);
                        }
                      }}
                      renderInput={(params) => (
                        <Box
                          component={TextField}
                          sx={styles.formControl}
                          variant="standard"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      minDate={dateRangeStart}
                      maxDate={new Date()}
                      value={dateRangeEnd}
                      onChange={(newValue) => {
                        if (newValue !== null) {
                          setDateRangeEnd(newValue);
                        }
                      }}
                      renderInput={(params) => (
                        <Box
                          component={TextField}
                          sx={styles.formControl}
                          variant="standard"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </React.Fragment>
              )}
              <ChipSelectComponent
                id="prescribers"
                placeholder={t('medications.prescriberSelectPlaceholder')}
                defaultValue={prescribers}
                options={prescriberOptions}
              />
              <ChipSelectComponent
                id="pharmacies"
                placeholder={t('medications.pharmacySelectPlaceholder')}
                defaultValue={pharmacies}
                options={pharmacyOptions}
              />
            </FilterComponent>
          </div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { tablet: 'row', mobile: 'column' },
              margin: '20px 0',
            }}
          >
            <div>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch checked={isExpanded} onChange={onExpandChange} />
                  }
                  label={t(
                    isExpanded
                      ? 'medications.collapseDetails'
                      : 'medications.expandDetails',
                  )}
                />
              </FormGroup>
            </div>
            <Box
              sx={{
                ...styles.actions,
                flexDirection: { mobile: 'column', tablet: 'row' },
              }}
            >
              {selectedRows[page] && selectedRows[page].length > 0 && (
                <Button
                  className="actionButton"
                  title={t('actions.downloadSelected')}
                  aria-label={t('actions.downloadSelected')}
                  onClick={exportSelected}
                >
                  <DownloadIcon />
                  <span>{t('actions.downloadSelected')}</span>
                </Button>
              )}

              {false && (
                <Button
                  className="actionButton"
                  title={t('actions.share')}
                  aria-label={t('actions.share')}
                >
                  <ShareIcon />
                  <span>{t('actions.share')}</span>
                </Button>
              )}
            </Box>
          </Box>
          <Box
            component={Paper}
            elevation={3}
            sx={{
              display: 'flex',
              flexDirection: { tablet: 'row', mobile: 'column' },
              justifyContent: { tablet: 'flex-end', mobile: 'flex-start' },
              alignItems: { tablet: 'center', mobile: 'flex-start' },
              padding: { tablet: '0 25px', mobile: '0 25px 20px 25px' },
            }}
          >
            <TablePagination
              component="div"
              count={totalRowCount}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
              labelDisplayedRows={defaultLabelDisplayedRows}
            />
            <Box
              component={FormControlLabel}
              sx={styles.sortSelectContainer}
              labelPlacement="start"
              control={
                <Select
                  sx={styles.sortSelect}
                  variant="standard"
                  labelId="sort_select_label"
                  id="sort_select"
                  value={sortSelection}
                  onChange={onSortChange}
                  label={t('medications.selectSortLabel')}
                >
                  {sortOptions.map((option: ISortOption) => (
                    <MenuItem key={option.id} value={option.id}>
                      {t(`medications.sortOptions.${option.id}`)}
                    </MenuItem>
                  ))}
                </Select>
              }
              label={`${t('medications.selectSortLabel')}:`}
            />
          </Box>
          {isLoading && <LinearProgress />}
          {data.length === 0 && renderNoRows()}
          <div>
            {data.map((prescription) => (
              <MedicationRowComponent
                key={`data_${prescription._id}`}
                prescription={prescription}
                expanded={isExpanded}
                checked={isChecked(prescription._id)}
                onCheck={handleSelectionChange}
              />
            ))}
          </div>
        </div>
      </div>
    </Box>
  );
}
