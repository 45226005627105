import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Paper,
  Popover,
  useTheme,
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon,
  InfoOutlined as InfoIcon,
} from '@mui/icons-material';
import { noop } from 'lodash';

import { formatDate, formatPhoneNumber } from 'utils/utils';
import { IPrescription } from 'core/interfaces/prescription.interface';

import useStyles from './MedicationRow.component.styles';

interface IComponentProps {
  prescription: IPrescription;
  expanded?: boolean;
  onCheck?: any;
  checked?: boolean;
}

export const MedicationRowComponent = ({
  prescription,
  expanded = false,
  onCheck = noop,
  checked = false,
}: IComponentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);
  const [infoTooltip, setInfoTooltip] = React.useState(null);
  const [isExpanded, changeExpand] = useState<boolean | null>(null);

  const handleInfoClick = (event) => {
    event.stopPropagation();
    setInfoTooltip(event.currentTarget);
  };

  const handleInfoClose = (event) => {
    event.stopPropagation();
    setInfoTooltip(null);
  };

  const open = Boolean(infoTooltip);
  const popoverId = open ? 'info-tooltip' : undefined;

  useEffect(() => {
    changeExpand(expanded || null);
  }, [expanded]);

  const toggleExpand = () => {
    const currentExpand = !(isExpanded === null || !isExpanded);
    changeExpand(!currentExpand);
  };

  const renderSubrecord = (subRecord: IPrescription, index) => (
    <Box key={`sub_${subRecord._id}_${index}`} sx={styles.subRecord}>
      <Box sx={styles.refillDate}>
        {t('medications.filledOn')} {formatDate(subRecord.lastRefillDate)}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: {
            tablet: '15px 15px 0 15px',
            mobile: '15px 0',
          },
          width: { desktop: '100%' },
          flexDirection: { tablet: 'row', mobile: 'column' },
          flexWrap: 'wrap',
          gap: { mobile: '15px', tablet: '0' },
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 33%' }}>
          <div>{t('medications.medicationDetails')}</div>
          <div style={styles.secondaryInfo}>
            <div>
              <span>{t('medications.prescriber')}: </span>
              <span>{subRecord.prescriber}</span>
            </div>
          </div>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 33%' }}>
          <div>{t('medications.fillDetails')}</div>
          <div style={styles.secondaryInfo}>
            <div>
              <span>{t('medications.presNumber')}:</span>
              <span>{subRecord.prescriptionNumber}</span>
            </div>
            <div>
              <span>{t('medications.quantity')}: </span>
              <span>
                {subRecord.quantity}{' '}
                {subRecord.unit === 'na' ? '' : subRecord.unit}
              </span>
            </div>
            <div>
              <span>{t('medications.daysSupply')}: </span>
              <span>
                {subRecord.daysSupply} {t('medications.days')}
              </span>
            </div>
          </div>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 33%' }}>
          <div>{t('medications.pharmacy')}</div>
          <div style={styles.secondaryInfo}>
            <div style={{ textTransform: 'capitalize' }}>
              {prescription.pharmacyName}
            </div>
            <div>
              <span>{subRecord.pharmacyStreet}, </span>
              {subRecord.pharmacyStreet2 && (
                <span> {subRecord.pharmacyStreet2}, </span>
              )}
              <span>{subRecord.pharmacyCity}, </span>
              <span>
                {subRecord.pharmacyState} {subRecord.pharmacyZip}
              </span>
            </div>
            <div>{formatPhoneNumber(subRecord.pharmacyPhone)}</div>
          </div>
        </Box>
      </Box>
      <Box
        sx={{
          flexDirection: 'column',
          visibility: subRecord.updates ? 'visible' : 'hidden',
          flex: '1 1 33%',
          display: {
            tablet: 'flex',
            mobile: subRecord.updates ? 'flex' : 'none',
          },
        }}
      >
        <div>{t('medications.additionalInfo')}</div>
        <div style={styles.secondaryInfo}>
          <div>
            <span>{subRecord.updates}</span>
          </div>
        </div>
      </Box>
    </Box>
  );

  return (
    <Paper elevation={3} style={{ display: 'flex', alignItems: 'flex-start' }}>
      <div
        style={{
          ...styles.checkBox,
          borderTop: isExpanded ? 'none' : '1px solid rgba(0, 0, 0, 0.12)',
        }}
      >
        <Checkbox
          inputProps={{ 'aria-label': t('actions.select') }}
          checked={checked}
          onChange={() => onCheck(prescription)}
        />
      </div>

      <Accordion
        sx={styles.accordionGroup}
        expanded={isExpanded !== null ? isExpanded : expanded}
        onClick={toggleExpand}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${prescription._id}_content`}
          id={prescription._id}
          sx={{ ...styles.accordion, flexDirection: 'row-reverse' }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div>
              <span>{prescription.title}</span>
            </div>
            <div style={{ ...styles.secondaryInfo, marginBottom: '10px' }}>
              <span>{t('medications.knownAs')} </span>
              {prescription.commonNames?.map((name: string, index: number) => (
                <React.Fragment key={`cname_${index}`}>
                  <span>{name}</span>
                  {index !== prescription.commonNames?.length - 1 && (
                    <span>, </span>
                  )}
                </React.Fragment>
              ))}
            </div>
            {prescription.directions && (
              <React.Fragment>
                <div>{t('medications.directionsForUse')}</div>
                <div>
                  <span style={styles.secondaryInfo}>
                    {prescription.directions}
                  </span>
                  <Button
                    aria-describedby={popoverId}
                    onClick={handleInfoClick}
                    style={styles.actionButton}
                    title={t('actions.moreInfo')}
                    aria-label={t('actions.moreInfo')}
                  >
                    <InfoIcon />
                  </Button>
                </div>
                <Popover
                  id={popoverId}
                  open={open}
                  anchorEl={infoTooltip}
                  onClose={handleInfoClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Box sx={styles.popup}>{t('medications.referenceOnly')}</Box>
                </Popover>
              </React.Fragment>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: { tablet: '15px 15px 0 15px', mobile: '15px 0' },
                width: { desktop: '75%', tablet: '100%' },
                flexDirection: { tablet: 'row', mobile: 'column' },
                gap: { mobile: '15px', tablet: '0' },
              }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <div>{t('medications.medicationDetails')}</div>
                <div style={styles.secondaryInfo}>
                  <div>
                    <span>{t('medications.prescribedOn')}: </span>
                    <span>{formatDate(prescription.prescriptionDate)}</span>
                  </div>
                </div>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <div>{t('medications.fillDetails')}</div>
                <div style={styles.secondaryInfo}>
                  <div>
                    <span>{t('medications.lastFilled')}: </span>
                    <span>{formatDate(prescription.lastRefillDate)}</span>
                  </div>
                </div>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <div>{t('medications.pharmacy')}</div>
                <div style={styles.secondaryInfo}>
                  <div>{prescription.pharmacyName}</div>
                </div>
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={styles.accordionDetails}>
          {prescription &&
            prescription.records?.map(
              (subRecord: IPrescription, index: number) =>
                renderSubrecord(subRecord, index),
            )}
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default MedicationRowComponent;
