import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  useTheme,
} from '@mui/material';
import {
  KeyboardArrowDown as ChevronDownIcon,
  Settings as SettingsIcon,
  // People as PeopleIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
// import { get } from 'lodash';
// import ProfileService from 'core/services/profile.service';
import {
  // useAuthDispatch,
  useAuthState,
} from 'core/contexts/authentication.context';
// import { IContext } from 'core/interfaces/profile.interface';
import LanguageSelectComponent from 'components/LanguageSelect/LanguageSelect.component';
import useStyles from './AccountSettings.component.styles';

interface IAccountSettingSelection {
  id: string;
  path?: any;
  icon?: any;
  displayName: string;
  click?: any;
  children?: any;
  disabled?: boolean;
}

export const AccountSettingsComponent = () => {
  const { t } = useTranslation();
  const { account, user } = useAuthState();
  // const dispatch = useAuthDispatch();
  const history = useHistory();
  const theme = useTheme();
  const styles = useStyles(theme);

  useEffect(() => {
    return;
  }, [account]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = (path: string) => {
    history.push(path);
  };
  // suppressed account switiching feature
  // const renderContexts = () => {
  //   if (account && account.contexts.length < 2) {
  //     return '';
  //   }

  //   return account?.contexts.map((context: IContext, index: number) => {
  //     let style = { ...styles.menuItem, paddingLeft: '51px' };
  //     const userId = get(user, '_id', '');
  //     if (context.profileId === userId) {
  //       style = { ...style, ...styles.active };
  //     }

  //     return (
  //       <MenuItem
  //         key={index}
  //         style={style}
  //         onClick={() => changeContext(context)}
  //       >
  //         {`${context?.firstName} ${context?.lastName}`}
  //       </MenuItem>
  //     );
  //   });
  // };

  const navigationLinks: IAccountSettingSelection[] = [
    {
      id: 'profile',
      icon: () => <PersonIcon fontSize="small" />,
      click: () => navigate('/profile'),
      displayName: 'navigation.profile',
    },
    // suppressed account switiching feature
    // {
    //   id: 'accounts',
    //   icon: () => <PeopleIcon fontSize="small" />,
    //   displayName: 'navigation.accounts',
    //   disabled: true,
    //   children: renderContexts,
    // },
    {
      id: 'settings',
      icon: () => <SettingsIcon fontSize="small" />,
      click: () => navigate('/settings'),
      displayName: 'navigation.settings',
    },
  ];

  // const changeContext = (context) => {
  //   getProfile(context.profileId);
  // };

  // const getProfile = (userId: string) => {
  //   ProfileService.getProfile(dispatch, userId).catch(getProfileFail);
  // };

  // const getProfileFail = () => {
  //   // TODO: Handle fail
  // };

  return (
    <div>
      <Box sx={{ ...styles.actionContainer, flexDirection: 'row' }}>
        <Box sx={styles.language}>
          <LanguageSelectComponent stl={{ minWidth: '127px' }} />
        </Box>
        <IconButton
          color="primary"
          title={t('actions.account')}
          aria-label={t('actions.account')}
          component="div"
          onClick={handleClick}
        >
          <Avatar
            alt={`${user?.firstName} ${user?.lastName}`}
            style={{ backgroundColor: theme.palette.primary.main }}
          >
            {user?.firstName?.charAt(0)} {user?.lastName?.charAt(0)}
          </Avatar>
          <ChevronDownIcon style={styles.chevron} fontSize="small" />
        </IconButton>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={styles.menu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <div style={styles.menuHeader}>
          <div style={styles.name}>{user?.firstName}</div>
        </div>
        <Divider />
        {navigationLinks.map((link, index) => [
          <MenuItem
            key={`menu_item_${index}`}
            style={{
              ...styles.menuItem,
              pointerEvents: link.disabled ? 'none' : 'auto',
            }}
            onClick={link.click ? link.click : () => null}
          >
            {link.icon && (
              <ListItemIcon style={styles.menuItemIcon}>
                {link.icon()}
              </ListItemIcon>
            )}
            {t(link.displayName)}
          </MenuItem>,
          link.children && link.children(),
        ])}
      </Menu>
    </div>
  );
};

export default AccountSettingsComponent;
