import { Theme } from '@mui/material';
import Color from 'color';

export const styles = (theme: Theme) => ({
  root: {},
  contentContainer: {
    display: 'flex',
    padding: { mobile: '0px 15px 45px 15px', tablet: '0px 45px 45px 45px' },
  },
  logo: {
    display: { tablet: 'none', mobile: 'block' },
    marginTop: '25px',
    width: '122px',
    marginBottom: '30px',
  },
  greeting: {
    display: 'flex',
    alignItems: { tablet: 'flex-start', mobile: 'center' },
    padding: { mobile: '0px 15px 120px 15px', tablet: '45px 45px 0px 45px' },
    fontSize: { tablet: '2rem', mobile: '2rem' },
    marginBottom: { tablet: '40px', mobile: '-75px' },
    backgroundColor: {
      tablet: theme.palette.grayScale.light,
      mobile: theme.palette.primary.dark,
    },
    color: {
      tablet: 'inherit',
      mobile: theme.palette.grayScale.light,
    },
  },
  section: {
    // marginBottom: { tablet: '83px', mobile: '20px' },
    display: 'flex',
    // gap: '28px',
  },
  sectionTitle: {
    color: Color(theme.palette.grayScale.dark).hsl().alpha(0.6).toString(),
    fontSize: '1.3rem',
    marginBottom: '23px',
    display: { tablet: 'flex', mobile: 'none' },
  },
  recordCard: {
    textDecoration: 'none',
    cursor: 'pointer',
    width: { tablet: '520px', mobile: '100%' },
  },
  recordCardPaper: {
    width: { tablet: '510px', mobile: '100%' },
    padding: '24px 20px',
    display: 'flex',
    fontSize: '0.875rem',
  },
  labImage: {
    marginRight: '30px',
    '& img': {
      width: { tablet: '259px', mobile: '100px' },
    },
  },
  details: {
    display: 'flex',
    justifyContent: 'center',
  },
  count: {
    color: theme.palette.primary.main,
    fontSize: { tablet: '3rem', mobile: '2rem' },
    lineHeight: '42px',
  },
  cardTitle: {
    color: { tablet: 'inherit', mobile: theme.palette.primary.main },
    marginLeft: { tablet: '0px', mobile: '5px' },
    fontSize: { tablet: '1.25rem', mobile: '1rem' },
    fontWeight: 700,
    marginBottom: '3px',
  },
  subtitle: {
    color: Color(theme.palette.grayScale.main).hsl().alpha(0.6).toString(),
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  },
});

export default styles;
