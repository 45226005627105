import React from 'react';
import { Box, CssBaseline, Drawer, IconButton, useTheme } from '@mui/material';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import NavigationComponent from 'components/Navigation/Navigation.component';
import ProtectedRoutes from 'components/ProtectedRoutes/ProtectedRoutes.component';
import FooterComponent from 'components/Footer/Footer.component';
import AccountSettingsComponent from 'components/AccountSettings/AccountSettings.component';
import { useAuthDispatch } from 'core/contexts/authentication.context';
import AuthService from 'core/services/auth.service';
import MediaQuery from 'react-responsive';

import useStyles from './Layout.component.styles';
import 'styles/common.scss';
import MobileNavigationComponent from '../MobileNavigation/MobileNavigation.component';

export const LayoutComponent = () => {
  const drawerWidth = '320px';

  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme, {
    drawerWidth,
  });
  const dispatch = useAuthDispatch();
  const history = useHistory();

  const logout = () => {
    AuthService.logout(dispatch);
    history.push('/login');
    // TODO: Remember location to return after auth
  };

  return (
    <Box sx={styles.root}>
      <CssBaseline />
      <Drawer sx={styles.aside} variant="permanent" anchor="left">
        <NavigationComponent />
      </Drawer>
      <Box component="main" sx={{ ...styles.content, flexDirection: 'column' }}>
        <Box
          component="div"
          sx={{
            ...styles.accountSettings,
            flexDirection: 'row',
            position: 'absolute',
          }}
        >
          <AccountSettingsComponent />
          <IconButton
            color="primary"
            title={t('actions.logout')}
            aria-label={t('actions.logout')}
            component="span"
            onClick={logout}
          >
            <LogoutIcon />
          </IconButton>
        </Box>
        <ProtectedRoutes />
        <FooterComponent />
      </Box>
      <MediaQuery maxWidth={theme.breakpoints.values.tablet}>
        <MobileNavigationComponent />
      </MediaQuery>
    </Box>
  );
};

export default LayoutComponent;
