import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { Box, Paper, Skeleton, useTheme } from '@mui/material';
import { AxiosResponse } from 'axios';
import { get, chain } from 'lodash';

import Logo from 'assets/images/logo_white.png';
import LabIcon from 'assets/images/labs_with_person.svg';
import CovidCardComponent from 'components/CovidCard/CovidCard.component';
import EmptyLabsTileComponent from 'components/EmptyLabsTile/EmptyLabsTile.component';
import { useAuthState } from 'core/contexts/authentication.context';
import SearchService from 'core/services/search.service';
import { ILabRecord, ILabResponse } from 'core/interfaces/lab-record.interface';
import { truncate } from 'utils/utils';
import ProfileService from 'core/services/profile.service';
import useStyles from './Landing.container.styles';
import Tutorial from 'components/Tutorial/Tutorial.component';
import { useAuthDispatch } from 'core/contexts/authentication.context';
import AlertBoxComponent from 'components/AlertBox/AlertBox.component';
// import MiniAssessment from 'components/MiniAssessment/MiniAssessment.component';

export default function LandingContainer() {
  const { t } = useTranslation();
  const { user } = useAuthState();
  const theme = useTheme();
  const styles = useStyles(theme);
  const dispatch = useAuthDispatch();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [newLabs, setNewLabs] = React.useState<ILabRecord[]>([]);
  const [showTutorial, setShowTutorial] = React.useState<boolean>(false);
  // const [showSecondAssessment, setShowSecondAssessment] =
  //   React.useState<boolean>(false);

  useEffect(() => {
    if (user) {
      getNewLabTests();
      shouldShowTutorial();
    }
  }, [user]);

  const shouldShowTutorial = () => {
    if (!user?.tutorialSeen) {
      setShowTutorial(true);
    }
  };

  const getNewLabTests = () => {
    setIsLoading(true);
    const userId = get(user, '_id', '');
    SearchService.getNewLabs(userId)
      .then(getNewLabsSuccess)
      .catch(getNewLabsFail);
  };

  const getNewLabsSuccess = (response: AxiosResponse<ILabResponse>) => {
    setIsLoading(false);
    const newLabs: ILabRecord[] = response.data.records;
    setNewLabs(newLabs);
  };

  const getNewLabsFail = () => {
    setIsLoading(false);
    // TODO: Error
  };

  const handleCloseTutorial = () => {
    ProfileService.updateProfile(
      user,
      {
        tutorialSeen: true,
      },
      // eslint-disable-next-line
      dispatch,
    )
      .then(() => {
        setShowTutorial(false);
        // setShowSecondAssessment(true);
      })
      .catch((e) => {
        console.dir(e);
        setShowTutorial(false);
      });
  };

  // const handleCloseSecondAssessment = () => {
  //   setShowSecondAssessment(false);
  // };

  const renderTestTile = (records: ILabRecord[]) => {
    const titles = chain(records).take(3).map('testName').value().join(', ');
    return (
      <Box component={NavLink} to="/labs" sx={styles.recordCard}>
        <Box component={Paper} elevation={3} sx={styles.recordCardPaper}>
          <Box sx={styles.labImage}>
            <img alt={t('landing.altNewLabs')} src={LabIcon} />
          </Box>
          <div style={{ ...styles.details, flexDirection: 'column' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: { tablet: 'column', mobile: 'row' },
                alignItems: { tablet: 'flex-start', mobile: 'flex-end' },
              }}
            >
              <Box sx={styles.count}>{records.length}</Box>
              <Box sx={styles.cardTitle}>{t('landing.newTestResults')}</Box>
            </Box>
            <div style={styles.subtitle}>{truncate(titles, 25)}</div>
          </div>
        </Box>
      </Box>
    );
  };

  const renderLoading = () => (
    <Box sx={styles.loadingContainer}>
      <Box component={Paper} elevation={3} sx={styles.recordCardPaper}>
        <div>
          <Skeleton variant="rectangular" width={140} height={140} />
        </div>
        <div style={{ width: '100%' }}>
          <Box
            sx={{
              flexDirection: 'row',
              width: '90%',
              height: '100px',
              marginLeft: '15px',
            }}
          >
            <div style={{ width: '100%' }}>
              <Skeleton width="100%" height={25} />
            </div>
            <div style={{ width: '100%' }}>
              <Skeleton width="65%" />
            </div>
          </Box>
        </div>
      </Box>
    </Box>
  );

  return (
    <Box sx={styles.root}>
      <Box
        sx={{
          ...styles.greeting,
          flexDirection: 'column',
          textAlign: { mobile: 'center', tablet: 'left' },
        }}
      >
        <Box component="img" sx={styles.logo} src={Logo} alt={'companyName'} />
        <span style={{ textTransform: 'capitalize' }}>
          {t('welcomeBack', { name: user?.firstName?.toLowerCase() || '' })}
        </span>
      </Box>
      <Box
        sx={{
          ...styles.contentContainer,
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { mobile: 'column', tablet: 'row' },
            gap: '53px',
            marginBottom: '20px',
          }}
        >
          <CovidCardComponent
            title={t('landing.covidCard')}
            hideTitleOnMobile
          />

          <AlertBoxComponent content={t('alertContent.homepageAlert')} />
        </Box>
        <Box>
          <Box sx={styles.sectionTitle}>{t('landing.newTestResults')}</Box>
          {isLoading && renderLoading()}
          {!isLoading && newLabs.length === 0 && <EmptyLabsTileComponent />}
          {!isLoading && newLabs.length !== 0 && renderTestTile(newLabs)}
        </Box>
      </Box>
      <Tutorial openDialog={showTutorial} setOpenDialog={handleCloseTutorial} />
      {/* <MiniAssessment
        openDialog={showSecondAssessment}
        setOpenDialog={handleCloseSecondAssessment}
      /> */}
    </Box>
  );
}
