import { Theme } from '@mui/material';
import Color from 'color';

const styles = (theme: Theme) => ({
  root: {
    // height: { tablet: '290px', mobile: '420px' },
    marginBottom: '25px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  tileContainer: {
    display: 'block',
    width: '100%',
    padding: '15px 10px',
  },
  emptyCard: {
    display: 'flex',
    backgroundColor: theme.palette.grayScale.gradient100,
    width: { tablet: '510px', mobile: '100%' },
    borderRadius: '4px',
    padding: '15px',
    alignItems: { tablet: 'flex-start', mobile: 'center' },
  },
  emptyTestImg: {
    width: '175px',
  },
  cardTitle: {
    fontSize: '1.25rem',
    marginBottom: '3px',
    fontWeight: 700,
    color: theme.palette.grayScale.main,
  },
  recordCard: {
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'inline-block',
    width: { tablet: 'auto', mobile: '100%' },
  },
  recordPaper: {
    width: { tablet: '510px', mobile: '100%' },
    padding: '24px 28px',
    fontSize: '0.875rem',
    display: 'flex',
  },
  labImage: {
    marginRight: '30px',
    width: '38px',
    display: 'flex',
    alignItems: { tablet: 'flex-start', mobile: 'center' },
    marginBottom: { tablet: '0px', mobile: '30px' },
  },
  emptyLabImage: {
    marginRight: { tablet: '30px', mobile: '0px' },
    marginBottom: { tablet: '0px', mobile: '30px' },
    width: '175px',
    display: 'flex',
  },
  details: {
    display: 'flex',
  },
  date: {
    fontWeight: 'bold',
  },
  sectionTitle: {
    fontSize: '1.5rem',
    marginBottom: '20px',
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
  },
  subtitle: {
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
    marginBottom: '20px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-start',
    flex: 'auto',
    color: theme.palette.primary.main,
    alignItems: 'flex-end',
    gap: '20px',
  },
});

export default styles;
