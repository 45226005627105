import React from 'react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Paper, useTheme } from '@mui/material';
import { NavLink } from 'react-router-dom';

import { IPanelDetailResult } from 'core/interfaces/tests.interface';
import LabYellow from 'assets/images/LabYellow.svg';
import utils from 'utils/utils';

import useStyles from './ScrollCards.component.style';

interface LabCardsProps {
  labRecords: IPanelDetailResult[] | undefined;
  updateSingleResult: any;
  selectedIndex?: number;
}

const ScrollCards = ({
  labRecords,
  updateSingleResult,
  selectedIndex = 0,
}: LabCardsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);

  const cardColor = (result: string) => {
    return result === ''
      ? theme.palette.secondary.main
      : theme.palette.warning.main;
  };

  const cardBackgroundColor = (indexNo: number) => {
    if (indexNo === selectedIndex) {
      return theme.palette.grey[50];
    }
  };

  if (!labRecords) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div style={{ position: 'relative', height: '175px' }}>
      <div
        style={{
          ...styles.section,
          whiteSpace: 'nowrap',
          padding: '15px 10px',
          position: 'absolute',
          overflowX: 'auto',
          left: '0px',
          right: '0px',
          gap: '20px',
        }}
      >
        {labRecords.map((record, index) => (
          <NavLink
            to="#"
            style={{
              ...styles.recordCardContainer,
              flexDirection: 'row',
            }}
            key={index}
            onClick={() => {
              updateSingleResult(record, index);
            }}
          >
            <Paper
              elevation={index === selectedIndex ? 3 : 1}
              style={{
                ...styles.recordCard,
                backgroundColor: cardBackgroundColor(index),
              }}
            >
              <div
                style={{
                  ...styles.labImage,
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <img
                  alt={t('landing.altNewLabs')}
                  src={LabYellow}
                  style={{ width: '38px' }}
                />
                <span
                  style={{
                    textAlign: 'center',
                    marginTop: '10px',
                    fontSize: '0.8rem',
                  }}
                >
                  {t('labDetail.lab')}
                </span>
              </div>
              <div
                style={{
                  ...styles.details,
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <div style={{ ...styles.cardTitle, fontWeight: 'bold' }}>
                  {utils.formatDate(record.modifiedDate)}
                </div>
                <div style={styles.subtitle}>
                  <div style={{ height: '21px' }}>{record.performers}</div>
                  <div style={{ marginTop: '10px' }}>
                    <span
                      style={{
                        ...styles.resultValue,
                        justifyContent: 'center',
                        color: cardColor(record.results),
                        borderColor: cardColor(record.results),
                      }}
                    >
                      {`${record.value} ${record.unit} - ${
                        record.results === ''
                          ? t('labDetail.normal')
                          : record.results
                      }`}
                    </span>
                  </div>
                </div>
              </div>
            </Paper>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default ScrollCards;
