import { Theme } from '@mui/material';
import Color from 'color';

export const styles = (theme: Theme) => ({
  root: {
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    display: { tablet: 'none', mobile: 'block' },
  },
  link: {
    color: theme.palette.grayScale.light,
    backgroundColor: theme.palette.primary.main,
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
    },
    '.MuiBottomNavigationAction-label': {
      color: theme.palette.grayScale.light,
      marginTop: '5px',
      whiteSpace: 'nowrap',
    },
  },
  links: {
    padding: 0,
    margin: 0,
    paddingInlineStart: 0,
  },
  menuLink: {
    color: theme.palette.grayScale.light,
    textDecoration: 'none',
    padding: '18px 29px 18px 34px',
    alignItems: 'center',
    display: 'flex',
    cursor: 'pointer',
  },
  linkText: {
    marginLeft: '34px',
  },
  active: {
    backgroundColor: Color(theme.palette.primary.highlight)
      .alpha(0.08)
      .hsl()
      .toString(),
  },
  activeContext: {
    backgroundColor: theme.palette.primary.main,
  },
  menuLinkIcon: {
    width: '24px',
    display: 'flex',
    justifyContent: 'center',
  },
  covidLink: {
    textDecoration: 'none',
  },
  panelContainer: {
    display: 'flex',
    padding: '20px 29px 20px 5px',
    alignItems: 'center',
  },
  personalInfo: {
    color: theme.palette.grayScale.light,
  },
  endIconDecorator: {
    display: 'flex',
    flex: 'auto',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: theme.palette.grayScale.light,
  },
  avatar: {
    flex: 'none',
    display: 'flex',
    justifyContent: 'center',
    padding: '0 15px',
  },
  avatarIcon: {
    width: '55px',
    height: '55px',
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.light}`,
  },
  avatarSmall: {
    width: '35px',
    height: '35px',
    fontSize: '1rem',
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.light}`,
  },
  name: {
    fontSize: '2.125rem',
    paddingRight: '15px',
    marginRight: '15px',
    lineHeight: '26px',
  },
  contextList: {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  communityIcon: {
    width: '24px',
    display: 'flex',
  },
});

export default styles;
