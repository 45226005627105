import { Theme } from '@mui/material';
import Color from 'color';

export const styles = (theme: Theme) => ({
  menu: {
    elevation: 0,
    sx: {
      // eslint-disable-next-line
      overflowY: 'scroll' as 'scroll',
      // eslint-disable-next-line
      overflowX: 'hidden' as 'hidden',
      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
      mt: 1.5,
      marginTop: 0,
      '& .MuiAvatar-root': {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      '& .MuiList-root': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  menuHeader: {
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().string(),
    backgroundColor: theme.palette.grayScale.gradient50,
    padding: '8px 16px 6px 16px',
  },
  name: {
    fontSize: '1.5rem',
    marginBottom: '5px',
  },
  menuItem: {
    color: Color(theme.palette.grayScale.main).alpha(0.87).hsl().string(),
  },
  menuItemIcon: {
    color: Color(theme.palette.grayScale.main).alpha(0.57).hsl().string(),
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  chevron: {
    color: theme.palette.primary.main,
  },
  active: {
    backgroundColor: theme.palette.grayScale.gradient200,
  },
  language: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  languageSelect: {
    minWidth: '220px',
  },
});

export default styles;
