import { Theme } from '@mui/material';
import Color from 'color';

export const styles = (theme: Theme) => ({
  root: {
    padding: '45px',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      padding: '15px',
    },
  },
  content: {
    display: 'flex',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'column',
    },
  },
  pageTitle: {
    fontSize: '3rem',
    marginBottom: '40px',
  },
  sectionTitle: {
    fontWeight: 500,
    fontSize: '1.25rem',
    marginBottom: '5px',
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
  },
  instruction: {
    marginBottom: '20px',
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
  },
  actionContainer: {
    marginTop: '80px',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      marginTop: '30px',
      marginBottom: '80px',
    },
  },
  cta: {
    minWidth: '180px',
  },
  full: {
    width: '100%',
  },
  half: {
    width: { mobile: '100%', tablet: 'calc(50% - 7.5px)' },
  },
  phone: {
    fontSize: '1.25rem',
    color: Color(theme.palette.grayScale.main).alpha(0.87).hsl().toString(),
    textDecoration: 'none',
    fontWeight: 500,
  },
  divider: {
    margin: '25px 0',
    width: { mobile: '100%', tablet: '50%' },
  },
  email: {
    textDecoration: 'none',
  },
  formContainer: {
    display: 'flex',
    flex: 'auto',
  },
  form: {
    display: 'inline-flex',
    gap: '15px',
    width: '75%',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      width: '100%',
    },
  },
  asideActions: {
    display: 'none',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'row',
      display: 'flex',
      flex: 'none',
      marginBottom: '23px',
    },
  },
  backButton: {
    color: theme.palette.primary.main,
  },
  backIcon: {
    marginRight: '14px',
  },
  logo: {
    width: '25px',
    justifyContent: 'center',
    display: { mobile: 'block', tablet: 'none' },
  },
});

export default styles;
