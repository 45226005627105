import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { each } from 'lodash';
import { format } from 'date-fns';
import { IPrescription } from 'core/interfaces/prescription.interface';
import { formatDate } from 'utils/utils';

interface jsPDFCustom extends jsPDF {
  autoTable: (options: UserOptions) => void;
}

interface ITableConfig {
  rows: any[][];
  columns: string[];
  yPos: number;
  title?: string;
}

interface IExportPDFProps {
  tables: ITableConfig[];
  title: string;
  personalDetails: string;
  filename: string;
  disclaimer: string;
}

interface IExportLabReportPDFProps {
  data: string;
  tables: ITableConfig[];
  title: string;
  personalDetails: string;
  filename: string;
  disclaimer: string;
}

interface IExportMedicationPDFProps {
  data: IPrescription[];
  title: string;
  personalDetails: string;
  filename: string;
  disclaimer: string;
  alsoKnownAs: string;
  directionsForUse: string;
  medicationDetails: string;
  prescriber: string;
  prescribedOn: string;
  fillDetails: string;
  presNumber: string;
  quantity: string;
  lastFilled: string;
  daysSupply: string;
  pharmacyDetails: string;
  additionalInfo: string;
}

const marginLeft = 14;
const marginRight = 14; //right margin in mm
const pdfInMM = 210; // width of A4 in mm

const generatePDF = (config: IExportPDFProps, theDoc?) => {
  const doc = theDoc ? theDoc : (new jsPDF() as jsPDFCustom);
  const startPos = 70;
  const heightOfRow = 10;
  const spaceBetweenTables = 20;
  let position = startPos;

  config.tables.map((table: ITableConfig) => {
    if (table.title) {
      addText(doc, 18, table.title, position, 'bold');
      position = position + 10;
    }

    doc.autoTable({
      columns: table.columns,
      body: table.rows,
      startY: position,
      theme: 'plain',
      bodyStyles: {
        lineWidth: 0.1,
      },
      pageBreak: 'auto',
    });

    position = position + table.rows.length * heightOfRow + spaceBetweenTables;
  });

  const date = format(new Date(), 'MM/dd/yyyy');
  const wrappedDisclaimer = doc.splitTextToSize(config.disclaimer, 390);
  doc.setFontSize(8).text(wrappedDisclaimer, marginLeft, 10);
  doc.setFontSize(10).line(marginLeft, 17, pdfInMM - marginLeft, 17);
  doc
    .setFontSize(14)
    .setFont('times', 'bold')
    .text(`CyncHealth Patient Portal - ${date}`, marginLeft, 24);
  doc.setFontSize(12).text(config.personalDetails, marginLeft, 40);
  addText(doc, 20, config.title, 55, 'bold', '#004C97');
  doc.save(`${date}_${config.filename}.pdf`);
};

export const generateLabsReportPDF = (
  config: IExportLabReportPDFProps,
  theDoc?,
) => {
  const doc = theDoc ? theDoc : (new jsPDF() as jsPDFCustom);
  console.log('table');
  console.log(config.personalDetails);
  const startPos = 55;
  const heightOfRow = 10;
  const spaceBetweenTables = 20;
  let position = startPos;

  config.tables.map((table: ITableConfig) => {
    if (table.title) {
      addText(doc, 18, table.title, position, 'bold');
      position = position + 10;
    }

    doc.autoTable({
      columns: table.columns,
      body: table.rows,
      startY: position,
      theme: 'plain',
      bodyStyles: {
        lineWidth: 0.1,
      },
      pageBreak: 'auto',
    });

    position = position + table.rows.length * heightOfRow + spaceBetweenTables;
  });

  const date = format(new Date(), 'MM/dd/yyyy');
  const wrappedDisclaimer = doc.splitTextToSize(config.disclaimer, 390);
  doc.setFontSize(8).text(wrappedDisclaimer, marginLeft, 10);
  doc.setFontSize(10).line(marginLeft, 17, pdfInMM - marginLeft, 17);
  doc
    .setFontSize(12)
    .setFont('times', 'bold')
    .text(`CyncHealth Patient Portal`, marginLeft, 22);
  doc.setFontSize(12).text(`${date}`, 172, 22);
  doc
    .setFontSize(12)
    .setFont('times', 'normal')
    .text(config.personalDetails, marginLeft, 35);
  doc.rect(marginLeft, 80, 182, 210, 'S');
  doc.setFontSize(9).text(config.data, marginLeft + 2, 85);
  addText(doc, 20, config.title, 50, 'bold', '#004C97');
  doc.save(`${date}_${config.filename}.pdf`);
};

export const generateMedicationPDF = (
  config: IExportMedicationPDFProps,
  theDoc?,
) => {
  const doc = theDoc ? theDoc : (new jsPDF() as jsPDFCustom);

  const date = format(new Date(), 'MM/dd/yyyy');
  const wrappedDisclaimer = doc.splitTextToSize(config.disclaimer, 390);
  doc.setFontSize(8).text(wrappedDisclaimer, marginLeft, 10);
  doc.setFontSize(10).line(marginLeft, 17, pdfInMM - marginLeft, 17);
  doc
    .setFontSize(12)
    .setFont('times', 'bold')
    .text(`CyncHealth Patient Portal`, marginLeft, 22);
  doc.setFontSize(12).text(`${date}`, 172, 22);
  doc
    .setFontSize(12)
    .setFont('times', 'normal')
    .text(config.personalDetails, marginLeft, 35);
  doc.setFontSize(20).text(config.title, marginLeft, 50);

  let startPos = 65;
  each(config.data, (row) => {
    doc
      .setFontSize(10)
      .line(marginLeft, startPos - 10, pdfInMM - marginLeft, startPos - 10);
    startPos = renderMedicationRow(config, row, startPos, doc);
    startPos += 25;
  });

  doc.save(`${date}_${config.filename}.pdf`);
};

const renderMedicationRow = (
  config: IExportMedicationPDFProps,
  row: IPrescription,
  startPos: number,
  doc: any,
) => {
  const title = wrapText(row.title, doc);
  const commonNames = wrapText(
    `${config.alsoKnownAs} ${row.commonNames.join(', ')}`,
    doc,
  );
  const directionsText = row.directions ? row.directions : '';
  const directions = wrapText(directionsText, doc);
  let pdf = [
    (printPos) => addText(doc, 18, title, printPos, 'bold', '#004C97'),
    (printPos) => addText(doc, 14, commonNames, printPos + 10, '#666666'),
    (printPos) =>
      addText(
        doc,
        16,
        `${config.directionsForUse}: ${directions}`,
        printPos + 15,
      ),
    (printPos) =>
      addText(
        doc,
        14,
        config.medicationDetails,
        printPos + directions.length + 10,
        'bold',
      ),
    (printPos) =>
      addText(
        doc,
        11,
        `${config.prescriber}: ${row.prescriber}`,
        printPos + 6,
        '#666666',
      ),
    (printPos) =>
      addText(
        doc,
        11,
        `${config.prescribedOn}: ${formatDate(row.prescriptionDate)}`,
        printPos + 6,
        '#666666',
      ),
    (printPos) => addText(doc, 14, config.fillDetails, printPos + 15, 'bold'),
    (printPos) =>
      addText(
        doc,
        11,
        `${config.presNumber}: ${row._id}`,
        printPos + 6,
        '#666666',
      ),
    (printPos) =>
      addText(
        doc,
        11,
        `${config.quantity}: ${row.quantity}`,
        printPos + 6,
        '#666666',
      ),
    (printPos) =>
      addText(
        doc,
        11,
        `${config.lastFilled}: ${formatDate(row.lastRefillDate)}`,
        printPos + 6,
        '#666666',
      ),
    (printPos) =>
      addText(
        doc,
        11,
        `${config.daysSupply}: ${row.daysSupply}`,
        printPos + 6,
        '#666666',
      ),
    (printPos) =>
      addText(doc, 14, config.pharmacyDetails, printPos + 15, 'bold'),
    (printPos) =>
      addText(doc, 11, `${row.pharmacyName}`, printPos + 6, '#666666'),
    (printPos) =>
      addText(
        doc,
        11,
        wrapText(
          row.pharmacyStreet
            ? `${
                (row.pharmacyStreet,
                row.pharmacyStreet2,
                row.pharmacyCity,
                row.pharmacyState,
                row.pharmacyZip || '')
              }`
            : '',
          doc,
        ),
        printPos + 6,
        '#666666',
      ),
    (printPos) =>
      addText(doc, 11, `${row.pharmacyPhone}`, printPos + 6, '#666666'),
    (printPos) =>
      addText(
        doc,
        14,
        wrapText(row.updates ? config.additionalInfo : '', doc),
        printPos + 10,
        'bold',
      ),
    (printPos) =>
      addText(
        doc,
        11,
        wrapText(
          row.updates ? `${row.updates || ''}` : `${row.updates || ''}`,
          doc,
        ),
        printPos + 6,
        '#666666',
      ),
  ];

  if (row.records) {
    row.records.forEach((row) => {
      pdf = renderSubRecord(config, row, doc, pdf);
    });
  }

  return pdf.reduce((position, renderElement) => {
    let newPosition = renderElement(position);

    if (newPosition > 260) {
      doc.addPage();
      newPosition = 15;
    }
    return newPosition;
  }, startPos);
};

const addText = (
  doc,
  fontSize = 14,
  text = '',
  pos = 0,
  fontStyle = 'normal',
  color = '#000000',
) => {
  doc.setFontSize(fontSize).setFont(undefined, fontStyle).setTextColor(color);

  const wrappedText = doc.splitTextToSize(
    text,
    pdfInMM - marginLeft - marginRight,
  );
  doc.text(wrappedText, marginLeft, pos);
  return pos + (wrappedText.length - 1) * 3;
};

const renderSubRecord = (config: IExportMedicationPDFProps, row, doc, pdf) => {
  return [
    ...pdf,
    (printPos) =>
      addText(
        doc,
        16,
        `${config.fillDetails}: ${formatDate(row.lastRefillDate)}`,
        printPos + 10,
        'bold',
      ),
    (printPos) =>
      addText(doc, 14, config.fillDetails + ': ', printPos + 10, 'bold'),
    (printPos) => {
      let unitText = '';
      switch (row.unit) {
        case 'ea':
          unitText = 'each';
          break;
        case 'na':
          unitText = '';
          break;
      }
      return addText(
        doc,
        11,
        `${config.quantity}: ${row.quantity} ${unitText}`,
        printPos + 6,
        '#666666',
      );
    },
    (printPos) => {
      const linePos = printPos + 6;
      return linePos;
    },
  ];
};

const wrapText = (text, doc) =>
  doc.splitTextToSize(text, pdfInMM - marginLeft - marginRight);

export const generatePersonalDetails = (
  fullName: string,
  dateOfBirth: string,
  dateRange?: string,
) =>
  `Name: ${fullName} | DOB: ${dateOfBirth}${
    dateRange ? ` | Report from: ${dateRange}` : ''
  }`;

export default generatePDF;
