import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@mui/material';
import {
  Home as HomeIcon,
  People as PeopleIcon,
  Help as HelpIcon,
} from '@mui/icons-material';
import { NavLink } from 'react-router-dom';

import {
  INavigationLink,
  INavigationSection,
} from 'core/interfaces/navigation.interface';
import logo from 'assets/images/logo_white.png';
import LabIcon from 'assets/images/labs_icon.svg';
import ImmunizationIcon from 'assets/images/immunization_icon.svg';
import MedicationIcon from 'assets/images/medication_icon.svg';
// import CommunityIcon from 'assets/images/community_icon.svg';
import useStyles from './Navigation.component.styles';

export const NavigationComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);

  const navigationLinks: INavigationSection[] = [
    {
      id: 'general',
      links: [
        {
          id: 'home',
          path: '/home',
          icon: () => <HomeIcon />,
        },
      ],
    },
    {
      id: 'records',
      links: [
        {
          id: 'labs',
          path: '/labs',
          icon: () => <img src={LabIcon} alt={t('navigation.labs')} />,
        },
        {
          id: 'immunizations',
          path: '/immunizations',
          icon: () => (
            <img src={ImmunizationIcon} alt={t('navigation.immunizations')} />
          ),
        },
        {
          id: 'medications',
          path: '/medications',
          icon: () => (
            <div style={styles.medIcon}>
              <img src={MedicationIcon} alt={t('navigation.medications')} />
            </div>
          ),
        },
      ],
    },
    {
      id: 'support',
      links: [
        // {
        //   id: 'communityCare',
        //   path: '/community-care',
        //   icon: () => (
        //     <img
        //       style={styles.medIcon}
        //       src={CommunityIcon}
        //       alt={t('navigation.communityCare')}
        //     />
        //   ),
        // },
        {
          id: 'contact',
          path: '/contact',
          icon: () => <PeopleIcon />,
        },
        {
          id: 'supportCenter',
          path: '/support',
          icon: () => <HelpIcon />,
        },
      ],
    },
  ];

  return (
    <Box sx={{ ...styles.root, flexDirection: 'column' }}>
      <nav>
        <div style={styles.logo}>
          <img alt={t('navigation.altLogo')} style={styles.icon} src={logo} />
        </div>
        {navigationLinks.map(
          (section: INavigationSection, sectionId: number) => (
            <React.Fragment key={`${sectionId}_${section.id}`}>
              {sectionId !== 0 && (
                <div style={styles.sectionTitle}>
                  {t(`navigation.${section.id}`)}
                </div>
              )}
              <ul style={styles.links}>
                {section.links.map((link: INavigationLink, linkId: number) => (
                  <li key={`${linkId}_${link.id}`}>
                    <NavLink
                      style={styles.link}
                      to={link.path}
                      activeStyle={styles.active}
                      title={t(`navigation.${link.id}`)}
                      aria-label={`${t(`navigation.${link.id}`)}`}
                    >
                      <div style={styles.iconContainer}>
                        {link.icon && link.icon()}
                      </div>
                      <span style={styles.linkText}>
                        {t(`navigation.${link.id}`)}
                      </span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          ),
        )}
      </nav>
    </Box>
  );
};

export default NavigationComponent;
