import axios, { AxiosError } from 'axios';
import dotenv from 'dotenv';
import { get } from 'lodash';

import ApiService from 'core/services/api.service';
import EndpointService from 'core/services/endpoint.service';
import { IAuthToken } from 'core/interfaces/auth-token.interface';
import { IProfile } from 'core/interfaces/profile.interface';
import { IAcknowledgement } from 'core/interfaces/acknowledgement.interface';
import { actionTypes } from 'core/contexts/authentication.reducer';
import { Dispatch } from 'react';

dotenv.config();

interface IServerError {
  code: string;
  description: string;
}

const authenticate = async (
  dispatch: any,
  accessCode: string,
  acknowledgements: IAcknowledgement[],
) => {
  const token = await AuthService.login(dispatch, accessCode);

  let user: IProfile | undefined;
  if (token && token.accessToken) {
    user = await AuthService.getUser(dispatch, acknowledgements);
  }

  return new Promise<any>((resolve) => {
    resolve({
      token,
      user,
    });
  });
};

// eslint-disable-next-line
const login = async (
  dispatch: any,
  accessCode: string,
  refreshToken?: string,
  history?: any,
  currentLocation?: string,
) => {
  try {
    dispatch({ type: actionTypes.REQUEST_LOGIN });

    const response = await ApiService.post<any>(
      EndpointService.getRoutes().AUTH.TOKEN(),
      {
        code: refreshToken || accessCode,
        token_type: refreshToken ? 'refresh_token' : 'access_token',
        redirect_uri: `${process.env.REACT_APP_DOMAIN}/callback`,
      },
    );

    const token: IAuthToken = response.data;
    dispatch({
      type: actionTypes.LOGIN_SUCCESS,
      payload: {
        accessToken: token.access_token,
        refreshToken: token.refresh_token,
        idToken: token.id_token,
      },
    });

    setToken(token.access_token);
    setRefreshToken(token.refresh_token);
    setIdToken(token.id_token);
    setAccessCode(accessCode);

    if (currentLocation) {
      history.push(currentLocation);
    }

    return {
      accessToken: token.access_token,
      refreshToken: token.refresh_token,
    };
  } catch (err: any) {
    handleAuthError(dispatch, err, refreshToken, history);
    return err;
  }

  // MOCK
  // dispatch({ type: actionTypes.REQUEST_LOGIN });
  // const response = await new Promise<any>((resolve) => {
  //   resolve({
  //     data: {
  //       access_token: 'c52f7b28194e4802b64229f05eb1f4cd',
  //       token_type: 'bearer',
  //       expires_in: 300,
  //       scope: 'http://idmanagement.gov/ns/assurance/ial/2/aal/2',
  //       refresh_token: '915e7e79b15b400a9df17240083e9df1',
  //       refresh_expires_in: 604800,
  //     },
  //   });
  // });
  // dispatch({
  //   type: actionTypes.LOGIN_SUCCESS,
  //   payload: {
  //     accessToken: response.data.access_token,
  //     refreshToken: response.data.refresh_token,
  //   },
  // });
  // setToken(response.data.access_token);
  // setRefreshToken(response.data.refresh_token);
  // return response.data;
};

const getUser = async (
  dispatch: any,
  acknowledgements: IAcknowledgement[] = [],
) => {
  try {
    dispatch({ type: actionTypes.FETCH_USER });

    const response = await ApiService.post<any>(
      EndpointService.getRoutes().AUTH.USER(),
      { acknowledgements },
    );
    const user: IProfile = response.data;

    dispatch({
      type: actionTypes.FETCH_USER_SUCCESS,
      payload: {
        user,
      },
    });
    return user;
  } catch (err: any) {
    handleAuthError(dispatch, err);
  }
};

const handleAuthError = (
  dispatch: Dispatch<any>,
  err: any,
  refreshToken?: string,
  history?: any,
) => {
  const axiosError = err as AxiosError<IServerError>;

  const errorMessage = get(axiosError, 'response.data.description', '');

  dispatch({
    type: actionTypes.LOGIN_ERROR,
    payload: {
      errorMessage,
    },
  });

  if (refreshToken) {
    logout();
    history.push('/login');
  }
  throw err;
};

const logout = (dispatch?: any) => {
  try {
    if (dispatch) {
      dispatch({ type: actionTypes.LOGOUT });
    }
    clearToken();
    clearRefreshToken();
    clearIdToken();
    clearAccessCode();
    //console.log(EndpointService.getRoutes().AUTH.LOGOUT());
    window.location.href = EndpointService.getRoutes().AUTH.LOGOUT();
  } catch (err: any) {
    handleAuthError(dispatch, err);
  }
};

const refreshToken = async (
  dispatch: Dispatch<any>,
  history: any,
  isAuthenticating = false,
) => {
  if (isAuthenticating) return;

  const accessCode = getAccessCode();
  const refreshToken = getRefreshToken();

  try {
    const response = await axios.post<any>(
      EndpointService.getRoutes().AUTH.TOKEN(),
      {
        code: refreshToken || accessCode,
        token_type: refreshToken ? 'refresh_token' : 'access_token',
        redirect_uri: `${process.env.REACT_APP_DOMAIN}/callback`,
      },
    );

    const token: IAuthToken = response.data;
    // dispatch({
    //   type: actionTypes.LOGIN_SUCCESS,
    //   payload: {
    //     accessToken: token.access_token,
    //     refreshToken: token.refresh_token,
    //   },
    // });

    setToken(token.access_token);
    setRefreshToken(token.refresh_token);
    setIdToken(token.id_token);
    setAccessCode(accessCode);

    //   AuthService.handleRefreshSuccess(dispatch);
    return token.access_token;
  } catch (err: any) {
    handleAuthError(dispatch, err, refreshToken, history);
    return err;
  }
};

const handleRefreshSuccess = (dispatch) => {
  getUser(dispatch);
};

// region Session Management
const getToken = () => {
  return localStorage.getItem('accessToken') || '';
};

const setToken = (token: string) => {
  clearToken();
  localStorage.setItem('accessToken', token);
};

const clearToken = () => {
  localStorage.removeItem('accessToken');
};

const getIdToken = () => {
  return localStorage.getItem('idToken') || '';
};

const setIdToken = (token: string) => {
  clearIdToken();
  localStorage.setItem('idToken', token);
};

const clearIdToken = () => {
  localStorage.removeItem('idToken');
};

const getRefreshToken = () => {
  return localStorage.getItem('refreshToken') || '';
};

const setRefreshToken = (token: string) => {
  clearRefreshToken();
  localStorage.setItem('refreshToken', token);
};

const clearRefreshToken = () => {
  localStorage.removeItem('refreshToken');
};

const getAccessCode = () => {
  return localStorage.getItem('code') || '';
};

const setAccessCode = (token: string) => {
  clearAccessCode();
  localStorage.setItem('code', token);
};

const clearAccessCode = () => {
  localStorage.removeItem('code');
};
// endregion

const AuthService = {
  authenticate,
  getRefreshToken,
  getToken,
  getUser,
  getIdToken,
  handleAuthError,
  handleRefreshSuccess,
  login,
  logout,
  refreshToken,
};

export default AuthService;
