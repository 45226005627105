import React, { useReducer } from 'react';

import {
  AuthReducer,
  initialState,
  IReducerState,
} from 'core/contexts/authentication.reducer';

const AuthStateContext = React.createContext<IReducerState>(initialState);
const AuthDispatchContext = React.createContext([]);

export function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
}

export function useAuthDispatch() {
  const context = React.useContext(
    AuthDispatchContext,
  ) as unknown as IReducerState;
  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }

  return context;
}

export const AuthProvider = (props: any) => {
  const [user, dispatch] = useReducer(AuthReducer, initialState);

  return (
    <AuthStateContext.Provider value={user}>
      <AuthDispatchContext.Provider value={dispatch} {...props}>
        {props.children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};
