import { Theme } from '@mui/material';
import Color from 'color';

const styles = (theme: Theme) => ({
  section: {
    marginBottom: '20px',
    display: 'flex',
    width: '100%',
  },
  sectionTitle: {
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
    fontSize: '1.5rem',
    marginBottom: '23px',
  },
  recordCardContainer: {
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'inline-block',
  },
  recordCard: {
    padding: '24px 28px',
    fontSize: '0.875rem',
    display: 'flex',
  },
  labImage: {
    marginRight: '30px',
    width: '38px',
    display: 'flex',
  },
  emptyLabImage: {
    marginRight: '30px',
    width: '175px',
    display: 'flex',
  },
  details: {
    display: 'flex',
  },
  date: {
    fontWeight: 'bold',
  },
  cardTitle: {
    fontSize: '1.25rem',
    marginBottom: '3px',
  },
  subtitle: {
    color: '#666666',
  },
  info: {
    marginBottom: '34px',
  },
  actions: {
    display: 'flex',
  },
  actionButton: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: '0',
    color: '#203b71',
    display: 'flex',
    cursor: 'pointer',
  },
  resultValue: {
    display: 'inline',
    border: '1px solid',
    padding: '5px 10px',
    borderRadius: '159px',
    lineHeight: '1',
  },
});

export default styles;
