import { Theme } from '@mui/material';
import Color from 'color';

const styles = (theme: Theme) => ({
  container: {
    width: '100%',
    padding: '25px 40px 40px',
    backgroundColor: Color(theme.palette.grayScale.light)
      .alpha(1)
      .hsl()
      .toString(),
  },
  balloonWrapper: {
    backgroundColor: Color(theme.palette.grayScale.light)
      .alpha(1)
      .hsl()
      .toString(),
  },
  balloon: {
    width: 'fit-content',
    maxWidth: '150px',
    padding: '10px',
    bottom: '23px',
    borderRadius: '5px',
    backgroundColor: theme.palette.grayScale.gradient50,
    boxShadow:
      '1px 3px 4px rgba(152, 152, 152, 0.2), 1px 2px 6px rgba(55, 128, 181, 0.14), 1px 1px 12px rgba(55, 128, 181, 0.12)',

    '&::after': {
      content: "' '",
      right: '40%',
      bottom: '-15px',
      borderTop: `15px solid ${theme.palette.grayScale.gradient300}`,
      borderRight: '15px solid transparent',
      borderLeft: '15px solid transparent',
      borderBottom: 'none',
    },
  },
  rangeBarWrap: {
    display: 'flex',
    height: '75px',
    alignItems: 'flex-end',
  },
  dangerRange: {
    backgroundColor: Color(theme.palette.warning.main)
      .alpha(1)
      .hsl()
      .toString(),
    flex: '0 0 20%',
    height: '8px',
  },
  normalRange: {
    backgroundColor: Color(theme.palette.secondary.main)
      .alpha(1)
      .hsl()
      .toString(),
    flex: '0 0 60%',
    height: '8px',
  },
  divider: {
    height: '58px',
    width: '1px',
    backgroundColor: 'rgba(0, 0, 0, 0.23)',
    transform: 'translateY(25px)',
  },
});

export default styles;
