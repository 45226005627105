import { Theme } from '@mui/material';
import Color from 'color';

const styles = (theme: Theme) => ({
  root: {
    padding: { tablet: '45px', mobile: '45px 15px' },
    minWidth: { laptop: '1000px' },
  },
  asideActions: {
    display: 'none',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'row',
      display: 'flex',
      flex: 'none',
      marginBottom: '23px',
    },
  },
  pageTitle: {
    fontSize: '2rem',
    marginBottom: '18px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  rowLink: {
    color: Color(theme.palette.primary.main).alpha(1).hsl().toString(),
  },
  resultColumn: {
    display: 'flex',
    border: '1px solid #cdcdcd',
    padding: '5px 10px',
    borderRadius: '159px',
    lineHeight: '1',
  },
  empty: {
    color: Color(theme.palette.grayScale.main).hsl().alpha(0.6).toString(),
  },
  emptyTitle: {
    fontSize: '2.125rem',
    marginBottom: '15px',
  },
  label: {
    fontWeight: 700,
  },
  tips: {
    padding: 0,
    margin: 0,
    paddingInlineStart: '15px',
  },
});

export default styles;
