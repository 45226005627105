import { Theme } from '@mui/material';
import Color from 'color';

const styles = (theme: Theme) => ({
  root: {
    padding: { tablet: '45px', mobile: '45px 15px' },
  },
  asideActions: {
    display: 'none',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'row',
      display: 'flex',
      flex: 'none',
      marginBottom: '23px',
    },
  },
  formControl: {
    width: { tablet: '250px', mobile: '100%' },
  },
  actions: {
    display: 'flex',
    justifyContent: { tablet: 'flex-end', mobile: 'flex-start' },
    alignItems: 'flex-start',
    flex: 'auto',
    color: theme.palette.primary.main,
  },
  actionButton: {
    marginLeft: '15px',
  },
  pageTitle: {
    fontSize: '2rem',
    marginBottom: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  rowLink: {
    color: Color(theme.palette.primary.main).alpha(1).hsl().toString(),
  },
  toolTip: {
    backgroundColor: theme.palette.grayScale.light,
    padding: '5px 10px',
  },
  chartContainer: {
    padding: '0px 10px',
  },
  selectedDot: {
    filter: 'drop-shadow(2px 2px 2px rgba(152, 152, 152, 0.4))',
  },
  badge: {
    borderRadius: '100px',
    backgroundColor: theme.palette.secondary.main,
    height: '16px',
    width: '16px',
    marginRight: '5px',
    display: 'inline-block',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default styles;
