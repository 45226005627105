import React, { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Skeleton,
} from '@mui/material';
import Button from '@mui/material/Button';
import { get, reject } from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import htmlParser from 'html-react-parser';
import { ICondition } from 'core/interfaces/condition.interface';
import ProfileService from 'core/services/profile.service';
import { useAuthState } from 'core/contexts/authentication.context';
import NotificationComponent from '../Notification/Notification.component';

import useStyles from './Consent.component.styles';

interface IConsentProps {
  condition: ICondition;
  unacknowledgedConditions: ICondition[];
  onAcknowledgement: any;
}

export const handleConditionsHtml = (contentString: string) => {
  const outputString: string = contentString.replaceAll('\n', '<br/>');
  return htmlParser(outputString);
};

export const ConsentComponent = ({
  condition,
  unacknowledgedConditions,
  onAcknowledgement,
}: IConsentProps) => {
  const { t }: { t: any } = useTranslation();
  const styles = useStyles();
  const { user } = useAuthState();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasViewedTerms, setHasViewedTerms] = useState<boolean>(false);
  const [isNotificationOpen, showNotification] = React.useState(false);
  const consentScroller = useRef(null);

  useEffect(() => {
    reset();
    setIsLoading(false);
  }, [condition]);

  useEffect(() => {
    let atBottom = false;

    if (consentScroller.current !== null) {
      const scroller = consentScroller.current as HTMLDivElement;
      atBottom = scroller.clientHeight === scroller.scrollHeight;
    }

    if (atBottom && !hasViewedTerms) {
      setHasViewedTerms(true);
    }
  }, [setHasViewedTerms, hasViewedTerms]);

  const openNotification = () => {
    showNotification(true);
  };

  const closeNotification = () => {
    showNotification(false);
  };

  const scrollTerms = (e: BaseSyntheticEvent) => {
    const atBottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (atBottom && !hasViewedTerms) {
      setHasViewedTerms(true);
    }
  };

  const onSubmit = (formValues: any) => {
    setIsLoading(true);
    const submit = user ? saveConditions : temporarySaveConditions;
    submit(formValues);
  };

  const temporarySaveConditions = (formValues: any) => {
    ProfileService.saveAcknowledgementToStorage({
      conditionsId: condition._id,
      type: condition.type,
      consent: formValues.consent ? 'yes' : 'no',
    });
    handleAcknowledgementSuccess();
  };

  const saveConditions = (formValues: any) => {
    const userId = get(user, '_id', '');
    ProfileService.acknowledgeCondition(userId, [
      {
        conditionsId: condition._id,
        consent: formValues.consent ? 'yes' : 'no',
      },
    ])
      .then(handleAcknowledgementSuccess)
      .catch(handleAcknowledgementFail);
  };

  const handleAcknowledgementSuccess = () => {
    setIsLoading(false);
    const restOfUnacknowledgedConditions: ICondition[] = reject(
      unacknowledgedConditions,
      {
        type: condition.type,
      },
    );
    const nextConditionType = get(restOfUnacknowledgedConditions, '[0].type');
    onAcknowledgement(nextConditionType);
  };

  const handleAcknowledgementFail = () => {
    setIsLoading(false);
    openNotification();
  };

  return (
    <React.Fragment>
      <NotificationComponent
        isOpen={isNotificationOpen}
        handleClose={closeNotification}
        message={t('notifications.acknowledgementFail')}
      />
      <Paper
        elevation={3}
        style={{ ...styles.terms, overflowY: 'auto' }}
        onScroll={scrollTerms}
        ref={consentScroller}
      >
        {!condition ? (
          <React.Fragment>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="rectangular" height={118} />
            <Skeleton variant="text" />
          </React.Fragment>
        ) : (
          <div style={styles.conditions}>
            {handleConditionsHtml(condition.terms_and_conditions)}
          </div>
        )}
      </Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ ...styles.form, flexDirection: 'column' }}>
          <FormControlLabel
            control={
              <Controller
                name="consent"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, ...field } }) => {
                  return (
                    <Checkbox
                      {...field}
                      checked={!!value}
                      inputProps={{ 'aria-label': t('conditions.agreement') }}
                    />
                  );
                }}
              />
            }
            {...register('consent', { required: true })}
            label={t('conditions.agreement')}
          />
          <Button
            disabled={!isValid || !hasViewedTerms || isLoading}
            type="submit"
            style={styles.cta}
            title={t('actions.next')}
            aria-label={t('actions.next')}
            variant="contained"
            size="large"
          >
            {t('actions.next')}
          </Button>
        </Box>
      </form>
    </React.Fragment>
  );
};

export default ConsentComponent;
