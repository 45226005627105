import { Box, useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import useStyles from './AlertBox.component.styles';
import AlertTitle from '@mui/material/AlertTitle';
import { NavLink } from 'react-router-dom';

export const AlertBoxComponent = ({ content }) => {
  const theme = useTheme();
  const styles = useStyles(theme);

  return (
    <Box
      sx={{
        ...styles.recordCard,
        ...styles.emptyCard,
        flexDirection: { tablet: 'row', mobile: 'column' },
      }}
    >
      <Alert style={styles.alert} severity="warning" variant="outlined">
        <AlertTitle>Attention</AlertTitle>
        {/* <div style={{ ...styles.cardTitle }}></div> */}
        <div style={styles.subtitle}>
          <span>{content}</span>
          <span>
            <NavLink to="/contact">Support</NavLink>
          </span>
        </div>
      </Alert>
    </Box>
  );
};

export default AlertBoxComponent;
