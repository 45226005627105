import apiService from './api.service';
import endpointService from './endpoint.service';
import { ISupportTopic } from 'core/interfaces/support-topic.interface';

interface ISupportEmailDetails {
  reason: string;
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

interface ICommunityResourceDetails {
  worriedAboutLosingHousing: string;
  hasTransportationKeptYouFrom: any;
  unabletoFindtheFollowing: any;
}

const getSupportTopics = async (params?: {}) => {
  return apiService.get<ISupportTopic[]>(
    endpointService.getRoutes().SUPPORT.TOPICS(),
    {
      params,
    },
  );
};

const getPublicSupportTopics = async (params?: {}) => {
  return apiService.get<ISupportTopic[]>(
    endpointService.getRoutes().SUPPORT.TOPICS_PUBLIC(),
    {
      params,
    },
  );
};

const sendSupportEmail = async (
  userId: string,
  payload: ISupportEmailDetails,
) => {
  return apiService.post<any>(
    endpointService.getRoutes().SUPPORT.EMAIL(userId),
    payload,
  );
};

const sendCommunityResourceData = async (
  userId: string,
  payload: ICommunityResourceDetails,
) => {
  return apiService.post<any>(
    endpointService.getRoutes().SUPPORT.COMMUNITY_RESOURCE_DATA(userId),
    payload,
  );
};

export const service = {
  getSupportTopics,
  sendSupportEmail,
  sendCommunityResourceData,
  getPublicSupportTopics,
};

export default service;
