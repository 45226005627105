import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import AuthService from './auth.service';

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

const headers: Readonly<Record<string, string>> = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8',
  'x-api-key': `${process.env.REACT_APP_X_API_KEY}`,
  'x-grant-type': 'authorization_code',
  // 'Access-Control-Allow-Origin':'*',
  // 'X-Frame-Options': 'DENY',
  // 'X-Content-Type-Options': 'nosniff',
  // 'Strict-Transport-Security': 'max-age=864000; includeSubDomains',
  // 'Content-Security-Policy': '',
  // 'Referrer-Policy': 'strict-origin-when-cross-origin',
  // 'Expect-CT': '',
  // 'Permissions-Policy': '',
  // 'Cross-Origin-Embedder-Policy': '',
  // 'Cross-Origin-Resource-Policy': '',
  // 'Cross-Origin-Opener-Policy': '',

  // 'Access-Control-Allow-Credentials': 'true',
  // 'X-Requested-With': 'XMLHttpRequest',
};

// We can use the following function to inject the JWT token through an interceptor
// We get the `accessToken` from the localStorage that we set when we authenticate
const injectToken = (config: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    const token = localStorage.getItem('accessToken');
    const idToken = localStorage.getItem('idToken');

    const headers = config.headers || {};

    if (config.url && config.url.includes('user')) {
      if (idToken) {
        delete headers.idtoken;
        headers.idtoken = idToken;
        config = {
          ...config,
          headers,
        };
      }
    }

    if (token) {
      delete headers.authorization;
      delete headers.Authorization;
      headers.authorization = `Bearer ${token}`;
    }

    if (token != null) {
      config = {
        ...config,
        headers,
      };
    }
    return config;
  } catch (error: any) {
    throw new Error(error);
  }
};

export class Http {
  instance: AxiosInstance | null = null;
  isRefreshing = false;
  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.initHttp();
  }

  initHttp() {
    const http = axios.create({
      headers,
    });

    http.interceptors.request.use(injectToken, (error) =>
      Promise.reject(error),
    );

    this.instance = http;
    return http;
  }

  request<T = any, R = AxiosResponse<T>>(
    config: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.request(config);
  }

  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.get<T, R>(url, config);
  }

  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.post<T, R>(url, data, config);
  }

  put<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.put<T, R>(url, data, config);
  }

  delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.http.delete<T, R>(url, config);
  }

  getInstance() {
    return this.http;
  }

  // Handle global app errors
  // We can handle generic app errors depending on the status code
  handleError(
    error: any,
    dispatch?: any,
    history?: any,
    isAuthenticating?: boolean,
  ) {
    switch (error?.response?.status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        break;
      }
      case StatusCode.Forbidden:
      case StatusCode.Unauthorized: {
        // Handle Unauthorized
        const originalRequest = error.config;
        if (!originalRequest._retry) {
          if (!this.isRefreshing) {
            originalRequest._retry = true;
            this.isRefreshing = true;
            AuthService.refreshToken(dispatch, history, isAuthenticating)
              .then((newToken) => {
                localStorage.setItem('accessToken', newToken);
                return this.http(injectToken(originalRequest));
              })
              .finally(() => {
                this.isRefreshing = false;
              });
          }
        }
        break;
      }
      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        break;
      }
    }

    return Promise.reject(error);
  }
}

export default new Http();
