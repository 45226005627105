import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ILabRecord } from 'core/interfaces/lab-record.interface';
import { Box, Button, Paper, Skeleton, useTheme } from '@mui/material';
import { NavLink } from 'react-router-dom';
import {
  Download as DownloadIcon,
  MailOutline as ShareIcon,
} from '@mui/icons-material';
import { chain, compact, get, map, take, noop } from 'lodash';

import LabIcon from 'assets/images/labs.svg';
import utils, { formatDate, truncate } from 'utils/utils';
import EmptyLabsTileComponent from 'components/EmptyLabsTile/EmptyLabsTile.component';
import generatePDF, {
  generatePersonalDetails,
} from 'core/services/export.service';
import { useAuthState } from 'core/contexts/authentication.context';

import useStyles from './LabResults.component.styles';

interface LabResultsProps {
  isLoading?: boolean;
  labRecords: ILabRecord[] | null | undefined;
}

const LabResults = ({
  labRecords = [],
  isLoading = false,
}: LabResultsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);
  const { user } = useAuthState();
  const [chunk, setChunk] = useState<number>(1);
  const columns = [
    {
      field: 'testName',
      headerName: t('labs.columns.test'),
      width: 250,
    },
    {
      field: 'testType',
      headerName: t('labs.columns.type'),
      width: 120,
    },
    {
      field: 'performers',
      headerName: t('labs.columns.performers'),
      width: 250,
    },
    {
      field: 'modifiedDate',
      headerName: t('labs.columns.date'),
      width: 120,
    },
  ];

  useEffect(noop, [chunk]);

  const exportSingleRecord = (event, record) => {
    event.preventDefault();
    const dataColumns = compact(map(columns, 'field'));
    const dataRows = map([record], (row) =>
      mapRowsToReportExport(row, dataColumns),
    );
    exportPDF(user, dataRows);
  };

  const exportPDF = (user, dataRows) => {
    const dob = get(user, 'dateOfBirth', '');
    generatePDF({
      tables: [
        {
          rows: dataRows,
          columns: compact(map(columns, 'headerName')),
          yPos: 60,
        },
      ],
      title: 'Lab Records',
      personalDetails: generatePersonalDetails(
        `${user?.firstName} ${user?.lastName}`,
        formatDate(dob),
      ),
      filename: 'labs',
      disclaimer: t('panel.disclaimer'),
    });
  };

  const mapRowsToReportExport = (row, columns: string[]) =>
    chain(row).pick(columns).values().value();

  const renderLoading = () => (
    <Box sx={styles.loadingContainer}>
      <Box component={Paper} elevation={3} sx={styles.recordPaper}>
        <div>
          <Skeleton variant="circular" width={38} height={38} />
        </div>
        <div style={{ width: '100%' }}>
          <Box
            sx={{
              flexDirection: 'row',
              width: '90%',
              height: '100px',
              marginLeft: '15px',
            }}
          >
            <div style={{ width: '100%' }}>
              <Skeleton width="100%" height={25} />
            </div>
            <div style={{ width: '100%' }}>
              <Skeleton width="65%" />
            </div>
            <div style={{ width: '100%' }}>
              <Skeleton width="50%" />
            </div>
          </Box>
        </div>
      </Box>
    </Box>
  );

  const renderLabResultTile = (record, index) => (
    <Box
      component={NavLink}
      to={`/labs/${record.id}`}
      sx={styles.recordCard}
      key={index}
    >
      <Box
        component={Paper}
        elevation={3}
        sx={{
          ...styles.recordPaper,
          flexDirection: { tablet: 'row', mobile: 'column' },
        }}
      >
        <Box sx={styles.labImage}>
          <img
            alt={t('landing.altNewLabs')}
            src={LabIcon}
            style={{ width: '38px' }}
          />
        </Box>
        <div
          style={{
            ...styles.details,
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <div style={styles.cardTitle}>{truncate(record.testName, 23)}</div>
          <div style={styles.subtitle}>
            <div>
              {t('general.providedBy')} {record.performers}
            </div>
            <div>
              {t('general.updatedOn')}{' '}
              <span style={{ fontWeight: 'bold' }}>
                {utils.formatDate(record.modifiedDate)}
              </span>
            </div>
          </div>

          <Box
            sx={{
              ...styles.actions,
              flexDirection: 'row',
            }}
          >
            <Button
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
              title={t('actions.download')}
              aria-label={t('actions.download')}
              onClick={(event) => exportSingleRecord(event, record)}
            >
              <DownloadIcon />
              <span>{t('actions.download')}</span>
            </Button>

            {false && (
              <Button
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                title={t('actions.share')}
                aria-label={t('actions.share')}
              >
                <ShareIcon />
                <span>{t('actions.share')}</span>
              </Button>
            )}
          </Box>
        </div>
      </Box>
    </Box>
  );

  const visibleRecords: ILabRecord[] = take(labRecords, chunk * 4);

  return (
    <Box sx={{ ...styles.root, position: 'relative' }}>
      <div style={styles.sectionTitle}>{t('labs.newLabTests')}</div>
      <div>
        {isLoading && renderLoading()}
        {!isLoading &&
          labRecords && [
            <Box
              key="records"
              sx={{
                ...styles.tileContainer,
                display: 'flex',
                flexWrap: 'wrap',
                gap: '15px',
                // overflowX: 'auto',
                // whiteSpace: 'nowrap',
              }}
            >
              {labRecords.length === 0 && <EmptyLabsTileComponent />}
              {visibleRecords.map((record, index: number) =>
                renderLabResultTile(record, index),
              )}
            </Box>,
            visibleRecords.length < labRecords.length && (
              <Button
                key="moreButton"
                title={t('navigation.more')}
                aria-label={t('navigation.more')}
                onClick={() => setChunk(chunk + 1)}
              >
                <span>{t('navigation.more')}</span>
              </Button>
            ),
          ]}
      </div>
    </Box>
  );
};

export default LabResults;
