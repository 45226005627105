import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './PanelReport.component.style';
import { useTheme, Box } from '@mui/material';

interface IpanelReportProps {
  htmlContent: string | JSX.Element | JSX.Element[];
}

const PanelReport = ({ htmlContent }: IpanelReportProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);
  return (
    <Box id="reportContWrapper">
      <Box sx={styles.detailBox} id="reportContainer">
        <div style={styles.sectionTitle}>{t('panel.panelReportTitle')}</div>
        <p>{htmlContent}</p>
      </Box>
    </Box>
  );
};

export default PanelReport;
