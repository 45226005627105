import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  MenuItem,
  Paper,
  SelectChangeEvent,
  TextField,
  useTheme,
} from '@mui/material';
import querystring, { ParsedUrlQueryInput } from 'querystring';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { find, get, map, omit, compact, omitBy, identity, chain } from 'lodash';
import { DateTime } from 'luxon';
import { AxiosResponse } from 'axios';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import { useHistory, Link } from 'react-router-dom';
import {
  Download as DownloadIcon,
  MailOutline as ShareIcon,
} from '@mui/icons-material';
import { GridSortDirection, GridSortItem } from '@mui/x-data-grid';

import SearchService from 'core/services/search.service';
import { ISortOption } from 'core/interfaces/sort-option.interface';
import MobileHeaderComponent from 'components/MobileHeader/MobileHeader.component';
import FilterComponent from 'components/Filter/Filter.component';
import FormControlAdapterComponent from 'components/FormControlAdapter/FormControlAdapter.component';
import ChipSelectComponent from 'components/ChipSelect/ChipSelect.component';
import SearchResultsDataGridComponent from 'components/SearchResultsDataGrid/SearchResultsDataGrid.component';
import { useAuthState } from 'core/contexts/authentication.context';
import { ILabMetadata } from 'core/interfaces/lab-metadata.interface';
import { ILabRecord, ILabResponse } from 'core/interfaces/lab-record.interface';
import LabResults from 'components/LabResult/LabResults.component';
import LabIcon from 'assets/images/labs.svg';
import generatePDF, {
  generatePersonalDetails,
} from 'core/services/export.service';
import { formatDate } from 'utils/utils';

import useStyles from './Labs.container.styles';
import AlertBoxComponent from 'components/AlertBox/AlertBox.component';

export default function LabsContainer() {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);
  const history = useHistory();
  const { user } = useAuthState();
  const today = DateTime.local();
  const [queryParams, changeQueryParams] = useState<any>(
    querystring.decode(window.location.search.replace('?', '')),
  );
  const rowsPerPage = 15;
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [totalRowCount, setTotalRowCount] = useState<number>();
  const [newLabRecords, setNewLabRecords] = useState<ILabRecord[]>([]);
  const [selectedRows, setSelectedRows] = useState<{
    [key: string]: ILabRecord[];
  }>({ 0: [] });
  const [selectedRowIds, setSelectedIds] = useState<{
    [key: string]: string[];
  }>({ 0: [] });
  const [data, setData] = useState<ILabRecord[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [sort, setSort] = useState<GridSortItem | ''>({
    field: 'testName',
    sort: 'asc',
  });
  const [, setQuery] = React.useState<string>('');
  const [facilities, setFacilities] = React.useState<string[]>([]);
  const [labTypes, setLabTypes] = React.useState<string[]>([]);
  const [dateRange, setDateRange] = React.useState<string>('3year');
  const [isCustomDateRange, setCustomDateRange] =
    React.useState<boolean>(false);
  const [isSearching, setIsSearching] = React.useState<boolean>(false);
  const [dateRangeEnd, setDateRangeEnd] = React.useState<Date>(new Date());
  const [facilityOptions, setFacilityOptions] = React.useState<ISortOption[]>(
    [],
  );
  const [labTypeOptions, setLabTypeOptions] = React.useState<ISortOption[]>([]);

  const dateOffsets: { [id: string]: { months?: number; years?: number } } = {
    '1months': { months: 1 },
    '3months': { months: 3 },
    '6months': { months: 6 },
    '1year': { years: 1 },
    '3year': { years: 3 },
  };

  const [dateRangeStart, setDateRangeStart] = React.useState<Date>(
    today.minus(dateOffsets['3year']).toJSDate(),
  );

  const dateOptions: ISortOption[] = [
    {
      id: '1months',
      displayName: 'general.dateOptions.1months',
    },
    {
      id: '3months',
      displayName: 'general.dateOptions.3months',
    },
    {
      id: '6months',
      displayName: 'general.dateOptions.6months',
    },
    {
      id: '1year',
      displayName: 'general.dateOptions.1year',
    },
    {
      id: '3year',
      displayName: 'general.dateOptions.3year',
    },
    {
      id: 'custom',
      displayName: 'general.dateOptions.custom',
    },
  ];

  const labResultTypes = ['Single', 'Panel', 'Report'];

  const resetValues = {
    facilities: [],
    testType: [],
    dateRange: '3year',
  };

  useEffect(() => {
    if (user) {
      getLabMetadata();
      getNewLabRecords();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const queryParams = querystring.decode(
        window.location.search.replace('?', ''),
      );
      changeQueryParams(queryParams);

      // Set current page
      setPage(queryParams.page ? parseFloat(queryParams.page as string) : 0);

      // Set sort
      const field = get(queryParams, 'sort', 'testName') as string;
      const sort = get(
        queryParams,
        'sortDirection',
        'asc',
      ) as GridSortDirection;
      setSort({ field, sort });

      // Set Facilities
      if (queryParams.facilities) {
        const facilities = queryParams.facilities as string;
        const parsedFacilities: string[] = facilities.split('|');
        setFacilities(parsedFacilities);
      }

      // Set Lab Types
      if (queryParams.testType) {
        const labTypes = queryParams.testType as string;
        const parsedLabTypes: string[] = labTypes.split('|');
        setLabTypes(parsedLabTypes);
      }

      // Set Date Range
      if (queryParams.dateRange) {
        const dateRange = queryParams.dateRange as string;
        handleDateRangeQueryParam(dateRange);
      }

      if (queryParams.dateStart) {
        const dateStart = queryParams.dateStart as string;
        setDateRangeStart(DateTime.fromISO(dateStart).toJSDate());
      }

      if (queryParams.dateEnd) {
        const dateEnd = queryParams.dateEnd as string;
        setDateRangeEnd(DateTime.fromISO(dateEnd).toJSDate());
      }

      if (queryParams.query) {
        const query = queryParams.query as string;
        setQuery(query);
      }

      searchRecords(queryParams);
    }
  }, [
    user,
    changeQueryParams,
    setPage,
    setSort,
    setFacilities,
    setLabTypes,
    setDateRangeStart,
    setDateRangeEnd,
    setQuery,
    window.location.search,
  ]);

  const handleDateRangeQueryParam = (dateRange: string) => {
    if (!find(dateOptions, { id: dateRange })) {
      const newQuery = omit(queryParams, 'dateRange');
      history.push('/labs', {
        search: querystring.encode(newQuery),
      });
      return;
    }
    setCustomDateRange(dateRange === 'custom');
    setDateRange(dateRange);
  };

  const getNewLabRecords = () => {
    setIsLoading(true);
    const userId = get(user, '_id', '');
    SearchService.getNewLabs(userId)
      .then(getNewLabsSuccess)
      .catch(getNewLabsFail);
  };

  const getNewLabsSuccess = (response: AxiosResponse<ILabResponse>) => {
    setIsLoading(false);
    const newLabRecords: ILabRecord[] = response.data.records;
    setNewLabRecords(newLabRecords);
  };

  const getNewLabsFail = () => {
    setIsLoading(false);
    // TODO: Snackbar
  };

  const getLabMetadata = () => {
    const userId = get(user, '_id', '');
    SearchService.getLabsMetadata(userId)
      .then(getLabsMetadataSuccess)
      .catch(getLabsMetadataFail);
  };

  const getLabsMetadataSuccess = (response: AxiosResponse<ILabMetadata>) => {
    const metadata: ILabMetadata = response.data;
    const facilities: ISortOption[] = map(
      compact(metadata.performers),
      convertOptionsToSortOptions,
    );
    setFacilityOptions(facilities);

    const labTypes: ISortOption[] = map(
      labResultTypes,
      convertOptionsToSortOptions,
    );
    setLabTypeOptions(labTypes);
  };

  const convertOptionsToSortOptions = (option: string): ISortOption => ({
    id: option,
    displayName: `labs.labTypes.${option}`,
  });

  const getLabsMetadataFail = () => {
    // TODO: Snackbar
  };

  const searchRecords = (queryParams: any) => {
    const userId = get(user, '_id', '');
    setIsSearching(true);
    SearchService.getLabs(userId, {
      ...queryParams,
      page: page + 1,
      limit: rowsPerPage,
      dateStart:
        queryParams?.dateStart ||
        DateTime.fromJSDate(dateRangeStart).toISODate(),
      dateEnd:
        queryParams?.dateEnd || DateTime.fromJSDate(dateRangeEnd).toISODate(),
    })
      .then(handleLabsSuccess)
      .catch(handleLabsFail);
  };

  const handleLabsSuccess = (response: AxiosResponse<ILabResponse>) => {
    const labs: ILabRecord[] = response.data.records;
    setData(labs);
    setTotalRowCount(response.data.metadata.total_count);
    setTotalPages(response.data.metadata.total_pages);
    setIsSearching(false);
  };

  const handleLabsFail = () => {
    setIsSearching(false);
    // TODO: Fail
  };

  const handleChangePage = (event: any, page: number) => {
    setPage(page);
    const newQuery = { ...queryParams, page: page };
    history.push({
      pathname: '/labs',
      search: querystring.encode(newQuery),
    });
  };

  const applyFilters = (values: any) => {
    const { dateStart, dateEnd } = calculateDateRange(values.dateRange);
    const newQuery = {
      page,
      limit: rowsPerPage,
      ...values,
      facilities: values.facilities.join('|'),
      testType: values.testType.join('|'),
      dateStart,
      dateEnd,
    };

    navigateAndSearch(newQuery);
  };

  const calculateDateRange = (dateRange: string) => {
    if (dateRange === 'custom') {
      return {
        dateStart: DateTime.fromJSDate(dateRangeStart).toISODate(),
        dateEnd: DateTime.fromJSDate(dateRangeEnd).toISODate(),
      };
    }

    return {
      dateStart: DateTime.now().minus(dateOffsets[dateRange]).toISODate(),
      dateEnd: today.toISODate(),
    };
  };

  const typeColumn = (params: GridRenderCellParams) => {
    return (
      <div style={styles.labTestLabel}>
        <img style={styles.labTestIcon} src={LabIcon} alt={t('labs.labTest')} />
        <span>{params.value}</span>
      </div>
    );
  };

  const testColumn = (params: GridRenderCellParams) => {
    return (
      <Link
        style={{ ...styles.rowLink, textDecoration: 'none' }}
        to={`/labs/${params.row.id}`}
      >
        {params.value}
      </Link>
    );
  };

  const actionsColumn = (record) => {
    return (
      <div style={styles.actionsCell}>
        <Button
          title={t('actions.download')}
          aria-label={t('actions.download')}
          onClick={() => exportSingleRecord(record.row)}
        >
          <DownloadIcon />
        </Button>
        {false && (
          <Button title={t('actions.share')} aria-label={t('actions.share')}>
            <ShareIcon />
          </Button>
        )}
      </div>
    );
  };

  const columns = [
    {
      field: 'testName',
      headerName: t('labs.columns.test'),
      width: 250,
      renderCell: testColumn,
    },
    {
      field: 'testType',
      headerName: t('labs.columns.type'),
      width: 120,
      renderCell: typeColumn,
    },
    {
      field: 'performers',
      headerName: t('labs.columns.performers'),
      width: 250,
    },
    {
      field: 'modifiedDate',
      headerName: t('labs.columns.date'),
      width: 120,
    },
    {
      field: '',
      headerName: '',
      width: 130,
      renderCell: actionsColumn,
    },
  ];

  const handleDataGridSelectionChange = (newSelection: string[]) => {
    setSelectedIds({ ...selectedRows, [page]: newSelection });

    const selections = chain(newSelection).map(findById).compact().value();
    const currentSelections = { ...selectedRows, [page]: selections };
    setSelectedRows(currentSelections);
  };

  const findById = (id) => find(data, { id });

  const onSortChange = (event: { field: string; sort: string }[]) => {
    const currentSort = get(event, '[0]');

    const newQuery = {
      ...queryParams,
      page: 0,
    };

    if (currentSort) {
      newQuery.sort = currentSort.field;
      newQuery.sortDirection = currentSort.sort;
      setSort(currentSort);
    } else {
      delete newQuery.sort;
      delete newQuery.sortDirection;
      setSort('');
    }

    navigateAndSearch(newQuery);
  };

  const navigateAndSearch = (query = {}) => {
    const queryParams = omitBy(
      query,
      (v) => !identity(v),
    ) as ParsedUrlQueryInput;
    history.push({
      pathname: '/labs',
      search: querystring.encode(queryParams),
    });
  };

  const exportSelected = () => {
    const dataColumns = compact(map(columns, 'field'));
    const allSelected = chain(selectedRows).values().flatten().value();
    const dataRows = map(allSelected, (row) =>
      mapRowsToReportExport(row, dataColumns),
    );
    exportPDF(user, dataRows);
  };

  const exportSingleRecord = (record) => {
    const dataColumns = compact(map(columns, 'field'));
    const dataRows = map([record], (row) =>
      mapRowsToReportExport(row, dataColumns),
    );
    exportPDF(user, dataRows);
  };

  const exportPDF = (user, dataRows) => {
    const dob = get(user, 'dateOfBirth', '');
    generatePDF({
      tables: [
        {
          rows: dataRows,
          columns: compact(map(columns, 'headerName')),
          yPos: 60,
        },
      ],
      title: 'Lab Records',
      personalDetails: generatePersonalDetails(
        `${user?.firstName} ${user?.lastName}`,
        formatDate(dob),
      ),
      filename: 'labs',
      disclaimer: t('panel.disclaimer'),
    });
  };

  const mapRowsToReportExport = (row, columns: string[]) =>
    chain(row).pick(columns).values().value();

  return (
    <Box sx={styles.root}>
      <Box sx={styles.asideActions}>
        <MobileHeaderComponent hideBackButton />
      </Box>
      <div style={styles.pageTitle}>
        <span>{t('labs.pageTitle')}</span>
      </div>
      <div style={styles.subtitle}>
        <Trans i18nKey="disclaimer">
          The Portal displays information shared with CyncHealth by certain
          providers. If your information appears incomplete, it may be because
          your provider does not participate with CyncHealth. Please see the
          full Terms and Conditions <Link to="/conditions">here</Link>.
        </Trans>
      </div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { mobile: 'column', tablet: 'row' },
          gap: '33px',
          marginBottom: '10px',
        }}
      >
        <LabResults labRecords={newLabRecords} isLoading={isLoading} />

        <AlertBoxComponent content={t('alertContent.testResultsAlert')} />
      </Box>
      <div style={styles.grouping}>
        <div style={styles.sectionTitle}>{t('labs.allTests')}</div>
        <div>
          <div>
            <FilterComponent
              onSubmit={applyFilters}
              searchValue={queryParams.query}
              resetValues={resetValues}
            >
              <FormControlAdapterComponent
                id="dateRange"
                defaultValue={dateRange}
                renderComponent={(field: any, register: any) => (
                  <Box
                    component={TextField}
                    {...field}
                    {...register('dateRange')}
                    select
                    variant="standard"
                    sx={styles.formControl}
                    SelectProps={{
                      value: field.value,
                      onChange: (event: SelectChangeEvent) => {
                        setCustomDateRange(event.target.value === 'custom');
                        field.onChange(event);
                      },
                    }}
                  >
                    {dateOptions.map((option: ISortOption) => (
                      <MenuItem key={option.id} value={option.id}>
                        {t(option.displayName)}
                      </MenuItem>
                    ))}
                  </Box>
                )}
              />
              {isCustomDateRange && (
                <React.Fragment>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      maxDate={new Date()}
                      value={dateRangeStart}
                      onChange={(newValue) => {
                        if (newValue !== null) {
                          setDateRangeStart(newValue);
                        }
                      }}
                      renderInput={(params) => (
                        <Box
                          component={TextField}
                          sx={styles.formControl}
                          variant="standard"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      minDate={dateRangeStart}
                      maxDate={new Date()}
                      value={dateRangeEnd}
                      onChange={(newValue) => {
                        if (newValue !== null) {
                          setDateRangeEnd(newValue);
                        }
                      }}
                      renderInput={(params) => (
                        <Box
                          component={TextField}
                          sx={styles.formControl}
                          variant="standard"
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </React.Fragment>
              )}
              <ChipSelectComponent
                id="facilities"
                placeholder={t('labs.facilitiesSelectPlaceholder')}
                defaultValue={facilities}
                options={facilityOptions}
              />
              <ChipSelectComponent
                id="testType"
                placeholder={t('labs.labTypesSelectPlaceholder')}
                defaultValue={labTypes}
                options={labTypeOptions}
              />
            </FilterComponent>
          </div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { tablet: 'row', mobile: 'column' },
              margin: '20px 0',
            }}
          >
            <Box
              sx={{
                ...styles.actions,
                flexDirection: { mobile: 'column', tablet: 'row' },
              }}
            >
              {selectedRows[page] && selectedRows[page].length > 0 && (
                <Button
                  style={styles.actionButton}
                  title={t('actions.downloadSelected')}
                  aria-label={t('actions.downloadSelected')}
                  onClick={exportSelected}
                >
                  <DownloadIcon />
                  <span>{t('actions.downloadSelected')}</span>
                </Button>
              )}

              {false && (
                <Button
                  style={styles.actionButton}
                  title={t('actions.share')}
                  aria-label={t('actions.share')}
                >
                  <ShareIcon />
                  <span>{t('actions.share')}</span>
                </Button>
              )}
            </Box>
          </Box>
        </div>
        <Paper elevation={3}>
          <div style={{ ...styles.resultsContainer, overflowX: 'auto' }}>
            <SearchResultsDataGridComponent
              loading={isSearching}
              columns={columns}
              rows={data}
              onPageChange={handleChangePage}
              page={page}
              pageSize={rowsPerPage}
              onSelectionChange={handleDataGridSelectionChange}
              selectedRows={selectedRowIds[page]}
              onSortChange={onSortChange}
              sort={compact([sort])}
              rowCount={totalRowCount}
              rowId="id"
              totalPages={totalPages}
            />
          </div>
        </Paper>
      </div>
    </Box>
  );
}
