import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthService from 'core/services/auth.service';
import ProfileService from 'core/services/profile.service';
import { useAuthDispatch } from 'core/contexts/authentication.context';

export const LoginCallbackComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAuthDispatch();
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('code');
  const state = queryParams.get('state');
  const acknowledgements = ProfileService.getAcknowledgementFromStorage();

  useEffect(() => {
    if (code) {
      AuthService.authenticate(dispatch, code, acknowledgements)
        .then(() => {
          ProfileService.clearAcknowledgements();
          history.replace('/home');
        })
        .catch(() => {
          history.replace('/login');
        });
    } else if (state || state == '') {
      //in case of logout, should get state parameter.
      history.replace('/login');
    }
  }, [dispatch]);

  return <div>{t('loginRedirect.redirecting')}...</div>;
};

export default LoginCallbackComponent;
