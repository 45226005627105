import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { chain, get, map } from 'lodash';
import { AxiosResponse } from 'axios';

import MobileHeaderComponent from 'components/MobileHeader/MobileHeader.component';
import LanguageSelectComponent from 'components/LanguageSelect/LanguageSelect.component';
import ContactSettingsComponent from 'components/ContactSettings/ContactSettings.component';
import {
  emailValidationRegex,
  formatPhoneNumber,
  phoneValidationRegex,
} from 'utils/utils';
import NotificationService from 'core/services/notifications.service';
import ProfileService from 'core/services/profile.service';
import { IContact } from 'core/interfaces/contact.interface';
import {
  useAuthDispatch,
  useAuthState,
} from 'core/contexts/authentication.context';
import NotificationComponent from 'components/Notification/Notification.component';

import useStyles from './Settings.container.styles';

export default function SettingsContainer() {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({ mode: 'onChange' });
  const { account } = useAuthState();
  const dispatch = useAuthDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('');
  const [settingsFail, setSettingsFailed] = useState<boolean>(false);
  const [phoneContacts, setPhoneContacts] = useState<IContact[]>([]);
  const [emailContacts, setEmailContacts] = useState<IContact[]>([]);
  const [notification, showNotification] = React.useState({
    isOpen: false,
    message: '',
  });

  const defaultTopics = {
    newHealthRecordOptStatus: false,
    updateContactOptStatus: false,
  };

  useEffect(() => {
    if (account) {
      getUserContactSettings(account._id);
    }
    setLanguage(get(account, 'language', ''));
  }, [account, setLanguage]);

  const getUserContactSettings = (userId: string) => {
    NotificationService.getNotificationPreferences(userId)
      .then(getUserContactSettingsSuccess)
      .catch(getUserContactSettingsFail);
  };

  const getUserContactSettingsSuccess = (
    response: AxiosResponse<IContact[]>,
  ) => {
    const userContacts: IContact[] = response.data;
    const phoneContacts = sortAndFilterByMethod(userContacts, 'SMS');
    const emailContacts = sortAndFilterByMethod(userContacts, 'EMAIL');
    setIsLoading(false);
    setPhoneContacts(phoneContacts);
    setEmailContacts(emailContacts);
    setInitialValues(phoneContacts, emailContacts);
  };

  const setInitialValues = (
    phoneContacts: IContact[],
    emailContacts: IContact[],
  ) => {
    const phone = get(phoneContacts, '[0].topics', defaultTopics);

    const email = get(emailContacts, '[0].topics', defaultTopics);

    reset({
      text: {
        newHealthRecordOptStatus: phone.newHealthRecordOptStatus === 'OptIn',
        updateContactOptStatus: phone.updateContactOptStatus === 'OptIn',
      },
      email: {
        newHealthRecordOptStatus: email.newHealthRecordOptStatus === 'OptIn',
        updateContactOptStatus: email.updateContactOptStatus === 'OptIn',
      },
    });
  };

  const sortAndFilterByMethod = (
    collection: IContact[],
    contactMethod: string,
  ) =>
    chain(collection)
      .filter({ contactMethod })
      .orderBy(['isPrimary'], ['desc'])
      .value();

  const getUserContactSettingsFail = () => {
    showNotification({
      isOpen: true,
      message: t('notifications.contactSettingsFail'),
    });
    setIsLoading(false);
    setSettingsFailed(true);
  };

  interface INotificationPreference {
    id?: string;
    channelType: string;
    isPrimary: boolean;
    address: string;
    newHealthRecordOptStatus: string;
    updateContactOptStatus: string;
  }

  const saveContactMethod = (formValues: any) => {
    setIsLoading(true);
    const notificationPreferencesPayload: INotificationPreference[] = [
      ...generateNotificationPreferences(phoneContacts, formValues.text),
      ...generateNotificationPreferences(emailContacts, formValues.email),
    ];

    if (account) {
      updateNotificationPreferences(
        notificationPreferencesPayload,
        account._id,
        true,
      );
    }
  };

  const updateNotificationPreferences = (
    notificationPreferencesPayload: INotificationPreference[],
    userId: string,
    isEdit: boolean,
  ) => {
    NotificationService.updateNotificationPreferences(
      userId,
      notificationPreferencesPayload,
      isEdit,
    )
      .then(updateNotificationPreferenceSuccess)
      .catch(updateProfileSaveFail);
  };

  const removeContactInfo = (notificationPreference: IContact) => {
    NotificationService.removeNotificationPreferences(notificationPreference.id)
      .then(updateNotificationPreferenceSuccess)
      .catch(updateProfileSaveFail);
  };

  const saveContactInfo = (
    contact: INotificationPreference,
    isEdit: boolean,
  ) => {
    setIsLoading(true);
    if (account) {
      updateNotificationPreferences([contact], account._id, isEdit);
    }
  };

  const generateNotificationPreferences = (
    collection: IContact[],
    values: any,
  ) =>
    map(collection, (contact: IContact) =>
      mapContactToNotificationPreference(contact, values),
    );

  const mapContactToNotificationPreference = (
    contact: IContact,
    values: any,
  ) => ({
    id: contact.id,
    channelType: contact.contactMethod,
    isPrimary: contact.isPrimary,
    address: contact.contactInfo,
    newHealthRecordOptStatus: values.newHealthRecordOptStatus
      ? 'OptIn'
      : 'OptOut',
    updateContactOptStatus: values.updateContactOptStatus ? 'OptIn' : 'OptOut',
  });

  const updateLanguage = (language: string) => {
    ProfileService.updateProfile(
      account,
      {
        language,
      },
      // eslint-disable-next-line
      dispatch
    )
      .then(updateProfileSaveSuccess)
      .catch(updateProfileSaveFail);
  };

  const updateNotificationPreferenceSuccess = () => {
    const userId = get(account, '_id', '');
    // setIsLoading(false);
    getUserContactSettings(userId);
  };

  const updateProfileSaveSuccess = () => {
    const userId = get(account, '_id', '');
    setIsLoading(false);
    ProfileService.getProfile(dispatch, userId);
  };

  const updateProfileSaveFail = () => {
    setIsLoading(false);
    showNotification({
      isOpen: true,
      message: t('notifications.contactSettingsSaveFail'),
    });
  };

  const closeNotification = () => {
    showNotification({ isOpen: false, message: '' });
  };

  const renderCheckbox = (
    name: string,
    label: string,
    defaultValue: boolean,
  ) => (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => {
            return (
              <Checkbox
                {...field}
                inputProps={{ 'aria-label': label }}
                checked={field.value}
                inputRef={field.ref}
              />
            );
          }}
        />
      }
      label={label}
    />
  );

  // Initial values
  const phoneSettings = get(phoneContacts, '[0].topics', defaultTopics);
  const emailSettings = get(emailContacts, '[0].topics', defaultTopics);

  return (
    <Box style={styles.root}>
      <NotificationComponent
        isOpen={notification.isOpen}
        handleClose={closeNotification}
        message={t('notifications.contactSettingsFail')}
      />
      <Box sx={styles.asideActions}>
        <MobileHeaderComponent />
      </Box>
      <Box sx={styles.content}>
        <div style={styles.pageTitle}>
          <span>{t('settings.pageTitle')}</span>
        </div>
        <div
          style={
            settingsFail
              ? { opacity: 0.4, pointerEvents: 'none' }
              : { opacity: 1 }
          }
        >
          <Paper
            elevation={3}
            style={{ ...styles.sectionContainer, flexDirection: 'column' }}
          >
            <form onSubmit={handleSubmit(saveContactMethod)}>
              <div style={styles.section}>
                <Box sx={styles.sectionTitle}>
                  {t('settings.contactMethodSectionTitle')}
                </Box>
                <div>{t('settings.contactMethodRecordChange')}</div>
                {renderCheckbox(
                  'text.newHealthRecordOptStatus',
                  t('settings.text'),
                  phoneSettings.newHealthRecordOptStatus === 'OptIn',
                )}
                {renderCheckbox(
                  'email.newHealthRecordOptStatus',
                  t('settings.email'),
                  emailSettings.newHealthRecordOptStatus === 'OptIn',
                )}
              </div>

              <div>
                <Box sx={styles.sectionTitle}>
                  {t('settings.contactInfoSectionTitle')}
                </Box>
                <div>{t('settings.contactMethodContactInfoChange')}</div>
                <div style={styles.instruction}>
                  {t('settings.contactInfoInstruction')}
                </div>
                {renderCheckbox(
                  'text.updateContactOptStatus',
                  t('settings.text'),
                  phoneSettings.updateContactOptStatus === 'OptIn',
                )}
                {renderCheckbox(
                  'email.updateContactOptStatus',
                  t('settings.email'),
                  emailSettings.updateContactOptStatus === 'OptIn',
                )}
              </div>

              <Button
                disabled={!isDirty || isLoading}
                type="submit"
                style={styles.cta}
                title={t('actions.save')}
                aria-label={t('actions.save')}
                variant="contained"
                size="large"
              >
                {t('actions.save')}
              </Button>
            </form>
          </Paper>
          <Paper
            elevation={3}
            style={{ ...styles.sectionContainer, flexDirection: 'column' }}
          >
            <Box style={styles.section}>
              <Box sx={styles.sectionTitle}>
                {t('settings.contactInfoSectionTitle')}
              </Box>
              <div style={styles.spacer}>
                {t('settings.contactInfoSectionSubtitle')}
              </div>
              <ContactSettingsComponent
                contacts={phoneContacts}
                channelType="SMS"
                sectionTitle={t('settings.phoneLabel')}
                inputLabel={t('settings.phoneLabel')}
                addHandler={saveContactInfo}
                removeHandler={removeContactInfo}
                formatter={formatPhoneNumber}
                validationRegex={phoneValidationRegex}
              />
              <ContactSettingsComponent
                contacts={emailContacts}
                channelType="EMAIL"
                sectionTitle={t('settings.emailLabel')}
                inputLabel={t('settings.emailLabel')}
                addHandler={saveContactInfo}
                removeHandler={removeContactInfo}
                validationRegex={emailValidationRegex}
              />
            </Box>
          </Paper>
        </div>
        <Paper
          elevation={3}
          style={{ ...styles.sectionContainer, flexDirection: 'column' }}
        >
          <div style={styles.language}>
            <LanguageSelectComponent
              value={language}
              onChange={updateLanguage}
            />
          </div>
        </Paper>
      </Box>
    </Box>
  );
}
