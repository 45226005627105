import { Theme } from '@mui/material';

export const styles = (theme: Theme) => ({
  root: {
    minWidth: '220px',
  },
  contact: {
    alignItems: 'center',
    minHeight: '36px',
  },
  contactValues: {
    fontWeight: 500,
  },
  contactInfoContainer: {
    display: 'table',
    tableLayout: 'fixed',
  },
  contactInfoRow: {
    display: 'table-row',
  },
  contactInfoCell: {
    display: { mobile: 'block', tablet: 'table-cell' },
    paddingRight: '15px',
    width: '125px',
  },
  spacer: {
    marginBottom: '20px',
  },
  contactValue: {
    paddingRight: '15px',
    fontWeight: 500,
    display: 'inline-block',
    minWidth: '200px',
  },
  updateButton: {
    color: theme.palette.primary.main,
    '&.Mui-disabled': {
      opacity: 0.4,
    },
  },
  contactFieldContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: '3px',
  },
});

export default styles;
