import apiService from './api.service';
import { IContact } from 'core/interfaces/contact.interface';
import endpointService from './endpoint.service';

interface INotificationPreferencePayload {
  channelType: string;
  isPrimary: boolean;
  address: string;
  newHealthRecordOptStatus: string;
  updateContactOptStatus: string;
}

async function getNotificationPreferences(userId: string) {
  return apiService.get<IContact[]>(
    endpointService.getRoutes().NOTIFICATIONS.PREFERENCES(userId),
  );
}

async function removeNotificationPreferences(notificationPreferenceId: string) {
  return apiService.delete<any>(
    endpointService
      .getRoutes()
      .NOTIFICATIONS.PREFERENCE(notificationPreferenceId),
  );
}

async function updateNotificationPreferences(
  userId: string,
  payload: INotificationPreferencePayload[],
  isEdit: boolean,
) {
  const action = isEdit ? 'put' : 'post';
  return apiService[action]<any>(
    endpointService.getRoutes().NOTIFICATIONS.PREFERENCES(userId),
    payload,
  );
}

export const service = {
  getNotificationPreferences,
  removeNotificationPreferences,
  updateNotificationPreferences,
};

export default service;
