import { createTheme } from '@mui/material/styles';

import palette from './palette';

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

const breakpoints = {
  values: {
    mobile: 0,
    tablet: 640,
    laptop: 1024,
    desktop: 1200,
  },
};

const themeConfig = {
  breakpoints,
  palette,
  typography: {
    fontFamily: ['"Helvetica Neue"', 'Roboto'].join(','),
  },
};

const theme = createTheme(themeConfig);

export default theme;
