import React from 'react';
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridSortItem,
} from '@mui/x-data-grid';
import { noop, get } from 'lodash';
import { LinearProgress, useTheme, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useStyles from './SearchResultsDataGrid.component.styles';

interface IComponentProps {
  page: number;
  onPageChange?: any;
  columns: GridColDef[];
  rows: { [p: string]: any }[];
  rowCount: number | undefined;
  selectedRows?: string[];
  pageSize?: number;
  onSelectionChange?: any;
  onCellClick?: any;
  onSortChange?: any;
  rowId?: string;
  loading?: boolean;
  sort?: GridSortItem[];
  totalPages: number;
}

export const SearchResultsDataGridComponent = ({
  page,
  pageSize = 10,
  columns,
  rows,
  rowCount,
  onSelectionChange = noop,
  onCellClick = noop,
  onPageChange = noop,
  onSortChange = noop,
  rowId = 'id',
  selectedRows = [],
  loading = false,
  sort = [],
  totalPages = 0,
}: IComponentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);

  const getRowId = (r) => get(r, rowId, '');

  const renderLoadingOverlay = () => {
    return (
      <GridOverlay>
        <div style={{ position: 'absolute', top: 0, width: '100%' }}>
          <LinearProgress />
        </div>
      </GridOverlay>
    );
  };

  const renderNoRows = () => (
    <GridOverlay
      style={{ ...styles.root, display: 'flex', flexDirection: 'column' }}
    >
      <div style={styles.empty}>
        <div style={{ ...styles.emptyTitle, textAlign: 'center' }}>
          {t('general.noMatches')}
        </div>
        <div style={styles.label}>{t('general.filterTips')}:</div>
        <ul style={styles.tips}>
          <li>{t('general.checkSpelling')}</li>
          <li>{t('general.tuneFilter')}</li>
          <li>{t('general.similarWords')}</li>
        </ul>
      </div>
    </GridOverlay>
  );

  const defaultLabelDisplayedRows = () =>
    `Page ${totalPages === 0 ? '0' : page + 1} of ${totalPages}`;

  return (
    <DataGrid
      style={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column-reverse',
      }}
      components={{
        NoRowsOverlay: renderNoRows,
        LoadingOverlay: renderLoadingOverlay,
        Pagination: TablePagination,
      }}
      componentsProps={{
        pagination: {
          page,
          onPageChange: onPageChange,
          count: rowCount,
          rowsPerPage: pageSize,
          rowsPerPageOptions: [],
          labelDisplayedRows: defaultLabelDisplayedRows,
          component: 'div',
        },
      }}
      loading={loading}
      rows={rows}
      columns={columns}
      checkboxSelection
      disableSelectionOnClick
      onSelectionModelChange={onSelectionChange}
      onSortModelChange={onSortChange}
      getRowId={getRowId}
      selectionModel={selectedRows}
      sortModel={sort}
      //rowCount={rowCount}
      rowHeight={80}
      onCellClick={onCellClick}
    />
  );
};

export default SearchResultsDataGridComponent;
