import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

interface IComponentProps {
  id: string;
  defaultValue: string[] | string | number | undefined;
  renderComponent: any;
  control?: any;
  register?: any;
  rules?: any;
  setValue?: any;
}

export const FormControlAdapterComponent = ({
  id,
  defaultValue,
  rules,
  control,
  register,
  renderComponent,
  setValue,
}: IComponentProps) => {
  useEffect(() => {
    if (defaultValue) {
      setValue(id, defaultValue);
    }
  }, [defaultValue, setValue]);

  return (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => renderComponent(field, register)}
    />
  );
};

export default FormControlAdapterComponent;
