import { Theme } from '@mui/material';
import Color from 'color';

export const styles = (theme: Theme) => ({
  root: {
    padding: { tablet: '45px', mobile: '15px 15px 45px 15px' },
  },
  asideActions: {
    display: 'none',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'row',
      display: 'flex',
      flex: 'none',
      marginBottom: '23px',
    },
  },
  rowLink: {
    color: Color(theme.palette.primary.main).alpha(1).hsl().toString(),
  },
  pageTitle: {
    fontSize: '2rem',
    marginBottom: '16px',
  },
  sectionTitle: {
    fontSize: '1.3rem',
    marginBottom: '20px',
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
  },
  subtitle: {
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
    marginBottom: '20px',
  },
  actions: {
    display: 'flex',
    justifyContent: { tablet: 'flex-end', mobile: 'space-between' },
    alignItems: 'flex-start',
    flex: 'auto',
    color: theme.palette.primary.main,
  },
  sortSelect: {
    marginLeft: '5px',
    fontSize: '0.875rem',
  },
  sortSelectContainer: {
    '& .MuiFormControlLabel-label': {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
  },
  formControl: {
    width: { tablet: '250px', mobile: '100%' },
  },
  resultsContainer: {
    height: 400,
    width: '100%',
  },
  resources: {
    padding: '24px 20px',
    fontSize: '0.875rem',
    display: 'flex',
    width: { tablet: '520px', mobile: '100%' },
    gap: { tablet: '36px', mobile: '11px' },
  },
  immunizationImg: {
    padding: { tablet: '13px', mobile: '5px' },
    width: { tablet: '200px', mobile: '93px' },
    height: { tablet: '188px', mobile: '93px' },
    display: 'flex',
    margin: { mobile: 'auto 0', tablet: '0' },
  },
  img: {
    width: '100%',
  },
  info: {
    display: { mobile: 'none', tablet: 'flex' },
    justifyContent: 'center',
  },
  details: {
    display: 'flex',
    fontSize: '1.25rem',
    fontWeight: '700',
  },
});

export default styles;
