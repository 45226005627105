import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import englishTranslation from './en/translation.json';
import spanishTranslation from './es/translation.json';

export const resources = {
  en: {
    translation: englishTranslation,
  },
  es: {
    translation: spanishTranslation,
  },
} as const;

const options = {
  order: ['localStorage', 'querystring', 'navigator'],
  lookupQuerystring: 'lng',
  htmlTag: document.documentElement,
};

i18n.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en',
  resources,
  detection: options,
  debug: false,
});

export default i18n;
