import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Paper,
  Skeleton,
  useTheme,
} from '@mui/material';
import { get, first } from 'lodash';
import {
  Close as CloseIcon,
  Download as DownloadIcon,
  MailOutline as ShareIcon,
} from '@mui/icons-material';
// import { AxiosResponse } from 'axios';
import QrCode, { QRCodeSegment } from 'qrcode';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// import utils from 'utils/utils';
// import SearchService from 'core/services/search.service';
// import { ICovidCard } from 'core/interfaces/covid-card.interface';
import { useAuthState } from 'core/contexts/authentication.context';

import useStyles from './CovidCardDetails.component.styles';

const MAX_SINGLE_JWS_SIZE = 1195;
const MAX_CHUNK_SIZE = 1191;
const SMALLEST_B64_CHAR_CODE = 45;

export const CovidCardDetails = ({
  title = '',
  hideTitleOnMobile = false,
  details,
}) => {
  const { user } = useAuthState();
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [card, setCard] = useState('');
  const [qrStrings, setQrString] = useState<string[]>([]);
  const [isOpen, setState] = React.useState<boolean>(false);

  const bodyTag: HTMLElement = document.body;

  //   console.log(details);
  useEffect(() => {
    if (user) {
      getCovidCardSuccess(details);
      console.log(details);
    }
  }, [user, setCard]);

  useEffect(() => {
    if (card) {
      //  setManufacturers(getManufacturers(card));
      generateQr(card);
    }
  }, [card]);

  const toggleDrawer = (open: boolean) => {
    const action = open ? 'add' : 'remove';
    bodyTag.classList[action]('lockScroll');
    setState(open);
  };

  const onClose = () => {
    toggleDrawer(false);
  };

  const getCovidCardSuccess = (details) => {
    setIsLoading(true);
    setCard(details);
    setIsLoading(false);
  };

  //   const getManufacturers = (card) => {
  //     const manufacturers = chain(card.vaccineName)
  //       .map('manufacturer')
  //       .compact()
  //       .uniq()
  //       .value();
  //     return manufacturers.join(', ');
  //   };

  //   const getCovidCardFail = () => {
  //     setManufacturers('');
  //     setIsLoading(false);
  //     // TODO: Error
  //   };

  const generateQr = async (card) => {
    if (!card.healthCard.verifiableCredential) {
      return '';
    }

    const justTheToken: string = card.healthCard.verifiableCredential;

    const jwsChunks = splitJwsIntoChunks(justTheToken);
    const qrSet = jwsChunks.map((c, i, chunks) =>
      toNumericQr(c, i, chunks.length),
    );

    //Convert numeric QR data into array of QR SVG data
    const exampleQrCodes: string[] = await Promise.all(
      qrSet.map(
        (qrSegments): Promise<string> =>
          new Promise((resolve, reject) =>
            QrCode.toString(
              qrSegments,
              { type: 'svg', errorCorrectionLevel: 'low' },
              function (err: any, result: string) {
                if (err) return reject(err);
                resolve(result as string);
              },
            ),
          ),
      ),
    );

    setQrString(exampleQrCodes);
  };

  const splitJwsIntoChunks = (jws: string): string[] => {
    if (jws.length <= MAX_SINGLE_JWS_SIZE) {
      return [jws];
    }

    // Try to split the chunks into roughly equal sizes.
    const chunkCount = Math.ceil(jws.length / MAX_CHUNK_SIZE);
    const chunkSize = Math.ceil(jws.length / chunkCount);
    const chunks = jws.match(new RegExp(`.{1,${chunkSize}}`, 'g'));
    return chunks || [];
  };

  const toNumericQr = (
    jws: string,
    chunkIndex: number,
    totalChunks: number,
  ): QRCodeSegment[] => [
    {
      data:
        'shc:/' + (totalChunks > 1 ? `${chunkIndex + 1}/${totalChunks}/` : ``),
      mode: 'byte',
    },
    {
      data: jws
        .split('')
        .map((c) => c.charCodeAt(0) - SMALLEST_B64_CHAR_CODE)
        .flatMap((c) => [Math.floor(c / 10), c % 10])
        .join(''),
      mode: 'numeric',
    },
  ];

  const saveToDevice = () => {
    if (!isMobile) {
      downloadQRimage();
    } else {
      const credential = get(card, 'healthCard', '');
      console.log(credential);
      saveHealthCardToComputer();
    }
  };

  const saveHealthCardToComputer = () => {
    const credential = get(card, 'healthCard.verifiableCredential', '');
    const jwsToken = {
      verifiableCredential: [credential],
    };
    const file = JSON.stringify(jwsToken);
    const blob = new Blob([file]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'COVID-19_Vaccination_Card.smart-health-card';
    link.href = fileDownloadUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadQRimage = async () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const DOMURL = window.URL || window.webkitURL || window;

    const img = new Image();
    const svgBlob = new Blob([qrStrings[0]], {
      type: 'image/svg+xml;charset=utf-8',
    });
    const url = DOMURL.createObjectURL(svgBlob);

    img.onload = function () {
      ctx?.drawImage(img, 0, 0);
      DOMURL.revokeObjectURL(url);

      const imgURI: string = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');

      triggerDownload(imgURI);
    };

    img.src = url;
  };

  const triggerDownload = (imgURI: string) => {
    const evt = new MouseEvent('click', {
      view: window,
      bubbles: false,
      cancelable: true,
    });

    const a = document.createElement('a');
    a.setAttribute('download', 'qr.png');
    a.setAttribute('href', imgURI);
    a.setAttribute('target', '_blank');

    a.dispatchEvent(evt);
  };

  const renderLoading = () => (
    <Box sx={{ ...styles.loadingContainer, flexDirection: 'column' }}>
      <Box
        sx={{
          ...styles.sectionTitle,
          display: {
            tablet: 'flex',
            mobile: hideTitleOnMobile ? 'none' : 'flex',
          },
        }}
      >
        <Skeleton width={230} height={33} />
      </Box>
      <Box component={Paper} elevation={3} sx={styles.recordCardPaper}>
        <div style={{ width: '100%' }}>
          <Box
            sx={{
              flexDirection: 'row',
              width: '90%',
              height: '100px',
              marginLeft: '15px',
            }}
          >
            <div style={{ width: '100%' }}>
              <Skeleton width="100%" height={25} />
            </div>

            <div style={{ width: '100%' }}>
              <Skeleton width="65%" />
            </div>
          </Box>
        </div>
      </Box>
    </Box>
  );

  const renderQRCode = (qrString, qrIndex = 0) => (
    <div key={qrIndex}>
      <img src={`data:image/svg+xml;utf8,${encodeURIComponent(qrString)}`} />
    </div>
  );

  const renderQRSection = () => (
    <Box sx={{ ...styles.qr, textAlign: 'center' }}>
      <div>
        {qrStrings.length > 1 ? (
          <Carousel
            showThumbs={false}
            ariaLabel={t('covidCard.carousel')}
            emulateTouch
          >
            {qrStrings.map((qrString, index: number) =>
              renderQRCode(qrString, index),
            )}
          </Carousel>
        ) : (
          renderQRCode(first(qrStrings))
        )}
      </div>
    </Box>
  );

  const renderInformation = (card) => (
    <Box
      sx={{
        ...styles.details,
        flexDirection: 'column',
        justifyContent: { mobile: 'center', tablet: 'space-between' },
      }}
    >
      <Box sx={styles.mobileInfo}>
        <Box sx={styles.cardTitle}>{t('covidCard.covidCardTitle')}</Box>
        <div>
          {t('covidCard.vaccineName')} : {card?.vaccineName}
        </div>
        <br></br>
        <Box>
          <div>
            {t('covidCard.dose')} : {card?.manufacturer}
            {', '}
            {card?.soldOn}{' '}
          </div>
        </Box>
      </Box>
      <Box sx={{ ...styles.info, flexDirection: 'column' }}>
        <Box sx={styles.cardBoldText}>{t('covidCard.covidCardTitle')}</Box>

        <Box sx={styles.userInfo}>
          <span style={{ paddingRight: '10px' }}>
            {t('contact.fullNameLabel')} : {user?.firstName} {user?.lastName}
          </span>

          <div>
            {' '}
            {t('profile.dateOfBirth')} : {user?.dateOfBirth}
          </div>
          <br></br>
          <div>
            {' '}
            {t('covidCard.vaccineName')} : {card?.vaccineName}
          </div>
        </Box>
        <Box sx={styles.cardBoldText}>
          <div>
            {t('covidCard.dose')} : {card?.manufacturer}
            {', '}
            {card?.soldOn}{' '}
          </div>
        </Box>
      </Box>
      <Box
        sx={{
          ...styles.actions,
          flexDirection: { mobile: 'column', tablet: 'row', gap: '15px' },
        }}
      >
        <Button
          style={styles.actionButton}
          title={t('actions.download')}
          aria-label={t('actions.download')}
          //onClick={saveHealthCardToComputer}
          onClick={saveToDevice}
        >
          <DownloadIcon style={styles.icon} />
          <span>{t('actions.download')}</span>
        </Button>

        {false && (
          <Button
            style={styles.actionButton}
            title={t('actions.share')}
            aria-label={t('actions.share')}
          >
            <ShareIcon style={styles.icon} />
            <span>{t('actions.share')}</span>
          </Button>
        )}
      </Box>
    </Box>
  );

  const credential = get(card, 'healthCard.verifiableCredential', '');

  return (
    <React.Fragment>
      {isLoading && renderLoading()}
      {!isLoading && card && credential && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {title && (
            <Box
              sx={{
                ...styles.sectionTitle,
                display: {
                  tablet: 'flex',
                  mobile: hideTitleOnMobile ? 'none' : 'flex',
                },
              }}
            >
              {title}
            </Box>
          )}
          <div style={{ display: 'flex' }}>
            <Box
              component={Paper}
              elevation={2}
              sx={{
                ...styles.root,
                display: { mobile: 'none', tablet: 'flex' },
              }}
            >
              {credential && renderQRSection()}
              {renderInformation(card)}
            </Box>
            <Box
              onClick={() => toggleDrawer(true)}
              sx={{
                display: { mobile: 'flex', tablet: 'none' },
                width: '100%',
              }}
            >
              <Box component={Paper} elevation={3} sx={styles.root}>
                {credential && renderQRSection()}
                {renderInformation(card)}
              </Box>
            </Box>
          </div>
          <Drawer
            variant="temporary"
            anchor="bottom"
            hideBackdrop
            elevation={0}
            onClose={onClose}
            sx={{
              width: '100%',
              bottom: '56px',
              '.MuiDrawer-paper': {
                color: theme.palette.grayScale.light,
                backgroundColor: theme.palette.primary.dark,
                position: 'fixed',
                top: '0px',
                bottom: '56px',
                left: '0px',
                right: '0px',
                overflowY: 'auto',
              },
            }}
            open={isOpen}
          >
            <div style={styles.covidCardMobile}>
              {credential && renderQRSection()}
              <div style={{ ...styles.drawerContent, textAlign: 'center' }}>
                <Box sx={styles.name}>
                  {`${user?.firstName} ${user?.lastName}`}
                </Box>
                <Box>
                  {t('profile.dateOfBirth')} : {user?.dateOfBirth}
                </Box>
                <Box sx={{ ...styles.doseInfo, flexDirection: 'column' }}>
                  {renderInformation(card)}
                </Box>
              </div>
              <IconButton
                style={{ position: 'absolute', ...styles.closeButton }}
                onClick={() => toggleDrawer(false)}
              >
                <CloseIcon />
              </IconButton>
              <Button
                style={styles.actionButtonDrawer}
                title={t('actions.download')}
                aria-label={t('actions.download')}
                //onClick={saveHealthCardToComputer}
                onClick={saveToDevice}
              >
                <DownloadIcon style={styles.icon} />
                <span>{t('actions.download')}</span>
              </Button>
            </div>
          </Drawer>
        </div>
      )}
    </React.Fragment>
  );
};

export default CovidCardDetails;
