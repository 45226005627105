import React from 'react';
import { Dialog, DialogContent, Button, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import Color from 'color';

interface ISessionModalProps {
  openDialog: boolean;
  closeHandle: any;
  signoutHandle: any;
}

const SessionModal = ({
  openDialog,
  closeHandle,
  signoutHandle,
}: ISessionModalProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={openDialog}
      onClose={closeHandle}
      PaperProps={{ sx: { minHeight: '40vh', maxHeight: '40vh' } }}
      disableEscapeKeyDown
    >
      <DialogContent sx={{ width: '450px' }}>
        <Box>
          <Box>
            <h2 style={{ textAlign: 'center' }}>
              {t('sessionModal.modTitle')}
            </h2>
            <p>{t('sessionModal.modContent')}</p>
          </Box>
          <Box>
            <Button
              onClick={closeHandle}
              style={{
                display: 'block',
                backgroundColor: Color(theme.palette.primary.main)
                  .hsl()
                  .toString(),
                color: Color(theme.palette.grayScale.light).hsl().toString(),
                width: '100%',
              }}
            >
              {t('sessionModal.modButtonStay')}
            </Button>
            <Button
              onClick={signoutHandle}
              style={{
                display: 'block',
                backgroundColor: Color(theme.palette.grayScale.gradient600)
                  .hsl()
                  .toString(),
                color: Color(theme.palette.grayScale.light).hsl().toString(),
                marginTop: '10px',
                width: '100%',
              }}
            >
              {t('sessionModal.modButtonLogout')}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SessionModal;
