import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Chip,
  MenuItem,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { includes, noop, startCase, toLower } from 'lodash';
import { Controller } from 'react-hook-form';

import { ISortOption } from 'core/interfaces/sort-option.interface';

import useStyles from './ChipSelect.component.styles';

interface IComponentProps {
  id: string;
  label?: string;
  defaultValue: string[];
  variant?: any;
  options: ISortOption[];
  control?: any;
  register?: any;
  setValue?: any;
  rules?: any;
  placeholder?: string;
}

export const ChipSelectComponent = ({
  id,
  defaultValue,
  label,
  placeholder,
  variant = 'standard',
  options,
  rules,
  control,
  register,
  setValue,
}: IComponentProps) => {
  const { t } = useTranslation();
  const styles = useStyles();

  // const { control, register } = form;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    if (defaultValue && setValue) {
      setValue(id, defaultValue);
    }
  }, [defaultValue, setValue]);

  const isActive = (id: string, currentValue: string[]) => {
    return includes(currentValue, id);
  };

  const renderValue = (selected: string[] = []) =>
    selected.join(', ') || placeholder;

  const renderOption = (option: ISortOption, currentValues: string[]) => (
    <Box component={MenuItem} key={option.id} value={option.id}>
      <Chip
        color={isActive(option.id, currentValues) ? 'primary' : 'default'}
        label={startCase(toLower(t(option.displayName)))}
      />
    </Box>
  );

  const onChange = (event: SelectChangeEvent, formOnChange: any) => {
    const {
      target: { value },
    } = event;
    formOnChange(value);
  };

  const renderSelect = (field: any = {}, register = noop) => {
    return (
      <Box
        component={TextField}
        select
        {...field}
        {...register(id)}
        sx={styles.select}
        variant={variant}
        id={id}
        SelectProps={{
          multiple: true,
          value: field.value,
          displayEmpty: true,
          renderValue: renderValue,
          MenuProps: MenuProps,
          onChange: (event: SelectChangeEvent) =>
            onChange(event, field.onChange),
        }}
        label={label}
      >
        {options.map((option: ISortOption) =>
          renderOption(option, field.value),
        )}
      </Box>
    );
  };

  return control ? (
    <Controller
      name={id}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => renderSelect(field, register)}
    />
  ) : (
    renderSelect()
  );
};

export default ChipSelectComponent;
