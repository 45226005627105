import React from 'react';
import {
  InfoOutlined as InfoIcon,
  Help as HelpIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  Popover,
  useTheme,
} from '@mui/material';
import loginImg from 'assets/images/login.png';
import loginImageSpanish from 'assets/images/loginImageSpanish.png';
import logo from 'assets/images/logo_mycynchealth_color.png';
import LanguageSelectComponent from 'components/LanguageSelect/LanguageSelect.component';
import EndpointService from 'core/services/endpoint.service';
import { useHistory, NavLink } from 'react-router-dom';
import IdmeFaq from '../IdmeFAQ/idmeFaq.component';
import useStyles from './Login.component.styles';
import 'styles/common.scss';

const LoginComponent = () => {
  const drawerWidth = '35%';

  const { t, i18n } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const styles = useStyles(theme, {
    drawerWidth,
  });

  const [infoTooltip, setInfoTooltip] = React.useState(null);

  const handleInfoClick = (event) => {
    setInfoTooltip(event.currentTarget);
  };

  const handleInfoClose = () => {
    setInfoTooltip(null);
  };

  const open = Boolean(infoTooltip);
  const id = open ? 'info-tooltip' : undefined;

  const navigateToSupport = () => {
    history.push('/help');
  };

  const renderAside = () => (
    <React.Fragment>
      <Box sx={styles.imgContainer}>
        <img
          alt={t('loginImageAlt')}
          style={styles.loginImg}
          src={i18n && i18n.language === 'es' ? loginImageSpanish : loginImg}
        />
      </Box>
      <Box sx={styles.asideActions}>
        <div>
          <Button
            title={t('login.needHelp')}
            aria-label={t('login.needHelp')}
            style={styles.helpButton}
            onClick={navigateToSupport}
          >
            <HelpIcon fontSize="small" style={styles.helpIcon} />
            <span>{t('login.needHelp')}</span>
          </Button>
        </div>
      </Box>
    </React.Fragment>
  );

  const asideContent = renderAside();

  return (
    <Box sx={styles.root}>
      <CssBaseline />
      <Drawer
        sx={styles.asideMobile}
        variant="permanent"
        anchor="top"
        open={true}
        elevation={0}
        ModalProps={{
          hideBackdrop: true,
          keepMounted: true,
        }}
      >
        {asideContent}
      </Drawer>
      <Drawer sx={styles.aside} variant="permanent" anchor="left">
        {asideContent}
      </Drawer>
      <Box component="main" sx={{ ...styles.content, flexDirection: 'column' }}>
        <Box sx={styles.language}>
          <LanguageSelectComponent />
        </Box>
        <div>
          <div>
            <Box sx={styles.logoContainer}>
              <img
                alt={t('navigation.altLogo')}
                src={logo}
                style={styles.logo}
              />
            </Box>
          </div>
          <div className="subtitle" style={styles.subtitle}>
            {t('login.subtitle')}
          </div>
        </div>
        <div style={styles.loginSection}>
          <div className="title" style={styles.title}>
            {t('login.existingUser')}
          </div>
          <a
            aria-label={t('actions.login')}
            href={EndpointService.getRoutes().AUTH.IDME('signin')}
          >
            <img
              style={styles.cta}
              alt={t('actions.login')}
              src="https://developers.id.me/assets/buttons/sign-in-870fd683722acd6140eb5524279b9752629031c8c87502356e7d7952af343043.svg"
            />
          </a>
        </div>
        <Divider />
        <div style={styles.registerSection}>
          <div className="title" style={styles.title}>
            {t('login.noAccount')}
          </div>
          <NavLink to="/register" aria-label={t('login.createAccount')}>
            <img
              style={styles.cta}
              alt={t('login.createAccount')}
              src="https://developers.id.me/assets/buttons/create-idme-4dcc8238deaf8adb3cc0934b157f8cca3e10e827a02d4ea2efb76d8c43a05b79.svg"
            />
          </NavLink>
          <div className="subtitle" style={styles.hintText}>
            {t('login.hint')}
          </div>
        </div>
        <Box component="div" sx={styles.instruction}>
          {t('login.instruction')}
          <Button
            aria-describedby={id}
            onClick={handleInfoClick}
            style={styles.subtitle.actionButton}
            title={t('login.instructionAlt')}
            aria-label={t('login.instructionAlt')}
          >
            <InfoIcon />
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={infoTooltip}
            onClose={handleInfoClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box sx={styles.popup}>
              <div style={styles.popupTitle}>{t('login.infoPopup.title')}</div>
              <p>
                <span>{t('login.infoPopup.subtitle1')} </span>
                <span style={styles.emphasis}>
                  {t('login.infoPopup.subtitle2')}
                </span>
                <span>{t('login.infoPopup.subtitle3')}</span>
              </p>
              <p>
                <span style={styles.optionLabel}>
                  {t('login.infoPopup.option', { number: 1 })}
                </span>
                <span>
                  {t('login.infoPopup.photoOf')}{' '}
                  <span style={styles.emphasis}>
                    {t('login.infoPopup.license')}
                  </span>
                </span>
              </p>
              <p>
                <span style={styles.optionLabel}>
                  {t('login.infoPopup.option', { number: 2 })}
                </span>
                <span>
                  {t('login.infoPopup.photoOf')}{' '}
                  <span style={styles.emphasis}>
                    {t('login.infoPopup.passport')}
                  </span>
                </span>
              </p>
              <p>
                <span style={styles.optionLabel}>
                  {t('login.infoPopup.option', { number: 3 })}
                </span>
                <span>
                  {t('login.infoPopup.photoOf')}{' '}
                  <span style={styles.emphasis}>
                    {t('login.infoPopup.passportCard')}
                  </span>
                </span>
              </p>
            </Box>
          </Popover>
        </Box>
        <Divider sx={{ ...styles.registerSection, marginTop: '54px' }} />
        <Box className="faqContainer">
          <IdmeFaq />
        </Box>
      </Box>
    </Box>
  );
};
export default LoginComponent;
