import { Theme } from '@mui/material';
import Color from 'color';

export const styles = (theme: Theme) => ({
  root: {
    padding: { tablet: '45px', mobile: '15px 15px 45px 15px' },
  },
  asideActions: {
    display: 'none',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'row',
      display: 'flex',
      flex: 'none',
      marginBottom: '23px',
    },
  },
  breadcrumb: {
    paddingBottom: '30px',
  },
  pageTitle: {
    fontSize: '2.025rem',
    lineHeight: '41px',
    marginBottom: '40px',
    maxWidth: '80%',
  },
  sectionTitle: {
    fontSize: '1.5rem',
    marginBottom: '20px',
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
  },
  subtitle: {
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
    marginBottom: '20px',
  },
  grouping: {
    marginBottom: '65px',
  },
  chartGrouping: {
    marginBottom: '30px',
  },
  toolTip: {
    backgroundColor: theme.palette.grayScale.light,
    boxShadow: '5px 5px 4px 1px rgba(0,0,0,0.24)',
    padding: '10px',
  },
});

export default styles;
