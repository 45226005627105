import React from 'react';
import { useTheme } from '@mui/material';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import Color from 'color';
import './ResultsAreaChart.component.styles.css';

interface AreaChartProps {
  CustomTooltip?: any;
  CustomDot: any;
  data: any[] | undefined;
  xAxisDataKey: string;
  yAxisDataKey?: string;
  xAxisTickFormatter?: any;
  height?: number;
  axisColor?: string;
  areaColor?: string;
  strokeWidth?: number;
}

const ResultsAreaChart = ({
  data = [],
  CustomTooltip,
  CustomDot,
  yAxisDataKey = 'value',
  xAxisDataKey,
  xAxisTickFormatter = (v) => v,
  height = 300,
  strokeWidth = 1,
  axisColor,
  areaColor,
}: AreaChartProps) => {
  const theme = useTheme();

  if (!areaColor) {
    areaColor = Color(theme.palette.primary.main).hsl().alpha(0.1).toString();
  }

  return (
    <ResponsiveContainer width="100%" height={height}>
      <AreaChart
        data={data}
        margin={{ top: 20, left: 20, right: 20, bottom: 20 }}
      >
        <CartesianGrid horizontal={false} vertical={false} />
        <XAxis
          dataKey={xAxisDataKey}
          interval="preserveStartEnd"
          dy={20}
          tickLine={false}
          tickFormatter={xAxisTickFormatter}
        />
        <YAxis hide domain={[0, 'dataMax + 20']} />
        {CustomTooltip && (
          <Tooltip content={<CustomTooltip active payload label />} />
        )}
        <Area
          type="monotone"
          dataKey={yAxisDataKey}
          strokeWidth={strokeWidth}
          stroke={axisColor || theme.palette.primary.main}
          fill={areaColor}
          legendType="circle"
          dot={CustomDot}
          activeDot={CustomDot}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default ResultsAreaChart;
