import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Button, Paper, Skeleton, useTheme } from '@mui/material';
import {
  EmailOutlined as EmailIcon,
  Phone as PhoneIcon,
} from '@mui/icons-material';
import { get, find } from 'lodash';
import { AxiosResponse } from 'axios';

import MobileHeaderComponent from 'components/MobileHeader/MobileHeader.component';
import { useAuthState } from 'core/contexts/authentication.context';
import ProfileService from 'core/services/profile.service';
import { formatDate, formatPhoneNumber } from 'utils/utils';
import { IProfile } from 'core/interfaces/profile.interface';
import NotificationComponent from 'components/Notification/Notification.component';

import useStyles from './Profile.container.styles';

export default function ProfileContainer() {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);

  const { account } = useAuthState();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [profiles, setProfiles] = useState<IProfile[]>([]);
  const [hasFailed, setHasFailed] = useState<boolean>(false);

  useEffect(() => {
    if (account) {
      getProfiles(account);
    }
  }, [account]);

  const getProfiles = (account: IProfile) => {
    setIsLoading(true);
    setHasFailed(false);
    const userId = get(account, '_id', '');
    ProfileService.getProfiles(userId)
      .then(getProfilesSuccess)
      .catch(getProfilesFail);
  };

  const getProfilesSuccess = (response: AxiosResponse<IProfile[]>) => {
    setProfiles(response.data);
    setIsLoading(false);
  };

  const getProfilesFail = () => {
    setIsLoading(false);
    // TODO: HANDLE ERROR
    setHasFailed(true);
  };

  const closeNotification = () => {
    setHasFailed(false);
  };

  const renderLoadingImage = () => (
    <Paper elevation={3}>
      <Box sx={{ ...styles.panelContainer, flexDirection: 'row' }}>
        <Box sx={styles.avatar}>
          <Skeleton variant="circular" width={135} height={135} />
        </Box>
        <div style={{ width: '50%' }}>
          <Box
            sx={{
              ...styles.infoPrimaryRow,
              flexDirection: 'row',
              width: '50%',
            }}
          >
            <Box component="span" sx={{ ...styles.name, width: '100%' }}>
              <Skeleton width="100%" />
            </Box>
            <Box
              sx={{
                ...styles.state,
                flexDirection: 'column',
                width: '50%',
              }}
            >
              <span style={styles.value}>
                <Skeleton width="100%" />
              </span>
            </Box>
          </Box>
          <Box
            sx={{
              ...styles.infoPrimaryRow,
              flexDirection: 'row',
              width: '50%',
            }}
          >
            <div style={{ ...styles.personalInfo, width: '100%' }}>
              <Skeleton width="100%" />
            </div>
            <div style={{ ...styles.personalInfo, width: '100%' }}>
              <Skeleton width="100%" />
            </div>
          </Box>
          <div style={styles.infoContainer}>
            <Box sx={{ ...styles.infoRow, flexDirection: 'row' }}>
              <Skeleton width="100%" />
            </Box>
            <Box sx={{ ...styles.infoRow, flexDirection: 'row' }}>
              <Skeleton width="100%" />
            </Box>
            <Box sx={{ ...styles.infoRow, flexDirection: 'row' }}>
              <Skeleton width="100%" />
            </Box>
            <Box sx={{ ...styles.infoRow, flexDirection: 'row' }}>
              <Skeleton width="100%" />
            </Box>
          </div>
        </div>
      </Box>
    </Paper>
  );

  const renderProfile = (user: IProfile, index: number) => {
    const context = find(account?.contexts, { profileId: user?._id });
    const contextRelation = get(context, 'relationship');
    const isPrimary = contextRelation === 'primary';
    return (
      <Paper key={index} elevation={3} style={styles.panelPaper}>
        {isPrimary && (
          <Box sx={{ ...styles.panelContainer, flexDirection: 'row' }}>
            <Box sx={styles.avatar}>
              <Avatar
                sx={isPrimary ? styles.avatarIcon : styles.avatarIconDependent}
                alt={`${user?.firstName} ${user?.lastName}`}
              >
                {user?.firstName?.charAt(0)} {user?.lastName?.charAt(0)}
              </Avatar>
            </Box>

            <div>
              <Box
                key={`role_${index}`}
                sx={{
                  ...styles.infoPrimaryRow,
                  flexDirection: { mobile: 'column', tablet: 'row' },
                }}
              >
                <Box component="span" sx={styles.name}>
                  {`${user?.firstName} ${user?.lastName}`}
                </Box>
                {/* <Box sx={{ ...styles.state, flexDirection: 'column' }}>
                  <span style={styles.value}>
                    {t(`profile.${isPrimary ? 'primaryUser' : 'dependent'}`)}
                  </span>
                </Box> */}
              </Box>

              <Box
                key={`contact_info_${index}`}
                sx={{
                  ...styles.infoPrimaryRow,
                  flexDirection: { mobile: 'column', tablet: 'row' },
                }}
              >
                {user?.email && (
                  <div style={styles.personalInfo}>
                    <EmailIcon fontSize="small" style={styles.icon} />
                    <span>{user?.email}</span>
                  </div>
                )}
                {user?.phone && (
                  <div style={styles.personalInfo}>
                    <PhoneIcon fontSize="small" style={styles.icon} />
                    <span>{formatPhoneNumber(user?.phone)}</span>
                  </div>
                )}
              </Box>

              <div style={styles.infoContainer}>
                <Box sx={{ ...styles.infoRow, flexDirection: 'row' }}>
                  <div style={styles.label}>{t('profile.dateOfBirth')}:</div>
                  <div style={styles.value}>
                    {formatDate(user?.dateOfBirth)}
                  </div>
                </Box>
                {isPrimary && (
                  <Box sx={{ ...styles.infoRow, flexDirection: 'row' }}>
                    <div style={styles.label}>{t('profile.address')}:</div>
                    <div
                      style={styles.value}
                    >{`${user?.address.street}, ${user?.address.city}, ${user?.address.state} ${user?.address.zip}`}</div>
                  </Box>
                )}
              </div>

              <a
                style={styles.link}
                aria-label={t('profile.editButtonText')}
                href="https://account.id.me/"
                target="_blank"
                rel="noreferrer"
              >
                <Box sx={styles.actionContainer}>
                  <Button
                    title={t('profile.editButtonText')}
                    aria-label={t('profile.editButtonText')}
                    variant="outlined"
                    size="large"
                    style={styles.cta}
                  >
                    <span>{t('profile.editButtonText')}</span>
                  </Button>
                </Box>
              </a>
            </div>
          </Box>
        )}
      </Paper>
    );
  };

  return (
    <React.Fragment>
      <NotificationComponent
        isOpen={hasFailed}
        handleClose={closeNotification}
        message={t('notifications.profileFail')}
      />
      <Box sx={styles.root}>
        <Box sx={styles.asideActions}>
          <MobileHeaderComponent />
        </Box>
        <div style={styles.pageTitle}>
          <span>{t('profile.pageTitle')}</span>
        </div>
        <div style={styles.sectionTitle}>
          <span>{t('profile.basicInformation')}</span>
        </div>
        <div style={styles.instruction}>
          <span>{t('profile.instruction')}</span>
        </div>
        {(!account || isLoading) && renderLoadingImage()}
        {profiles && profiles.map(renderProfile)}
      </Box>
    </React.Fragment>
  );
}
