import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const idmeFAQ = () => {
  const { t } = useTranslation();
  return (
    <Box className="idmeFAQ_wrapper">
      <h3>{t('login.idmeFAQ.faq')}</h3>
      <Box>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="idmefaq1-content"
            id="idmefaq1-header"
          >
            <Typography>{t('login.idmeFAQ.q1')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <Trans i18nKey="login.idmeFAQ.a1">
                To verify your identity with ID.me, you&apos;ll need to provide
                a photo of an identity document such as a driver&apos;s license,
                state ID, or passport. You&apos;ll also need to take a selfie
                with a smartphone or a computer with a webcam. If you need help
                verifying your identity or to submit a support ticket, you can
                visit the <Link to="imfaq">ID.me Help Site</Link>.
              </Trans>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="idmefaq3-content"
            id="idmefaq3-header"
          >
            <Typography>{t('login.idmeFAQ.q3')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{t('login.idmeFAQ.a3')}</Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default idmeFAQ;
