import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  CssBaseline,
  Paper,
  Drawer,
  Skeleton,
  useTheme,
} from '@mui/material';
import Button from '@mui/material/Button';
import { ArrowBack as ArrowLeftIcon } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import TermsService from 'core/services/conditions.service';
import MobileNavigationComponent from 'components/MobileNavigation/MobileNavigation.component';
import { useAuthState } from 'core/contexts/authentication.context';
import MediaQuery from 'react-responsive';
import htmlParser from 'html-react-parser';
import logoImg from 'assets/images/logo_white.png';
import { Help as HelpIcon } from '@mui/icons-material';
import useStyles from './Support.container.styles';
import 'styles/common.scss';
import { ICondition } from 'core/interfaces/condition.interface';
import LanguageSelectComponent from 'components/LanguageSelect/LanguageSelect.component';

export const handleConditionsHtml = (contentString: string) => {
  const outputString: string = contentString.replaceAll('\n', '<br/>');
  return htmlParser(outputString);
};

export default function SupportTermContainer() {
  const drawerWidth = '30%';
  const [terms, setTerms] = useState<any>(null);
  const { t, i18n } = useTranslation();
  const { user } = useAuthState();
  const history = useHistory();
  const theme = useTheme();
  const consentScroller = useRef(null);
  const styles = useStyles(theme, {
    drawerWidth,
  });

  useEffect(() => {
    getTermsAndConditions();
  }, [i18n.language]);

  const getTermsAndConditions = () => {
    TermsService.getTermsAndConditions({ language: i18n.language })
      .then(getTermsAndConditionsSuccess)
      .catch(getTermsAndConditionsFail);
  };

  const getTermsAndConditionsSuccess = (
    response: AxiosResponse<ICondition[]>,
  ) => {
    const tAndC = response.data.map((e) => e.terms_and_conditions);
    setTerms(tAndC[0]);
    console.log(tAndC);
  };

  const getTermsAndConditionsFail = () => {
    // TODO: Handle with Snackbar
    console.log('Terms and Conditions are not loading..');
  };

  const renderAside = () => (
    <React.Fragment>
      <Box sx={styles.imgContainer}>
        <img alt={t('navigation.altLogo')} style={styles.logo} src={logoImg} />
      </Box>
      <Box sx={{ ...styles.asideContent, flexDirection: 'column' }}>
        <div style={styles.title}>{t('conditions.title')}</div>
      </Box>
      <Box sx={{ ...styles.asideActions, marginLeft: '20px' }}>
        <div>
          <Button
            title={t('login.needHelp')}
            aria-label={t('login.needHelp')}
            style={styles.helpButton}
            onClick={() => history.push('/support')}
          >
            <HelpIcon fontSize="small" style={styles.helpIcon} />
            <span>{t('login.needHelp')}</span>
          </Button>
        </div>
      </Box>
    </React.Fragment>
  );

  const asideContent = renderAside();
  return (
    <Box sx={styles.root}>
      <CssBaseline />
      <Drawer sx={styles.aside} variant="permanent" anchor="left">
        {asideContent}
      </Drawer>

      <Box component="main" sx={{ ...styles.content, flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', flex: 'none' }}>
          <Button
            title={t('general.back')}
            aria-label={t('general.back')}
            style={styles.backButton}
            onClick={() => history.push('/support')}
          >
            <ArrowLeftIcon fontSize="small" style={styles.backIcon} />
            <span>{t('general.back')}</span>
          </Button>
        </Box>
        <Box sx={{ ...styles.language, marginTop: '-40px' }}>
          <LanguageSelectComponent />
        </Box>
        <Paper
          elevation={3}
          style={{ ...styles.terms, overflowY: 'auto' }}
          ref={consentScroller}
        >
          {!terms ? (
            <React.Fragment>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
              <Skeleton variant="rectangular" height={118} />
              <Skeleton variant="text" />
            </React.Fragment>
          ) : (
            <div style={styles.conditions}>{handleConditionsHtml(terms)}</div>
          )}
        </Paper>
      </Box>

      {user && (
        <MediaQuery maxWidth={theme.breakpoints.values.tablet}>
          <MobileNavigationComponent />
        </MediaQuery>
      )}
    </Box>
  );
}
