import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import LanguageService from 'core/services/language.service';
import useStyles from './LanguageSelect.component.styles';

interface IComponentProps {
  value?: string;
  onChange?: any;
  stl?: any;
}

export const LanguageSelectComponent = ({
  value,
  onChange,
  stl,
}: IComponentProps) => {
  const { t, i18n } = useTranslation();
  const styles = useStyles();

  const [selectedLang, setSelectedLang] = useState<string>(
    value || i18n.language,
  );
  const shortLang = LanguageService.getShortLanguage(selectedLang);
  const changeLanguage = (event: SelectChangeEvent<string>) => {
    i18n.changeLanguage(event.target.value);
    setSelectedLang(event.target.value);

    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <FormControl>
      <InputLabel id="language-select-label">
        {t('login.languageSelectLabel')}
      </InputLabel>
      <Select
        style={stl || styles.root}
        labelId="language-select-label"
        id="language-select"
        label={t('login.languageSelectLabel')}
        value={shortLang}
        onChange={changeLanguage}
      >
        {LanguageService.supportedLanguages.map((language, index) => (
          <MenuItem key={index} value={language.id}>
            {t(language.displayName)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageSelectComponent;
