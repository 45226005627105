import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Link,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import SupportService from 'core/services/support.service';
import {
  supportPhoneNumber,
  supportPhoneNumberTollFree,
} from 'core/constants/app.constants';
import MobileHeaderComponent from 'components/MobileHeader/MobileHeader.component';
import NotificationComponent from 'components/Notification/Notification.component';

import useStyles from './Contact.container.styles';
import { useAuthState } from 'core/contexts/authentication.context';
import { get } from 'lodash';

export default function ContactContainer() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { user } = useAuthState();
  const styles = useStyles(theme);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailSucceeded, setEmailSucceeded] = useState<boolean>(false);
  const [emailFailed, setEmailFailed] = useState<boolean>(false);

  const {
    control,
    register,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const reasons = [
    {
      id: 'suggestion',
      displayName: 'contact.reasons.suggestion',
    },
    {
      id: 'technical',
      displayName: 'contact.reasons.technical',
    },
    {
      id: 'account',
      displayName: 'contact.reasons.account',
    },
    {
      id: 'incorrectData',
      displayName: 'contact.reasons.incorrectData',
    },
    {
      id: 'missingInfo',
      displayName: 'contact.reasons.missingInfo',
    },
  ];

  useEffect(reset, [emailSucceeded]);

  const submitEmail = (values: any) => {
    setIsLoading(true);
    setEmailSucceeded(false);
    setEmailFailed(false);
    const userId = get(user, '_id', '');
    SupportService.sendSupportEmail(userId, values)
      .then(submitEmailSuccess)
      .catch(submitEmailFail);
  };

  const submitEmailSuccess = () => {
    setIsLoading(false);
    setEmailSucceeded(true);
  };

  const submitEmailFail = () => {
    setIsLoading(false);
    setEmailSucceeded(false);
    setEmailFailed(true);
  };

  const closeNotification = () => {
    setEmailSucceeded(false);
    setEmailFailed(false);
  };

  return (
    <React.Fragment>
      <NotificationComponent
        severity="success"
        isOpen={emailSucceeded}
        handleClose={closeNotification}
        message={t('notifications.emailSent')}
      />
      <NotificationComponent
        isOpen={emailFailed}
        handleClose={closeNotification}
        message={t('notifications.emailFail')}
      />
      <Box sx={styles.root}>
        <Box sx={styles.asideActions}>
          <MobileHeaderComponent />
        </Box>
        <div>
          <div style={styles.pageTitle}>
            <span>{t('contact.pageTitle')}</span>
          </div>
          <div style={styles.instruction}>
            <span>{t('contact.instruction')}</span>
          </div>
        </div>
        <Box sx={{ ...styles.content, flexDirection: 'row' }}>
          <Box sx={styles.formContainer}>
            <Box
              component="form"
              sx={{ ...styles.form, flexWrap: 'wrap' }}
              onSubmit={handleSubmit(submitEmail)}
            >
              <Controller
                name="subject"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <TextField
                      select
                      required
                      style={styles.full}
                      variant="outlined"
                      defaultValue={t(reasons[4].displayName)}
                      {...field}
                      label={t('contact.reasonLabel')}
                      inputProps={{
                        'aria-label': t('contact.reasonLabel'),
                      }}
                      {...register('subject', { required: true })}
                    >
                      {reasons.map((reason, index) => (
                        <MenuItem key={index} value={t(reason.displayName)}>
                          {t(reason.displayName)}
                        </MenuItem>
                      ))}
                    </TextField>
                  );
                }}
              />
              <Controller
                name="firstname"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <Box
                      component={TextField}
                      required
                      sx={styles.half}
                      variant="outlined"
                      defaultValue={user ? user.firstName : ''}
                      {...field}
                      label={t('contact.firstNameLabel')}
                      inputProps={{
                        'aria-label': t('contact.firstNameLabel'),
                      }}
                      {...register('firstname', { required: true })}
                    />
                  );
                }}
              />
              <Controller
                name="lastname"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <Box
                      component={TextField}
                      required
                      sx={styles.half}
                      variant="outlined"
                      defaultValue={user ? user.lastName : ''}
                      {...field}
                      label={t('contact.lastNameLabel')}
                      inputProps={{
                        'aria-label': t('contact.lastNameLabel'),
                      }}
                      {...register('lastname', { required: true })}
                    />
                  );
                }}
              />
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <TextField
                      required
                      style={styles.full}
                      variant="outlined"
                      defaultValue={user ? user.email : ''}
                      {...field}
                      label={t('contact.emailLabel')}
                      inputProps={{
                        'aria-label': t('contact.emailLabel'),
                      }}
                      {...register('email', { required: true })}
                    />
                  );
                }}
              />
              <Controller
                name="phone"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <TextField
                      required
                      style={styles.full}
                      variant="outlined"
                      defaultValue={user ? user.phone : ''}
                      {...field}
                      label={t('contact.phoneLabel')}
                      inputProps={{
                        'aria-label': t('contact.phoneLabel'),
                      }}
                      {...register('phone', { required: true })}
                    />
                  );
                }}
              />
              <Controller
                name="description"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  return (
                    <TextField
                      required
                      multiline
                      style={{ ...styles.full }}
                      variant="outlined"
                      rows={10}
                      {...field}
                      label={t('contact.messageLabel')}
                      inputProps={{
                        'aria-label': t('contact.messageLabel'),
                      }}
                      {...register('description', { required: true })}
                    />
                  );
                }}
              />
              <Box sx={styles.actionContainer}>
                <Button
                  disabled={!isValid || isLoading}
                  title={t('actions.submit')}
                  aria-label={t('actions.submit')}
                  variant="contained"
                  size="large"
                  style={styles.cta}
                  type="submit"
                >
                  <span>{t('actions.submit')}</span>
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{ display: 'flex', flex: '0 0 30%', flexDirection: 'column' }}
          >
            <Typography style={styles.sectionTitle}>
              {t('contact.phoneSupport')}
            </Typography>
            <div style={styles.instruction}>
              <span>{t('contact.openHours')}</span>
            </div>
            <div>
              <Link
                style={styles.phone}
                href={`tel:${supportPhoneNumber}`}
                aria-label={t('contact.phoneSupport')}
              >
                {supportPhoneNumber}
              </Link>
              <div>
                <Link
                  style={styles.phone}
                  href={`tel:${supportPhoneNumberTollFree}`}
                  aria-label={t('contact.phoneSupport')}
                >
                  {supportPhoneNumberTollFree}
                </Link>
              </div>
            </div>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
