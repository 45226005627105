import React, { useEffect, useState } from 'react';
import { Box, CssBaseline, Drawer, Popover, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import {
  Help as HelpIcon,
  HelpOutline as HelpOutlineIcon,
  InfoOutlined as InfoIcon,
} from '@mui/icons-material';
import { differenceWith, find, get } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';

import logoImg from 'assets/images/logo_white.png';
import LanguageSelectComponent from 'components/LanguageSelect/LanguageSelect.component';
import ConsentComponent from 'components/Consent/Consent.component';
import LanguageService from 'core/services/language.service';
import ProfileService from 'core/services/profile.service';
import ConditionsService from 'core/services/conditions.service';
import EndpointService from 'core/services/endpoint.service';
import { ICondition } from 'core/interfaces/condition.interface';
import { IAcknowledgement } from 'core/interfaces/acknowledgement.interface';
import NotificationComponent from 'components/Notification/Notification.component';

import useStyles from './RegistrationConditions.container.styles';
import 'styles/common.scss';

interface IConditionsRouteParams {
  conditionType: string;
}

export default function RegistrationConditionsContainer() {
  const drawerWidth = '30%';

  const { conditionType } = useParams() as IConditionsRouteParams;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const styles = useStyles(theme, {
    drawerWidth,
  });

  const [condition, setCondition] = useState<any>(null);
  const [isNotificationOpen, showNotification] = React.useState(false);
  const [unacknowledgedConditions, setUnacknowledged] = useState<ICondition[]>(
    [],
  );

  useEffect(() => {
    getConditions();
  }, [setCondition, conditionType, setUnacknowledged, i18n.language]);

  const getConditions = () => {
    getAllConditions();
  };

  const getAllConditions = () => {
    const language = LanguageService.getShortLanguage(i18n.language);
    ConditionsService.getTermsAndConditions(language)
      .then(handleTempConditionsSuccess)
      .catch(handleConditionsFail);
  };

  const handleTempConditionsSuccess = (
    response: AxiosResponse<ICondition[]>,
  ) => {
    const allConditions: ICondition[] = response.data;
    manageTempStorageConditions(allConditions);
  };

  const compareAcknowledgementsToConditions = (
    a: ICondition,
    b: IAcknowledgement,
  ) => a.type === b.type;

  const manageTempStorageConditions = (allConditions: ICondition[]) => {
    const storedAcknowledgements: IAcknowledgement[] =
      ProfileService.getAcknowledgementFromStorage();
    const conditions = differenceWith(
      allConditions,
      storedAcknowledgements,
      compareAcknowledgementsToConditions,
    );
    setUnacknowledged(conditions);

    getCurrentCondition(conditions);
  };

  const getCurrentCondition = (conditions: ICondition[]) => {
    const condition =
      find(conditions, { type: conditionType }) || get(conditions, '[0]');

    setCondition(condition);
    handleRedirect(condition);
  };

  const handleRedirect = (condition: ICondition) => {
    if (condition && condition.type !== conditionType) {
      history.push(`/register/${condition.type}`);
    } else if (!condition) {
      window.location.href = EndpointService.getRoutes().AUTH.IDME('signup');
    }
  };

  const handleConditionsFail = () => {
    showNotification(true);
    history.replace('/404');
  };

  const closeNotification = () => {
    showNotification(false);
  };

  const onAcknowledgement = (nextConditionType: string) => {
    history.push(`/register/${nextConditionType}`);
  };

  const renderAside = () => (
    <React.Fragment>
      <NotificationComponent
        isOpen={isNotificationOpen}
        handleClose={closeNotification}
        message={t('notifications.conditionsFail')}
      />
      <Box sx={styles.imgContainer}>
        <img alt={t('navigation.altLogo')} style={styles.logo} src={logoImg} />
      </Box>
      <Box sx={{ ...styles.asideContent, flexDirection: 'column' }}>
        <div style={styles.titleHeading}>
          {t('conditions.beforeRegistration')}
        </div>
        <div style={styles.title}>{t('conditions.title')}</div>
      </Box>
      <Box sx={styles.asideActions}>
        <div>
          <Button
            title={t('login.needHelp')}
            aria-label={t('login.needHelp')}
            style={styles.helpButton}
            onClick={() => history.push('/help')}
          >
            <HelpIcon fontSize="small" style={styles.helpIcon} />
            <span>{t('login.needHelp')}</span>
          </Button>
        </div>
      </Box>
    </React.Fragment>
  );

  const [infoTooltip, setInfoTooltip] = React.useState(null);

  const handleInfoClick = (event) => {
    setInfoTooltip(event.currentTarget);
  };

  const handleInfoClose = () => {
    setInfoTooltip(null);
  };

  const open = Boolean(infoTooltip);
  const id = open ? 'info-tooltip' : undefined;
  const asideContent = renderAside();

  return (
    <Box sx={styles.root}>
      <CssBaseline />
      <Drawer sx={styles.aside} variant="permanent" anchor="left">
        {asideContent}
      </Drawer>
      <Box component="main" sx={{ ...styles.content, flexDirection: 'column' }}>
        <Box sx={styles.language}>
          <LanguageSelectComponent />
        </Box>
        <ConsentComponent
          condition={condition}
          unacknowledgedConditions={unacknowledgedConditions}
          onAcknowledgement={onAcknowledgement}
        />
        <div className="subtitle" style={styles.registrationHint}>
          <span>
            {t('conditions.idMeRegistration')}
            <Button
              style={styles.actionButton}
              title={t('conditions.idMeRegistration')}
              aria-label={t('conditions.idMeRegistration')}
            >
              <HelpOutlineIcon />
            </Button>
          </span>
        </div>
        <div className="subtitle" style={styles.infoRequired}>
          <span>
            {t('conditions.infoRequired')}
            <Button
              style={styles.actionButton}
              title={t('conditions.infoRequired')}
              aria-label={t('conditions.infoRequired')}
              onClick={handleInfoClick}
            >
              <InfoIcon />
            </Button>
          </span>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={infoTooltip}
          onClose={handleInfoClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box sx={styles.popup}>
            <div style={styles.popupTitle}>{t('login.infoPopup.title')}</div>
            <p>
              <span>{t('login.infoPopup.subtitle1')} </span>
              <span style={styles.emphasis}>
                {t('login.infoPopup.subtitle2')}
              </span>
              <span>{t('login.infoPopup.subtitle3')}</span>
            </p>
            <p>
              <span style={styles.optionLabel}>
                {t('login.infoPopup.option', { number: 1 })}
              </span>
              <span>
                {t('login.infoPopup.photoOf')}{' '}
                <span style={styles.emphasis}>
                  {t('login.infoPopup.license')}
                </span>
              </span>
            </p>
            <p>
              <span style={styles.optionLabel}>
                {t('login.infoPopup.option', { number: 2 })}
              </span>
              <span>
                {t('login.infoPopup.photoOf')}{' '}
                <span style={styles.emphasis}>
                  {t('login.infoPopup.passport')}
                </span>
              </span>
            </p>
            <p>
              <span style={styles.optionLabel}>
                {t('login.infoPopup.option', { number: 3 })}
              </span>
              <span>
                {t('login.infoPopup.photoOf')}{' '}
                <span style={styles.emphasis}>
                  {t('login.infoPopup.passportCard')}
                </span>
              </span>
            </p>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
}
