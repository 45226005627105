import React from 'react';
import { Alert, AlertColor, Snackbar } from '@mui/material';
import { noop } from 'lodash';

import { notificationDuration } from 'core/constants/app.constants';

interface IComponentProps {
  handleClose?: any;
  isOpen: boolean;
  message: string;
  severity?: AlertColor;
}

export const NotificationComponent = ({
  isOpen = false,
  handleClose = noop,
  severity = 'error',
  message,
}: IComponentProps) => {
  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={notificationDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity={severity} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};

export default NotificationComponent;
