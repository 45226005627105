import { Theme } from '@mui/material';
import Color from 'color';

const styles = (theme: Theme) => ({
  tableContainer: {
    backgroundColor: Color(theme.palette.grayScale.light)
      .alpha(1)
      .hsl()
      .toString(),
    marginTop: '20px',
  },
  boldRow: {
    fontWeight: 600,
  },
});

export default styles;
