import { Theme } from '@mui/material';
import Color from 'color';

export const styles = (theme: Theme) => ({
  root: {
    padding: '45px',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      padding: '15px',
    },
  },
  pageTitle: {
    fontSize: '3rem',
    marginBottom: '20px',
  },
  sectionTitle: {
    fontSize: '1.5rem',
    marginBottom: '23px',
  },
  instruction: {
    marginBottom: '20px',
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
  },
  panelContainer: {
    display: 'flex',
    padding: '30px 15px 30px 0',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'column',
      padding: '15px',
    },
  },
  infoPrimaryRow: {
    display: 'flex',
    marginBottom: '30px',
    alignItems: 'flex-start',
    gap: { mobile: '15px', tablet: '0px' },
  },
  infoRow: {
    display: 'flex',
    marginBottom: '15px',
  },
  label: {
    width: '120px',
  },
  value: {
    fontWeight: 700,
  },
  avatar: {
    flex: '0 0 30%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: { mobile: '30px', tablet: '0px' },
  },
  name: {
    fontSize: '2rem',
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
    // borderRight: {
    //   mobile: 'none',
    //   tablet: `1px solid ${Color(theme.palette.grayScale.main)
    //     .alpha(0.4)
    //     .hsl()
    //     .toString()}`,
    // },
    paddingRight: '15px',
    marginRight: '15px',
    lineHeight: '26px',
  },
  infoContainer: {
    marginBottom: '30px',
  },
  state: {
    display: 'flex',
    justifyContent: 'center',
  },
  personalInfo: {
    marginRight: '15px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: '5px',
  },
  actionContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      textAlign: 'center',
    },
  },
  cta: {
    minWidth: '180px',
  },
  avatarIcon: {
    width: '120px',
    height: '120px',
    backgroundColor: theme.palette.primary.main,
  },
  avatarIconDependent: {
    width: '106px',
    height: '106px',
    backgroundColor: theme.palette.primary.main,
  },
  asideActions: {
    display: 'none',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'row',
      display: 'flex',
      flex: 'none',
      marginBottom: '23px',
    },
  },
  panelPaper: {
    marginBottom: '20px',
  },
  link: {
    textDecoration: 'none',
  },
});

export default styles;
