import { Theme } from '@mui/material';
import Color from 'color';

export const styles = (theme: Theme) => ({
  checkBox: {
    display: 'flex',
    flex: 'none',

    marginTop: '-1px',
    paddingTop: '8px',
  },
  accordionGroup: {
    marginBottom: '0',
    width: '100%',
    boxShadow: 'none',
    '&.Mui-expanded': {
      margin: '0',
    },
    '& .MuiCollapse-root': {
      marginLeft: '-42px',
    },
  },
  accordion: {
    padding: 0,
    alignItems: 'start',
    '& .Mui-expanded': {
      margin: '12px 0 !important',
    },
    '& .MuiAccordionSummary-content': {
      justifyContent: 'start',
      marginLeft: `${theme.spacing(1)} !important`,
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      marginTop: '16px',
    },
  },
  accordionDetails: {
    padding: '0',
  },
  secondaryInfo: {
    fontSize: '0.875rem',
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
  },
  subRecord: {
    padding: { mobile: '20px 20px 20px 72px', tablet: '20px 20px 20px 59px' },
    backgroundColor: theme.palette.grayScale.gradient50,
    boxShadow: 'inset 0 0 10px -8px rgb(0 0 0 / 80%)',
  },
  refillDate: {
    fontSize: '1.2rem',
    paddingLeft: { tablet: '15px', mobile: '0' },
  },
  highlight: {
    color: theme.palette.primary.main,
  },
  refills: {
    color: theme.palette.grayScale.main,
    fontSize: '1rem',
    fontWeight: 700,
  },
  actionButton: {
    color: 'inherit',
    opacity: 0.6,
  },
  popup: {
    padding: '8px 24px',
    maxWidth: { tablet: '340px', mobile: '100%' },
  },
});

export default styles;
