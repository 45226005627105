import { Theme } from '@mui/material';
import Color from 'color';

export const styles = (theme: Theme, props?: any) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  terms: {
    maxWidth: '80%',
    maxHeight: '380px',
    margin: '40px 0',
    borderRadius: '4px',
    padding: '6px 16px',
  },
  helpButton: {
    color: theme.palette.grayScale.light,
  },
  helpIcon: {
    marginRight: '14px',
  },
  pageTitle: {
    fontSize: '3rem',
    marginBottom: '20px',
  },
  instruction: {
    marginBottom: '40px',
    color: Color(theme.palette.grayScale.main).alpha(0.6).hsl().toString(),
  },
  conditions: {
    minHeight: '200px',
  },
  language: {
    display: 'flex',
    flex: 'auto',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
  },
  languageSelect: {
    minWidth: '220px',
  },
  asideMobile: {
    width: props.drawerWidth,
    flexShrink: 0,
    display: { mobile: 'block', tablet: 'none' },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      display: 'block',
      width: '100%',
    },
    '& .MuiDrawer-paper': {
      backgroundColor: theme.palette.primary.main,
      width: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      flex: 'none',
      [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        display: 'block',
        position: 'static',
        width: '100%',
      },
    },
  },
  aside: {
    width: props.drawerWidth,
    flexShrink: 0,
    display: { mobile: 'none', tablet: 'block' },
    '& .MuiDrawer-paper': {
      backgroundColor: theme.palette.primary.main,
      width: props.drawerWidth,
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  imgContainer: {
    display: 'flex',
    flex: 'none',
    paddingLeft: '43px',
    paddingTop: '41px',
  },
  asideContent: {
    display: 'flex',
    flex: 'auto',
    padding: '0 43px',
    justifyContent: 'center',
    color: theme.palette.grayScale.light,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      paddingTop: '41px',
    },
  },
  titleHeading: {
    fontSize: '1.5rem',
    lineHeight: '133%',
    maxWidth: '75%',
    marginBottom: '16px',
  },
  title: {
    fontSize: '3rem',
    lineHeight: '116%',
  },
  logo: {
    maxHeight: '48px',
  },
  asideActions: {
    display: 'flex',
    flex: 'none',
    marginBottom: '80px',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'column',
    },
  },
  backButton: {
    color: theme.palette.primary.main,
  },
  backIcon: {
    marginRight: '14px',
  },
  content: {
    flexGrow: 1,
    backgroundColor: 'background.default',
    p: 3,
    marginBottom: { mobile: `70px`, tablet: '0px' },
    padding: { mobile: `30px 15px 50px 15px`, tablet: '45px' },
    flex: 'auto',
    display: 'flex',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      textAlign: 'center',
    },
  },
  section: {
    display: 'flex',
    marginBottom: '25px',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      flexDirection: 'column',
    },
  },
  sectionTitle: {
    width: '230px',
    display: 'flex',
    flex: 'none',
    fontSize: '1.5rem',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      marginBottom: '10px',
    },
  },
  accordion: {
    '& .MuiAccordionSummary-content': {
      justifyContent: 'start',
    },
  },
  topicsContainer: {
    width: '100%',
  },
});

export default styles;
