import React, { useEffect, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Link,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useTheme,
} from '@mui/material';
import { NavigateNext as NavigateNextIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { orderBy, get, map } from 'lodash';

import ResultsAreaChart from 'components/ResultsAreaChart/ResultsAreaChart.component';
import MobileHeaderComponent from 'components/MobileHeader/MobileHeader.component';
import SearchService from 'core/services/search.service';
import {
  IImmunization,
  IImmunizationHistory,
} from 'core/interfaces/immunization.interface';
import { useAuthState } from 'core/contexts/authentication.context';
import { formatDate } from 'utils/utils';

import useStyles from './ImmunizationDetail.container.styles';
import CovidCardDetails from 'components/CovidCard/CovidCardDetails.component';

interface IContainerParams {
  recordId: string;
}

export default function ImmunizationDetailContainer() {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const styles = useStyles(theme);
  const { user } = useAuthState();
  const [cardDetails, setCardDetails] = useState<IImmunization>();
  const { recordId } = useParams() as IContainerParams;
  const [recordDetails, setRecordDetail] = useState<IImmunization>();
  const [recordHistory, setRecordHistory] = useState<IImmunizationHistory[]>(
    [],
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  useEffect(() => {
    if (!recordId) {
      return history.push('/immunizations');
    }

    if (user) {
      getImmunizationDetails(recordId);
    }
  }, [user, recordId]);

  const getImmunizationHistory = (recordId: string) => {
    const userId = get(user, '_id', '');
    SearchService.getImmunizationHistory(userId, recordId)
      .then(getImmunizationsHistorySuccess)
      .catch(getImmunizationsHistoryFail);
  };

  const getImmunizationsHistorySuccess = (
    response: AxiosResponse<IImmunizationHistory[]>,
  ) => {
    const immunizations: IImmunizationHistory[] = orderBy(
      response.data,
      'soldOn',
    );
    setRecordHistory(map(immunizations, addZeroValue));
  };

  const addZeroValue = (v) => {
    v.value = 0;
    return v;
  };

  const getImmunizationsHistoryFail = () => {
    setRecordHistory([]);
  };

  const getImmunizationDetails = (recordId: string) => {
    setIsLoading(true);
    const userId = get(user, '_id', '');
    SearchService.getImmunizationDetails(userId, recordId)
      .then(getImmunizationsDetailsSuccess)
      .catch(getImmunizationsDetailsFail);
  };

  const getImmunizationsDetailsSuccess = (
    response: AxiosResponse<IImmunization>,
  ) => {
    const immunization: IImmunization = response.data;

    setRecordDetail(immunization);
    setCardDetails(immunization);
    setIsLoading(false);
    getImmunizationHistory(immunization.vaccineCode);
  };

  const getImmunizationsDetailsFail = () => {
    setIsLoading(false);
    // TODO: Snackbar
  };

  const rowsConfig = [
    {
      id: 'performer',
    },
    {
      id: 'manufacturer',
    },
    {
      id: 'vaccineCode',
    },
    {
      id: 'doseQuantityValue',
    },
  ];

  const handleDotClick = (dot) => {
    history.push(`/immunizations/${dot.id}`);
    setRecordDetail(undefined);
  };

  const renderDot = (props) => {
    const { cx, cy, payload } = props;
    const dotSize = 24;

    return (
      <rect
        key={props.index}
        onClick={() => handleDotClick(payload)}
        x={cx - dotSize / 2}
        y={cy - dotSize / 2}
        width={dotSize}
        height={dotSize}
        rx={dotSize}
        fill={
          payload.id === recordId
            ? theme.palette.primary.main
            : theme.palette.grayScale.gradient400
        }
      />
    );
  };

  const formatXAxis = (tickItem) => {
    return DateTime.fromISO(tickItem).toFormat('MM/dd/yyyy');
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.asideActions}>
        <MobileHeaderComponent />
      </Box>
      <Breadcrumbs
        style={styles.breadcrumb}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label={t('immunizationDetail.breadcrumb')}
      >
        <Link underline="none" color="primary" onClick={history.goBack}>
          {t('immunizationDetail.immunization')}
        </Link>
        <span>{recordDetails?.vaccineName.split(',', 1)}</span>
      </Breadcrumbs>
      <div style={styles.pageTitle}>
        {isLoading && <Skeleton height={70} />}
        <span>{recordDetails?.vaccineName}</span>
      </div>
      <div style={styles.grouping}>
        {recordHistory.length > 1 && (
          <div style={styles.chartGrouping}>
            <div style={styles.sectionTitle}>
              {t(
                recordHistory.length > 1
                  ? 'immunizationDetail.dosesInRecordPlural'
                  : 'immunizationDetail.dosesInRecord',
                {
                  count: recordHistory.length,
                },
              )}
            </div>
            <ResultsAreaChart
              data={recordHistory}
              CustomDot={renderDot}
              xAxisDataKey="soldOn"
              height={70}
              xAxisTickFormatter={formatXAxis}
              axisColor={theme.palette.grayScale.gradient400}
            />
          </div>
        )}

        {cardDetails && user && (
          <CovidCardDetails title="Immunization Card" details={cardDetails} />
        )}
        {isLoading && (
          <Stack spacing={2}>
            <Skeleton height={45} width="50%" />
            <Skeleton variant="rectangular" height={300} />
          </Stack>
        )}
        {recordDetails && (
          <div>
            <div style={styles.sectionTitle}>
              <span>
                {t('immunizationDetail.detailsFor')}{' '}
                {formatDate(recordDetails.soldOn)}
              </span>
            </div>
            <TableContainer component={Paper} elevation={3}>
              <Table aria-label={t('immunizationDetail.vaccineInfo')}>
                <TableBody>
                  {rowsConfig.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {t(`immunizationDetail.rows.${row.id}`)}
                      </TableCell>
                      <TableCell>{recordDetails[row.id]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </Box>
  );
}
