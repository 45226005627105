import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  TextField,
  useTheme,
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { noop } from 'lodash';

import useStyles from './Filter.component.styles';

interface IComponentProps {
  children: any;
  onSubmit: any;
  onClear?: any;
  searchValue?: string;
  resetValues?: any;
  resetCutomDate?: any;
}

interface IChildProps {
  control: any;
  register: any;
  setValue: any;
}

export const FilterComponent = ({
  children,
  onSubmit,
  onClear = noop,
  searchValue = '',
  resetValues = {},
  resetCutomDate,
}: IComponentProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);
  const { control, register, reset, handleSubmit, setValue } = useForm({
    mode: 'onChange',
  });

  const childrenWithProps = React.Children.map(children, (child: any) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        control,
        register,
        setValue,
      } as IChildProps);
    }
    return child;
  });

  const clearAll = () => {
    reset({ ...resetValues });
    if (resetCutomDate) {
      resetCutomDate(false);
    }
    onClear();
  };

  const submit = (formValues) => {
    reset(formValues, { keepValues: true });
    onSubmit(formValues);
  };

  return (
    <Paper sx={styles.root} elevation={3}>
      <form onSubmit={handleSubmit(submit)}>
        <div style={styles.searchBarContainer}>
          <Controller
            name="query"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                {...register('query')}
                style={styles.searchBar}
                InputProps={{
                  defaultValue: searchValue || '',
                  placeholder: 'Search by keyword',
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </div>
        <Box
          sx={{
            ...styles.children,
            flexDirection: { mobile: 'column', tablet: 'row' },
            flexWrap: 'wrap',
          }}
        >
          {childrenWithProps}
        </Box>
        <Box style={styles.actions}>
          <Button
            style={styles.actionButton}
            title={t('actions.clearAll')}
            aria-label={t('actions.clearAll')}
            onClick={clearAll}
          >
            <span>{t('actions.clearAll')}</span>
          </Button>
          <Button
            variant="contained"
            style={styles.actionButton}
            title={t('actions.apply')}
            aria-label={t('actions.apply')}
            type="submit"
          >
            <span>{t('actions.apply')}</span>
          </Button>
        </Box>
      </form>
    </Paper>
  );
};

export default FilterComponent;
