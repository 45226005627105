import { Theme } from '@mui/material';

export const styles = (theme: Theme, props?: any) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
  language: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  languageSelect: {
    minWidth: '220px',
  },
  asideMobile: {
    width: props.drawerWidth,
    flexShrink: 0,
    display: { mobile: 'block', tablet: 'none' },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      display: 'block',
      width: '100%',
    },
    '& .MuiDrawer-paper': {
      backgroundColor: theme.palette.primary.main,
      width: '100%',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
        display: 'block',
        position: 'static',
        width: '100%',
      },
    },
  },
  aside: {
    width: props.drawerWidth,
    flexShrink: 0,
    display: { mobile: 'none', tablet: 'block' },
    '& .MuiDrawer-paper': {
      backgroundColor: theme.palette.primary.main,
      width: props.drawerWidth,
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  imgContainer: {
    display: 'flex',
    maxHeight: '100vh',
    flex: 'auto',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      paddingTop: '0px',
    },
  },
  loginImg: {
    width: '100%',
  },
  asideActions: {
    display: 'flex',
    flex: 'none',
    padding: '0',
    marginTop: '-30px',
    marginLeft: '10px',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      padding: '2px',
    },
  },
  helpButton: {
    color: theme.palette.grayScale.light,
    paddingTop: '5px',
  },
  helpIcon: {
    marginRight: '6px',
  },
  content: {
    flexGrow: 1,
    backgroundColor: 'background.default',
    p: 3,
    padding: { mobile: `30px 15px 50px 15px`, tablet: '30px 30px 150px 130px' },
    flex: 'auto',
    display: 'flex',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      textAlign: 'center',
    },
  },
  title: {
    marginBottom: '17px',
  },
  subtitle: {
    marginBottom: '54px',
    color: theme.palette.grayScale.dark,
    actionButton: {
      color: theme.palette.grayScale.dark,
      opacity: 'inherit',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flexEnd',
    marginBottom: '80px',
  },
  logoContainer: {
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      marginTop: '60px',
    },
  },
  logo: {
    width: '100%',
    maxWidth: '340px',
    marginBottom: '28px',
  },
  loginSection: {
    marginBottom: '54px',
  },
  registerSection: {
    marginTop: '54px',
    marginBottom: '17px',
  },
  cta: {
    maxWidth: '250px',
    width: '100%',
  },
  instruction: {
    maxWidth: '400px',
    width: '100%',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      margin: 'auto',
    },
  },
  popup: {
    padding: '24px',
    maxWidth: { tablet: '340px', mobile: '100%' },
  },
  popupTitle: {
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  emphasis: {
    fontWeight: 500,
  },
  optionLabel: {
    display: 'block',
    fontWeight: 500,
  },
  hintText: {
    color: theme.palette.grey[700],
    fontSize: '12px',
    marginTop: '-3px',
  },
});

export default styles;
