import React, { useEffect, useState } from 'react';
import SessionModal from '../SessionModal/SessionModal.container';
import { addEventListeners, removeEventListeners } from 'utils/utils';
import { useAuthState } from 'core/contexts/authentication.context';
import { useHistory } from 'react-router-dom';
import { useAuthDispatch } from 'core/contexts/authentication.context';
import AuthService from 'core/services/auth.service';

const SessionTimer = () => {
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);
  const { user } = useAuthState();
  const dispatch = useAuthDispatch();
  const history = useHistory();

  const logout = () => {
    AuthService.logout(dispatch);
    history.push('/login');
    setWarningModalOpen(false);
  };

  const refreshHandler = (aDispatch: any, aHistory: any) => {
    AuthService.refreshToken(aDispatch, aHistory, false).then((newToken) => {
      localStorage.setItem('accessToken', newToken);
      setWarningModalOpen(false);
    });
  };

  useEffect(() => {
    const createTimeout1 = () =>
      setTimeout(() => {
        setWarningModalOpen(true);
      }, 180000);

    const createTimeout2 = () =>
      setTimeout(() => {
        history.push('/login');
        AuthService.logout(dispatch);
        setWarningModalOpen(false);
      }, 90000);

    const listener = () => {
      if (!isWarningModalOpen) {
        clearTimeout(timeout);
        timeout = createTimeout1();
      }
    };

    // Initialization
    let timeout: NodeJS.Timeout;
    if (isWarningModalOpen) {
      timeout = createTimeout2();
    } else {
      if (user) {
        timeout = createTimeout1();
        addEventListeners(listener);
      }
    }

    // Cleanup
    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    };
  }, [isWarningModalOpen, user]);
  return (
    <div>
      {isWarningModalOpen && (
        <SessionModal
          openDialog={isWarningModalOpen}
          closeHandle={() => refreshHandler(dispatch, history)}
          signoutHandle={logout}
        />
      )}
    </div>
  );
};

export default SessionTimer;
