import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm, ValidationRule } from 'react-hook-form';
import { Box, Button, IconButton, TextField, useTheme } from '@mui/material';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import { chain } from 'lodash';

import { IContact } from 'core/interfaces/contact.interface';

import useStyles from './ContactSettings.component.styles';

interface IContactSettingsProps {
  contacts: IContact[];
  channelType: string;
  sectionTitle: string;
  inputLabel: string;
  addHandler: any;
  removeHandler: any;
  limit?: number;
  formatter?: any;
  validationRegex?: ValidationRule<RegExp>;
}

export const ContactSettingsComponent = ({
  contacts,
  channelType,
  sectionTitle,
  inputLabel,
  addHandler,
  removeHandler,
  limit = 2,
  formatter = (v: any) => v,
  validationRegex,
}: IContactSettingsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);

  const [topics, setTopics] = useState<any>();
  const [isAddingNewContact, addNewContact] = useState<boolean>(false);
  const [isEditing, editContact] = useState<number>();

  useEffect(() => {
    const topics = chain(contacts)
      .find({ isPrimary: true })
      .get('topics')
      .value();
    setTopics(topics);
  }, [contacts]);

  const {
    control,
    reset,
    register,
    handleSubmit,
    setValue,
    unregister,
    formState: { isDirty, isValid },
  } = useForm({
    mode: 'onChange',
  });

  const cancelEdit = () => {
    editContact(undefined);
    addNewContact(false);
    reset();
  };

  const add = () => {
    editContact(undefined);
    addNewContact(!isAddingNewContact);
  };

  const edit = (index: number) => {
    editContact(isEditing ? undefined : index);
    addNewContact(false);
  };

  const save = (contact: IContact) => {
    reset();
    addHandler(contact, isEditing);
    editContact(undefined);
    addNewContact(false);
    unregister('address');
  };

  const remove = (contact: IContact) => {
    editContact(undefined);
    addNewContact(false);
    reset();
    removeHandler(contact);
  };

  const renderTextField = (contact?: IContact) => {
    let defaultValue = ' ';
    if (contact) {
      setValue('id', contact.id);
    }
    setValue('channelType', channelType);
    setValue('isPrimary', false);
    setValue('newHealthRecordOptStatus', topics.newHealthRecordOptStatus);
    setValue('updateContactOptStatus', topics.updateContactOptStatus);

    if (contact) {
      defaultValue = contact.contactInfo;
    }

    return (
      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <Box sx={styles.contactFieldContainer}>
            <TextField
              variant="standard"
              {...field}
              defaultValue={defaultValue}
              label={inputLabel}
              inputProps={{
                'aria-label': inputLabel,
              }}
              {...register('address', {
                pattern: validationRegex,
              })}
            />
            <Box
              component={IconButton}
              sx={styles.updateButton}
              disabled={!isDirty || !isValid}
              type="submit"
            >
              <CheckIcon />
            </Box>
            <IconButton onClick={cancelEdit}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      />
    );
  };

  return (
    <form
      style={{ display: 'table', tableLayout: 'fixed', marginBottom: '15px' }}
      onSubmit={handleSubmit(save)}
    >
      <Box sx={styles.contactInfoRow}>
        <Box sx={styles.contactInfoCell}>
          <span>{sectionTitle}:</span>
        </Box>
        {contacts.map((contact: IContact, index: number) => (
          <div key={`contact_${index}`} style={styles.contact}>
            {isEditing !== index && (
              <div>
                <span style={styles.contactValue}>
                  {formatter(contact.contactInfo)}
                </span>
                {contact.isPrimary ? (
                  <Button
                    style={{
                      display:
                        contacts.length >= limit ? 'none' : 'inline-block',
                    }}
                    title={t('actions.add')}
                    aria-label={t('actions.add')}
                    onClick={add}
                  >
                    {t('actions.add')}
                  </Button>
                ) : (
                  <React.Fragment>
                    <Button
                      title={t('actions.edit')}
                      aria-label={t('actions.edit')}
                      onClick={() => edit(index)}
                    >
                      {t('actions.edit')}
                    </Button>
                    <Button
                      title={t('actions.remove')}
                      aria-label={t('actions.remove')}
                      onClick={() => remove(contact)}
                    >
                      {t('actions.remove')}
                    </Button>
                  </React.Fragment>
                )}
              </div>
            )}
            {isEditing === index && <div>{renderTextField(contact)}</div>}
            {isAddingNewContact && renderTextField()}
          </div>
        ))}
      </Box>
    </form>
  );
};

export default ContactSettingsComponent;
