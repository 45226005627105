import { Theme } from '@mui/material';

export const styles = (theme: Theme, props?: any) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  // asideMobile: {
  //   width: props.drawerWidth,
  //   flexShrink: 0,
  //   display: { mobile: 'block', tablet: 'none' },
  //   [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
  //     display: 'block',
  //     width: '100%',
  //   },
  //   '& .MuiDrawer-paper': {
  //     backgroundColor: theme.palette.primary.main,
  //     width: '100%',
  //     boxSizing: 'border-box',
  //     display: 'flex',
  //     flexDirection: 'column',
  //     flex: 'none',
  //     [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
  //       display: 'block',
  //       position: 'static',
  //       width: '100%',
  //     },
  //   },
  // },
  aside: {
    width: props.drawerWidth,
    flexShrink: 0,
    display: { mobile: 'none', tablet: 'block' },
    '& .MuiDrawer-paper': {
      backgroundColor: theme.palette.primary.main,
      width: props.drawerWidth,
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  navigationContainer: {
    width: props.drawerWidth,
    position: 'fixed',
    left: 0,
    bottom: 0,
    top: 0,
  },
  content: {
    flexGrow: 1,
    backgroundColor: 'background.default',
    flex: 'auto',
    display: 'flex',
    marginBottom: { tablet: '0px', mobile: '70px' },
  },
  asideActions: {
    display: 'flex',
    flex: 'none',
    padding: '46px',
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      padding: '22px',
    },
  },
  accountSettings: {
    display: 'flex',
    padding: '45px',
    right: 0,
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      display: 'none',
    },
  },
});

export default styles;
