import { Theme } from '@mui/material';
import Color from 'color';

export const styles = (theme: Theme) => ({
  root: {
    boxShadow:
      '0 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.grayScale.light,
    height: '100%',
    display: 'flex',
  },
  logo: {
    display: 'flex',
    padding: '27px 0 27px 35px',
    fontSize: '1.5rem',
  },
  icon: {
    width: '151px',
  },
  appName: {
    marginLeft: '26px',
  },
  medIcon: {
    width: '24px',
    display: 'flex',
  },
  sectionTitle: {
    padding: '18px 16px',
  },
  links: {
    padding: 0,
    margin: 0,
    paddingInlineStart: 0,
  },
  link: {
    color: theme.palette.grayScale.light,
    textDecoration: 'none',
    padding: '18px 34px',
    alignItems: 'center',
    // flexDirection: 'row',
    display: 'flex',
    cursor: 'pointer',
  },
  linkText: {
    marginLeft: '34px',
  },
  active: {
    backgroundColor: Color(theme.palette.primary.highlight)
      .alpha(0.3)
      .hsl()
      .toString(),
  },
  iconContainer: {
    width: '24px',
  },
});

export default styles;
