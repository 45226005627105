declare module '@mui/material/styles' {
  interface Palette {
    grayScale: Palette['primary'];
  }
  interface PaletteOptions {
    grayScale: PaletteOptions['primary'];
  }

  interface PaletteColor {
    highlight?: string;
    gradient50?: string;
    gradient100?: string;
    gradient200?: string;
    gradient300?: string;
    gradient400?: string;
    gradient500?: string;
    gradient600?: string;
    gradient700?: string;
    gradient800?: string;
  }
  interface SimplePaletteColorOptions {
    gradient50?: string;
    gradient100?: string;
    gradient200?: string;
    gradient300?: string;
    gradient400?: string;
    gradient500?: string;
    gradient600?: string;
    gradient700?: string;
    gradient800?: string;
  }
}

const themePalette = {
  type: 'light',
  primary: {
    main: '#004C97',
    highlight: '#BEDFFF',
    light: '#639AD0',
    dark: '#003D79',
  },
  secondary: {
    main: '#00AFD7',
    light: '#7FB7CF',
    dark: '#008CAC',
  },
  warning: {
    main: '#F2A900',
    light: '#F4DCB2',
    dark: '#C28700',
  },
  success: {
    main: '#4CAF50',
    light: '#94BC54',
  },
  grayScale: {
    light: '#FFFFFF',
    main: '#000000',
    gradient50: '#FAFAFA',
    gradient100: '#F5F5F5',
    gradient200: '#EEEEEE',
    gradient300: '#E0E0E0',
    gradient400: '#BDBDBD',
    gradient500: '#9E9E9E',
    gradient600: '#757575',
    gradient700: '#616161',
    gradient800: '#424242',
    dark: '#1A1919',
  },
};

export default themePalette;
