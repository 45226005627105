// eslint-disable-next-line @typescript-eslint/no-var-requires
const dotenv = require('dotenv');
dotenv.config();

const apiEndpoint = process.env.REACT_APP_ROUTING_BASE_API || '{BaseApi}';

function getBaseApiEndpoint(base = apiEndpoint) {
  return base;
}

function getRoutes() {
  return {
    AUTH: {
      IDME: (op?: string) =>
        `${
          process.env.REACT_APP_IDME_ISSUER
        }/authorize?response_type=code&client_id=${
          process.env.REACT_APP_IDME_CLIENT_ID
        }&redirect_uri=${
          process.env.REACT_APP_DOMAIN
        }/callback&scope=openid http://idmanagement.gov/ns/assurance/ial/2/aal/2${
          op ? `&op=${op}` : ''
        }`,
      TOKEN: () => `${EndpointService.getBaseApiEndpoint()}/auth/getToken`,
      USER: () => `${EndpointService.getBaseApiEndpoint()}/auth/user`,
      LOGOUT: () =>
        `${process.env.REACT_APP_IDME_ISSUER}/logout?client_id=${process.env.REACT_APP_IDME_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_DOMAIN}/callback`,
    },
    LABS: {
      NEW: (userId: string) =>
        `${EndpointService.getBaseApiEndpoint()}/search-new-lab-records/${userId}`,
      QUERY: (userId: string) =>
        `${EndpointService.getBaseApiEndpoint()}/search-all-lab-records/${userId}`,
      DETAIL: (recordId: string) =>
        `${EndpointService.getBaseApiEndpoint()}/search-lab-record/${recordId}`,
      HISTORY: (patiendId: string, testCode: string) =>
        `${EndpointService.getBaseApiEndpoint()}/search-lab-record-history/${patiendId}/${testCode}`,
      METADATA: (userId: string) =>
        `${EndpointService.getBaseApiEndpoint()}/search-lab-metadata/${userId}`,
    },
    MEDICATIONS: {
      QUERY: (userId: string) =>
        `${EndpointService.getBaseApiEndpoint()}/search-all-prescription-records${
          userId ? `/${userId}` : ''
        }`,
      METADATA: (userId: string) =>
        `${EndpointService.getBaseApiEndpoint()}/search-prescription-record-metadata/${userId}`,
    },
    IMMUNIZATIONS: {
      QUERY: (userId: string) =>
        `${EndpointService.getBaseApiEndpoint()}/search-all-immunization-records${
          userId ? `/${userId}` : ''
        }`,
      METADATA: (userId: string) =>
        `${EndpointService.getBaseApiEndpoint()}/search-immunization-metadata/${userId}`,
      DETAIL: (userId: string, recordId: string) =>
        `${EndpointService.getBaseApiEndpoint()}/search-immunization-record/${userId}/${recordId}`,
      HISTORY: (userId: string, recordId: string) =>
        `${EndpointService.getBaseApiEndpoint()}/search-immunization-history/${userId}/${recordId}`,
      COVID_CARD: (userId: string) =>
        `${EndpointService.getBaseApiEndpoint()}/covid-card/${userId}`,
    },
    CONDITIONS: (id?: string) =>
      `${EndpointService.getBaseApiEndpoint()}/conditions${id ? `/${id}` : ''}`,
    SUPPORT: {
      TOPICS: () =>
        `${EndpointService.getBaseApiEndpoint()}/support-topics-by-scope/general`,
      TOPICS_PUBLIC: () =>
        `${EndpointService.getBaseApiEndpoint()}/support-topics-by-scope/public`,
      EMAIL: (userid: string) =>
        `${EndpointService.getBaseApiEndpoint()}/support-case/${userid}`,
      COMMUNITY_RESOURCE_DATA: (userid: string) =>
        `${EndpointService.getBaseApiEndpoint()}/community-care-survey/${userid}`,
    },
    PROFILE: {
      USER_PROFILE: (userId?: string) =>
        `${EndpointService.getBaseApiEndpoint()}/user-profiles/${userId}`,
      USER_PROFILES_BY_CONTEXT: (userId: string) =>
        `${EndpointService.getBaseApiEndpoint()}/user-profiles-by-context/${userId}`,
      USER_PROFILE_BY_EMAIL: (email: string) =>
        `${EndpointService.getBaseApiEndpoint()}/userProfilesbyEmail/${email}`,
      ACKNOWLEDGEMENTS: (userId: string) =>
        `${EndpointService.getBaseApiEndpoint()}/acknowledgements/${userId}`,
      UNACKNOWLEDGED: (userId: string) =>
        `${EndpointService.getBaseApiEndpoint()}/unacknowledged/${userId}`,
    },
    NOTIFICATIONS: {
      PREFERENCES: (userId: string) =>
        `${EndpointService.getBaseApiEndpoint()}/notification-preferences/${userId}`,
      PREFERENCE: (id: string) =>
        `${EndpointService.getBaseApiEndpoint()}/notification-preference/${id}`,
    },
  };
}

const EndpointService = {
  getRoutes,
  getBaseApiEndpoint,
};

export default EndpointService;
