import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function ProtectedRoute(props: any) {
  const { component: Component, ...restOfProps } = props;
  const isAuthenticated = localStorage.getItem('accessToken');

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

export default ProtectedRoute;
