import { Theme } from '@mui/material';

export const styles = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.grayScale.gradient100,
    padding: '17px 20px',
  },
  actions: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  actionButton: {
    marginLeft: '15px',
  },
  searchBarContainer: {
    marginBottom: '12px',
  },
  searchBar: {
    width: '100%',
    backgroundColor: theme.palette.grayScale.light,
  },
  children: {
    display: 'flex',
    gap: '15px',
  },
});

export default styles;
