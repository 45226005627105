import React from 'react';
import { useTranslation } from 'react-i18next';
import { IPanelDetailResult } from '../../core/interfaces/tests.interface';
import { Box, useTheme } from '@mui/material';
import useStyles from './TestDetailBlock.component.style';
import utils from 'utils/utils';
import { HIGH, LOW } from '../../core/constants/app.constants';

interface TestDetailProps {
  labRecord: IPanelDetailResult | undefined;
  children: any[];
}

const TestDetailBlock = ({ labRecord, children }: TestDetailProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const styles = useStyles(theme);

  //methods
  const resultMessage = (result: string | undefined) => {
    if (!result) {
      return '';
    } else {
      switch (result) {
        case HIGH:
          return t('labDetail.highWarning');
        case LOW:
          return t('labDetail.lowWarning');
        default:
          return '';
      }
    }
  };
  return (
    <>
      <Box sx={styles.detailBox}>
        <div style={styles.sectionTitle}>
          {t('labDetail.detailsTitle')}
          <span style={styles.bold}>
            {labRecord ? utils.formatDate(labRecord.modifiedDate) : ''}
          </span>
        </div>
        <p>{resultMessage(labRecord?.results)}</p>
        <Box>{children}</Box>
      </Box>
    </>
  );
};

export default TestDetailBlock;
