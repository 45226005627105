import React, { useEffect, useState } from 'react';
import { Box, CssBaseline, Drawer, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { Help as HelpIcon } from '@mui/icons-material';
import { find, get } from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosResponse } from 'axios';

import logoImg from 'assets/images/logo_white.png';
import LanguageSelectComponent from 'components/LanguageSelect/LanguageSelect.component';
import ConsentComponent from 'components/Consent/Consent.component';
import ProfileService from 'core/services/profile.service';
import { ICondition } from 'core/interfaces/condition.interface';
import NotificationComponent from 'components/Notification/Notification.component';
import { useAuthState } from 'core/contexts/authentication.context';
import LanguageService from 'core/services/language.service';

import useStyles from './Conditions.container.styles';
import 'styles/common.scss';

interface IConditionsRouteParams {
  conditionType: string;
}

export default function ConditionsContainer() {
  const drawerWidth = '30%';

  const { conditionType } = useParams() as IConditionsRouteParams;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const styles = useStyles(theme, {
    drawerWidth,
  });
  const { user } = useAuthState();

  const [condition, setCondition] = useState<any>(null);
  const [isNotificationOpen, showNotification] = React.useState(false);
  const [unacknowledgedConditions, setUnacknowledged] = useState<ICondition[]>(
    [],
  );

  useEffect(() => {
    if (user) {
      getUnacknowledgedConditions(user._id);
    }
  }, [setCondition, conditionType, setUnacknowledged]);

  const getUnacknowledgedConditions = (userId: string) => {
    const language = LanguageService.getShortLanguage(i18n.language);
    ProfileService.getUnacknowledgedConditions(userId, language)
      .then(handleConditionsSuccess)
      .catch(handleConditionsFail);
  };

  const handleConditionsSuccess = (response: AxiosResponse<ICondition[]>) => {
    setUnacknowledged(response.data);
    getCurrentCondition(response.data);
  };

  const getCurrentCondition = (conditions: ICondition[]) => {
    const condition =
      find(conditions, { type: conditionType }) || get(conditions, '[0]');

    setCondition(condition);
    handleRedirect(condition);
  };

  const handleRedirect = (condition: ICondition) => {
    if (condition && condition.type !== conditionType) {
      history.push(`/conditions/${condition.type}`);
    } else if (!condition) {
      history.push(`/home`);
    }
  };

  const handleConditionsFail = () => {
    showNotification(true);
    history.replace('/404');
  };

  const closeNotification = () => {
    showNotification(false);
  };

  const onAcknowledgement = (nextConditionType: string) => {
    history.push(`/conditions/${nextConditionType}`);
  };

  const renderAside = () => (
    <React.Fragment>
      <NotificationComponent
        isOpen={isNotificationOpen}
        handleClose={closeNotification}
        message={t('notifications.conditionsFail')}
      />
      <Box sx={styles.imgContainer}>
        <img alt={t('navigation.altLogo')} style={styles.logo} src={logoImg} />
      </Box>
      <Box sx={{ ...styles.asideContent, flexDirection: 'column' }}>
        <div style={styles.title}>{t('conditions.title')}</div>
      </Box>
      <Box sx={styles.asideActions}>
        <div>
          <Button
            title={t('login.needHelp')}
            aria-label={t('login.needHelp')}
            style={styles.helpButton}
            onClick={() => history.push('/support')}
          >
            <HelpIcon fontSize="small" style={styles.helpIcon} />
            <span>{t('login.needHelp')}</span>
          </Button>
        </div>
      </Box>
    </React.Fragment>
  );

  const asideContent = renderAside();

  return (
    <Box sx={styles.root}>
      <CssBaseline />
      <Drawer sx={styles.aside} variant="permanent" anchor="left">
        {asideContent}
      </Drawer>
      <Box component="main" sx={{ ...styles.content, flexDirection: 'column' }}>
        <Box sx={styles.language}>
          <LanguageSelectComponent />
        </Box>
        <ConsentComponent
          condition={condition}
          unacknowledgedConditions={unacknowledgedConditions}
          onAcknowledgement={onAcknowledgement}
        />
      </Box>
    </Box>
  );
}
