import React from 'react';
import { Box } from '@mui/material';
import { useMediaQuery } from 'react-responsive';

interface tutBodyProps {
  tutTitle: string;
  tutDescription: string;
  key: number;
  tutImage: any;
  tutImageMobile: any;
}

const TutBody = ({
  tutTitle,
  tutDescription,
  key,
  tutImage,
  tutImageMobile,
}: tutBodyProps) => {
  const isMobile = useMediaQuery({ query: `(max-width: 560px)` });
  return (
    <Box key={key}>
      <h3>{tutTitle}</h3>
      <p>{tutDescription}</p>
      {isMobile ? (
        <Box
          component="img"
          sx={{
            Height: '340px',
            maxHeight: '320px',
            minWidth: '200px',
            maxWidth: '70px',
            display: 'block',
            margin: '0 0',
          }}
          src={tutImageMobile}
        />
      ) : (
        <Box
          component="img"
          sx={{
            minHeight: '430px',
            maxHeight: '450px',
            minWidth: '400px',
            maxWidth: '700px',
            display: 'block',
            margin: '1 0',
          }}
          src={tutImage}
        />
      )}
    </Box>
  );
};

export default TutBody;
